import React , { useEffect } from 'react';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Image, Button, Icon, Text, Flex } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useBackofficeRootStore } from '../../../../../hooks/useBackofficeRootStore';

interface MenuTableProps {
    outletId: number;
}

const MenuTable: React.FC<MenuTableProps> = observer(({ outletId }) => {
  const { backofficeMenuItemByOutletStore } = useBackofficeRootStore();

  useEffect(() => {
    backofficeMenuItemByOutletStore.fetchMenuItems(outletId);
  }, [outletId, backofficeMenuItemByOutletStore]);

  const filteredMenuData = backofficeMenuItemByOutletStore.filteredMenuItems;


  return (
    <Box overflowX="auto" my={5} borderRadius="lg">
      <Table variant="simple">
        <Thead bg="#f0f4f8">
          <Tr>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">
                Menu Name
            </Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">
                Description
            </Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">
                Menu ID
            </Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">
                Price
            </Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">
                Quantity
            </Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">
                Action
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredMenuData.length > 0 ? (
            filteredMenuData.map((item) => (
              <Tr key={item.id}>
                <Td>
                  <Flex align="center">
                    <Image src={item.image} alt={item.name} boxSize="50px" mr={4} />
                    <Text>{item.name}</Text>
                  </Flex>
                </Td>
                <Td>
                  <Text>{item.description}</Text>
                </Td>
                <Td>{item.id}</Td>
                <Td>
                  <Text>AED {item.price}</Text>
                </Td>
                <Td>
                  <Text>X {item.stock}</Text>
                </Td>
                <Td>
                  <Flex direction="row" gap={2}>
                    <Button size="sm" variant="ghost">
                      <Icon as={HiOutlinePencilSquare} />
                    </Button>
                    <Button size="sm" variant="ghost"
                      onClick = {() => {
                        backofficeMenuItemByOutletStore.deleteMenuItem(item.id);
                      }}>
                      <Icon as={RiDeleteBinLine} />
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={6} textAlign="center">
                  No data available.
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
});

export default MenuTable;
