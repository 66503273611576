import { NotAllowedIcon } from '@chakra-ui/icons';
import { Text, VStack } from '@chakra-ui/react';
import React from 'react';

export const EmptyMenuItems = () => {
  return (
    <VStack align={'center'} justify={'center'} height={'100%'} width={'100%'} spacing={4}>
      <NotAllowedIcon boxSize={12} />
      <Text textAlign={'center'}>
        No menu items available currently for this outlet
      </Text>
    </VStack>
  );
};
