import React from 'react';
import { Card, CardBody, CardHeader, Flex, Heading, StackDivider, VStack, Text, Spacer, IconButton, Image } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { useRootStore } from '../../hooks/useRootStore';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useWindow } from '../../hooks/useWindow';

import styles from './cartItems.module.css';
import { MenuItemType } from '../../ClientPages/MenuPage/components/MenuItemsData';

export const CartItems: React.FC = observer(() => {
  const { menuStore, cartStore } = useRootStore();
  const { maxWindowWidth } = useWindow();

  // Group cart items by outletId
  const groupedItems = cartStore.cartItems.reduce<Record<number, MenuItemType[]>>((acc, item) => {
    const outletId = item.outletId;
    if (!(outletId in acc)) {
      acc[outletId] = [];
    }
    acc[outletId].push(item);
    return acc;
  }, {});

  return (
    <>
      <VStack align={'left'} width={'100%'} bg={'white'} maxWidth={maxWindowWidth}>
        {Object.keys(groupedItems).map((outletId) => (
          <Card key={outletId} variant={'outlined'}>
            <CardHeader py={'5px'} px={0}>
              <Heading size={['sm', 'md', 'lg']} fontFamily={'Body'}>
                {menuStore.getOutletName(Number(outletId))}
              </Heading>
            </CardHeader>

            <CardBody py={'5px'} px={0}>
              <VStack align={'left'} divider={<StackDivider borderColor='gray.200' />}>
                {groupedItems[Number(outletId)].map((innerCartItem) => (
                  <Card key={toJS(innerCartItem.id)} variant={'unstyled'}>
                    <CardHeader my={'15px'}>
                      <Heading size={['xs', 'sm', 'md']} fontFamily={'Body'}>
                        {toJS(innerCartItem.name)}
                      </Heading>
                    </CardHeader>

                    <CardBody>
                      <Flex alignItems='center' gap={2}>
                        <IconButton
                          isRound={true}
                          className={styles.iconButton}
                          aria-label='Decrement'
                          icon={toJS(innerCartItem.stock) === 1 ? <Image src='./images/TrashSimple.svg' /> : <MinusIcon color={'#637485'} />}
                          onClick={() => {
                            cartStore.removeFromCart(toJS(innerCartItem.id));
                          }}
                        />

                        <Text mx={['5px', '10px', '20px']} fontSize={['small', 'medium', 'large']} fontWeight={'bold'} fontFamily={'Body'}>
                          {cartStore.cartCountForItem(toJS(innerCartItem.id))}
                        </Text>

                        <IconButton
                          isRound={true}
                          className={styles.iconButton}
                          aria-label='Increment'
                          icon={<AddIcon color={'#637485'} />}
                          onClick={() => {
                            cartStore.incrementCartItem(toJS(innerCartItem.id));
                          }}
                        />

                        {/* Delete button if quantity > 1 */}
                        {toJS(innerCartItem.stock) > 1 && (
                          <IconButton
                            aria-label='Delete'
                            icon={<Image src='./images/TrashSimple.svg' w={'20px'}/>}
                            onClick={() => {
                              cartStore.deleteFromCart(toJS(innerCartItem.id));
                            }}
                          />
                        )}

                        <Spacer />

                        <Text fontSize={['small', 'medium', 'large']} fontWeight={'bold'} fontFamily={'Body'}>
                          AED {toJS(innerCartItem.stock) * toJS(innerCartItem.price)}
                        </Text>
                      </Flex>
                    </CardBody>
                  </Card>
                ))}
              </VStack>
            </CardBody>
          </Card>
        ))}
      </VStack>
    </>
  );
});
