import React from 'react';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import { RiDeleteBinLine } from 'react-icons/ri';
import { GoDotFill } from 'react-icons/go';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button, Icon, Text, Flex } from '@chakra-ui/react';
import { useBackofficeRootStore } from '../../../../../hooks/useBackofficeRootStore';
import { observer } from 'mobx-react';

const PropertyTable = observer(() => {
  // const [data, setData] = useState<PropertyItemType[]>([]);
  // const [loading,setLoading] = useState(false);

  const { backofficePropertyTypeStore } = useBackofficeRootStore();

  return (
    <Box overflowX="auto" my={5} borderRadius="lg">
      <Table variant="simple">
        <Thead bg="#f0f4f8">
          <Tr>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Property type</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Active/Inactive</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {backofficePropertyTypeStore.propertyTypes.length > 0 ? (
            backofficePropertyTypeStore.propertyTypes.map((item) => (
              <Tr key={item.propertyTypeId}>
                <Td>
                  <Text>{item.propertyType1}</Text>
                </Td>
                <Td>
                  <Text
                    bg={item.isActive ? '#99FF99' : '#D8D8D8'}
                    color={item.isActive ? '#003300' : '#383838'}
                    borderRadius="10px"
                    p="4px 6px"
                    display="inline-flex"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Icon as={GoDotFill} color={item.isActive ? '#003300' : '#383838'} mr={2} />
                    {item.isActive ? 'Active' : 'Inactive'}
                  </Text>
                </Td>
                <Td p={0}>
                  <Flex direction="row" justify="flex-start" align="left">
                    <Button size="md" variant="ghost">
                      <Icon as={HiOutlinePencilSquare} color="#6699FF" />
                    </Button>
                    <Button size="md" variant="ghost"
                      onClick={() => {
                        backofficePropertyTypeStore.deletePropertyType(item.propertyTypeId);
                      }}
                    >
                      <Icon as={RiDeleteBinLine} color="#6699FF" />
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={3} textAlign="center">
                Loading...
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
});

export default PropertyTable;
