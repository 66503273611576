
import { Box, Text, VStack } from '@chakra-ui/react';

export const GuestDetails = ({ guestName, guestConfirmationNumber, guestRoomNumber, guestRoomType }: {
  guestName: string,
  guestConfirmationNumber: string,
  guestRoomNumber: number,
  guestRoomType: string
}) => {
  return (
    <Box
      backgroundColor="white"
      padding={6}
      borderRadius="md"
      boxShadow="md"
      width="300px"
    >
      <VStack align="start" spacing={4}>
        {/* Header */}
        <Text fontSize="xl" fontWeight="bold" color="gray.800">
          Guest Details
        </Text>

        {/* Guest Name */}
        <VStack align="start" spacing={0}>
          <Text fontSize="sm" color="gray.600">Name:</Text>
          <Text fontSize="md" fontWeight="bold" color="gray.800">{guestName}</Text>
        </VStack>

        {/* Reservation Number */}
        <VStack align="start" spacing={0}>
          <Text fontSize="sm" color="gray.600">Confirmation number:</Text>
          <Text fontSize="md" fontWeight="bold" color="gray.800">{guestConfirmationNumber}</Text>
        </VStack>

        {/* Room Number */}
        <VStack align="start" spacing={0}>
          <Text fontSize="sm" color="gray.600">Room:</Text>
          <Text fontSize="md" fontWeight="bold" color="gray.800">{guestRoomNumber}</Text>
        </VStack>

        {/* Room Type */}
        <VStack align="start" spacing={0}>
          <Text fontSize="sm" color="gray.600">Room type:</Text>
          <Text fontSize="md" fontWeight="bold" color="gray.800">{guestRoomType}</Text>
        </VStack>
      </VStack>
    </Box>
  );
};

