import React from 'react';
import { SimpleGrid, Skeleton } from '@chakra-ui/react';

import styles from './OutletsStyles.module.css';
import { useWindow } from '../../../../hooks/useWindow';
import { OutletListStructure } from './Outlets';

export const OutletsLoading = ({ structure = OutletListStructure.Grid }) => {

  const { isMobileOrTablet } = useWindow();

  return (
    <>
      <SimpleGrid spacingX={'10px'} spacingY={'14px'} columns={[3, null, 5]} className={styles.menuCardContainer} role="grid" bg={'transparent'}>
        {Array.from({ length: structure === OutletListStructure.Grid ? 5 : 3 }).map((_, index) => (
          <Skeleton key={index} borderRadius={8} height={isMobileOrTablet ? 180 : 160} width={isMobileOrTablet ? 120 : 160} />
        ))}
      </SimpleGrid>
    </>
  );
};
