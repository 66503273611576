import { HStack, Stack, Text, Divider } from '@chakra-ui/react';
import { useWindow } from '../../../hooks/useWindow';
import { useRootStore } from '../../../hooks/useRootStore';

export const PaymentSummary = () => {
  const { maxWindowWidth } = useWindow();
  const { cartStore } = useRootStore();

  return (
    <Stack width="100%" maxWidth={maxWindowWidth} mx="auto" spacing={3}>
      <Text fontFamily="Title" fontSize="24px" fontWeight={800} >Summary</Text>

      <Divider />

      <HStack justify="space-between" width="100%">
        <Text fontWeight="600" color="gray.600">Grand total</Text>
        <Text color="blue.600" fontWeight="600">
          {cartStore.cartTotalString}
        </Text>
      </HStack>
    </Stack>
  );
};
