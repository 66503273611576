import BaseApiService from './BaseApiService';

export interface PropertyDetailsResponse {
  result: boolean;
  code: string;
  data: PropertyDetailsInterface;
  message: string;
};

export interface PropertyDetailsInterface {
  propertyId: number;
  propertyTypeId: number;
  name: string;
  contactNo: string;
  propertyLogo: string;
};

export const GetPropertyDetailsService = async (propertyId: number) => {
  try {
    // curl -X 'GET' \
  // 'https://localhost:5001/api/PropertyDetails/GetPropertyDetailsById?PropertyId=1' \
  // -H 'accept: text/plain'
    const response = await BaseApiService.get(`/PropertyDetails/GetPropertyDetailsById?PropertyId=${String(propertyId)}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as PropertyDetailsResponse;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error getting property details', error);
  };
};
