import BaseApiService from '../BaseApiService';
import { OrderDetailsInterface } from '../../stores/BackofficeStores/BackofficeOrderStore';
import { OrderSummaryCard } from '../../BackOfficePages/OrderList/OrderDetails';


export interface OrderDetailListInterface {
    result: boolean;
    code: string;
    data: OrderDetailsInterface[];
    message: string;
}

export interface OrderDetailsResponse {
    result: boolean;
    code: string;
    data: OrderDetailsInterface;
    message: string;
}
export interface StructuredOrderDetailResponse {
    result: boolean;
    code: string;
    data: OrderSummaryCard[];
    message: string;
}

export const GetAllOrderDetails = async (propertyId : number) => {
  try {
    const response = await BaseApiService.get(`/Orders/GetOrdersByPropertyId?PropertyId=${String(propertyId)}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as OrderDetailListInterface;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error fetching order details', error);
  }
};

export const GetStructuredOrderItemsById = async (orderId : number) => {
  try {
    const response = await BaseApiService.get(`/Orders/GetStructuredOrderItemsById?OrderId=${String(orderId)}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as StructuredOrderDetailResponse;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error fetching order details', error);
  }
};

export const GetOrderDetailsById = async (orderId : number) => {
  try {
    const response = await BaseApiService.get(`/Orders/GetOrderDetailsById?OrderId=${String(orderId)}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as OrderDetailsResponse;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error fetching order details', error);
  };
};

export interface ClosePaymentForOrderCategoryInterface {
  orderId: number;
  categoryID: number;
  paymentMethodId: number;
  guestSignature: string;
  isPaymentConfirmed: boolean;
}

export interface ClosePaymentForOrderCategoryResponse {
  code: string;
  result: boolean;
  message: string;
  data: string;
}

export interface PaymentStatesInterface {
  result: boolean;
  code: string;
  data: ClosePaymentForOrderCategoryInterface[];
  message: string;
}

export const ClosePaymentForOrderCategory = async (orderDetails : ClosePaymentForOrderCategoryInterface) => {
  try {
    const response = await BaseApiService.post('/Orders/SetPaymentStateByID', orderDetails);
    if (response.data && typeof response.data === 'object') {
      return response.data as ClosePaymentForOrderCategoryResponse;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error fetching order details', error);
  };
};

export const GetPaymentStatesByID = async (orderId : number) => {
  try {
    const response = await BaseApiService.get(`/Orders/GetPaymentStatesByID?OrderId=${String(orderId)}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as PaymentStatesInterface;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error fetching order details', error);
  };
};
