import pizza from './images/pizza.svg';
import cheeseBurger from './images/cheeseBurger.svg';
import pasta from './images/pasta.jpg';
import fries from './images/fries.jpg';

export interface MenuItemType {
  id: number;
  name: string;
  description: string;
  price: number;
  quantity: number;
  image: string;
}

export interface OutletDetailsType {
  title: string;
  total_customers: {
    today: number;
    week: number;
    month: number;
  };
  total_orders: {
    today: number;
    week: number;
    month: number;
  };
  total_sales: {
    today: number;
    week: number;
    month: number;
  };
  total_pending_orders: number;
  sales_details: {
    August: number[];
    September: number[];
    October: number[];
  };
  top_sellers: MenuItemType[];
}

export const OutletDetailsData: OutletDetailsType[] = [
  {
    'title': 'In-room dining',
    'total_customers': {
      'today': 90,
      'week': 310,
      'month': 390
    },
    'total_orders': {
      'today': 320,
      'week': 619,
      'month': 939
    },
    'total_sales': {
      'today': 8065,
      'week': 15000,
      'month': 23065
    },
    'total_pending_orders': 28,
    'sales_details': {
      'August': [7033, 22372, 7694, 21741, 5706, 10533, 15759, 13345, 7473, 9090, 12490, 6297, 24893, 17088, 6125, 17410, 18433, 16775, 21544, 13126, 16420, 20198, 18566, 16185, 12873, 11398, 14468, 14247, 14967, 20688, 16728],
      'September': [13051, 12118, 18897, 12746, 24167, 11771, 20532, 12211, 20531, 11929, 16219, 10988, 15717, 13247, 16677, 12222, 20607, 9241, 6559, 12510, 13535, 7689, 7241, 22704, 9187, 17287, 23606, 13508, 12760],
      'October': [18425, 6972, 12932, 19948, 21063, 13854, 23405, 22295, 18867, 9732, 6206, 18311, 6114, 15925, 15257, 16670, 9420, 20604, 20099, 17612, 5232, 6592, 18179]
    },
    'top_sellers': [
      {
        id: 32345,
        name: 'Creamy Alfredo Pasta',
        description: 'Rich and creamy Alfredo sauce tossed with perfectly cooked fettuccine pasta, topped with fresh parsley.',
        price: 25,
        quantity: 15,
        image: pasta,
      },
      {
        id: 42345,
        name: 'Classic French Fries',
        description: 'Golden, crispy French fries lightly salted, served hot and fresh, perfect for dipping.',
        price: 12,
        quantity: 20,
        image: fries,
      },
      {
        id: 22345,
        name: 'Cheese Burger',
        description: 'Build your own burger with a beef patty, bun, and your choice of cheese, vegetables, and sauces.',
        price: 30,
        quantity: 10,
        image: cheeseBurger,
      }
    ]
  },
  {
    'title': 'Grooming & Beauty',
    'total_customers': {
      'today': 120,
      'week': 302,
      'month': 402
    },
    'total_orders': {
      'today': 120,
      'week': 354,
      'month': 474
    },
    'total_sales': {
      'today': 10591,
      'week': 21500,
      'month': 42091
    },
    'total_pending_orders': 35,
    'sales_details': {
      'August': [17341, 24430, 18197, 14147, 22283, 17763, 11310, 23294, 16983, 22385, 20880, 16718, 20134, 11660, 15428, 20679, 19268, 19594, 23649, 23055, 20916, 9330, 8823, 23289, 16230, 22716, 9455, 10509, 15534, 15370, 18877],
      'September': [21386, 24403, 16334, 14516, 20991, 16423, 23755, 11463, 11222, 14360, 20117, 17927, 10982, 18233, 13218, 21357, 18650, 23764, 15212, 21623, 22713, 20022, 12364, 19807, 17602, 12755, 23792, 16294, 24513, 24474],
      'October': [24783, 14579, 5479, 13471, 21836, 12060, 11298, 14365, 10211, 21991, 9164, 6331, 24420, 6169, 18243, 5204, 17946, 12350, 5299, 22017, 21047, 21294, 11334]
    },
    'top_sellers': [
      {
        id: 32345,
        name: 'Creamy Alfredo Pasta',
        description: 'Rich and creamy Alfredo sauce tossed with perfectly cooked fettuccine pasta, topped with fresh parsley.',
        price: 25,
        quantity: 15,
        image: pasta,
      },
      {
        id: 42345,
        name: 'Classic French Fries',
        description: 'Golden, crispy French fries lightly salted, served hot and fresh, perfect for dipping.',
        price: 12,
        quantity: 20,
        image: fries,
      }
    ]
  },
  {
    'title': 'Pastries Delight',
    'total_customers': {
      'today': 52,
      'week': 98,
      'month': 124
    },
    'total_orders': {
      'today': 189,
      'week': 389,
      'month': 489
    },
    'total_sales': {
      'today': 11920,
      'week': 22100,
      'month': 29707
    },
    'total_pending_orders': 73,
    'sales_details': {
      'August': [24682, 16150, 22187, 9942, 17412, 15797, 16341, 10510, 11143, 23062, 20182, 13218, 18302, 15209, 19562, 21856, 16089, 24592, 21832, 9229, 19690, 17461, 8442, 9537, 9797, 23290, 5106, 17752, 20853, 13738, 12577],
      'September': [18357, 22013, 7075, 20535, 9541, 24582, 11450, 18817, 7487, 12417, 14648, 7183, 24343, 10947, 6073, 9726, 12844, 15013, 24311, 18012, 20078, 19850, 9056, 13120, 17787, 10556, 12678, 20258, 16211, 17208],
      'October': [12535, 19160, 24690, 23847, 24797, 16268, 9027, 13734, 10249, 18166, 13340, 11220, 5483, 15864, 21927, 23422, 6465, 17669, 23589, 23186, 7160, 6661, 21894]
    },
    'top_sellers': [
      {
        id: 12345,
        name: 'Hawaiian BBQ Chicken Pizza',
        description: 'Build your own burger with a beef patty, bun, and your choice of cheese, vegetables, and sauces.',
        price: 33,
        quantity: 11,
        image: pizza,
      },
      {
        id: 22345,
        name: 'Cheese Burger',
        description: 'Build your own burger with a beef patty, bun, and your choice of cheese, vegetables, and sauces.',
        price: 30,
        quantity: 10,
        image: cheeseBurger,
      },
      {
        id: 42345,
        name: 'Classic French Fries',
        description: 'Golden, crispy French fries lightly salted, served hot and fresh, perfect for dipping.',
        price: 12,
        quantity: 20,
        image: fries,
      }
    ]
  },
  {
    'title': 'Beverages',
    'total_customers': {
      'today': 71,
      'week': 179,
      'month': 273
    },
    'total_orders': {
      'today': 156,
      'week': 359,
      'month': 573
    },
    'total_sales': {
      'today': 7590,
      'week': 18450,
      'month': 25945
    },
    'total_pending_orders': 21,
    'sales_details': {
      'August': [12857, 20198, 23660, 13573, 15717, 13240, 8827, 10492, 15637, 23058, 19721, 11796, 21186, 22180, 19983, 18797, 11898, 8315, 14414, 19682, 10353, 14068, 19758, 16125, 11000, 10423, 21744, 10126, 18415, 6813, 13768],
      'September': [11820, 12957, 13624, 19419, 7040, 5451, 24611, 7033, 14474, 19130, 13027, 11233, 10626, 24299, 11350, 5445, 17030, 18697, 17349, 14296, 20385, 11233, 9219, 23624, 23531, 23161, 24806, 14756, 6184, 16048],
      'October': [13490, 22295, 10415, 5054, 13263, 11810, 12466, 6070, 20108, 13180, 18700, 22689, 22485, 10863, 18901, 7421, 15352, 9863, 15979, 16788, 15390, 6172, 17221]
    },
    'top_sellers': [
      {
        id: 12345,
        name: 'Hawaiian BBQ Chicken Pizza',
        description: 'Build your own burger with a beef patty, bun, and your choice of cheese, vegetables, and sauces.',
        price: 33,
        quantity: 11,
        image: pizza,
      },
      {
        id: 32345,
        name: 'Creamy Alfredo Pasta',
        description: 'Rich and creamy Alfredo sauce tossed with perfectly cooked fettuccine pasta, topped with fresh parsley.',
        price: 25,
        quantity: 15,
        image: pasta,
      },
      {
        id: 42345,
        name: 'Classic French Fries',
        description: 'Golden, crispy French fries lightly salted, served hot and fresh, perfect for dipping.',
        price: 12,
        quantity: 20,
        image: fries,
      }
    ]
  },
  {
    'title': 'Grocery',
    'total_customers': {
      'today': 110,
      'week': 230,
      'month': 338
    },
    'total_orders': {
      'today': 180,
      'week': 499,
      'month': 787
    },
    'total_sales': {
      'today': 11293,
      'week': 21980,
      'month': 31489
    },
    'total_pending_orders': 69,
    'sales_details': {
      'August': [10038, 24234, 6736, 16412, 15242, 22530, 20402, 17252, 14785, 22828, 5091, 14606, 17087, 13464, 18086, 17422, 22592, 19448, 17685, 20807, 21927, 16952, 13741, 7893, 16360, 22892, 17548, 13343, 11398, 22810, 20646],
      'September': [13208, 12936, 13409, 11953, 10956, 12958, 20616, 20679, 21175, 5362, 12485, 24799, 11594, 23590, 21411, 18612, 24112, 6266, 14952, 16086, 19597, 13277, 20364, 15527, 10490, 14711, 11309, 19546, 17892, 19617],
      'October': [17792, 9115, 16409, 23184, 13221, 15705, 6252, 11271, 18011, 19693, 9362, 20273, 16745, 8189, 9812, 24047, 21330, 10809, 16533, 13160, 6314, 10150, 19717]
    },
    'top_sellers': [
      {
        id: 12345,
        name: 'Hawaiian BBQ Chicken Pizza',
        description: 'Build your own burger with a beef patty, bun, and your choice of cheese, vegetables, and sauces.',
        price: 33,
        quantity: 11,
        image: pizza,
      },
      {
        id: 22345,
        name: 'Cheese Burger',
        description: 'Build your own burger with a beef patty, bun, and your choice of cheese, vegetables, and sauces.',
        price: 30,
        quantity: 10,
        image: cheeseBurger,
      },
      {
        id: 32345,
        name: 'Creamy Alfredo Pasta',
        description: 'Rich and creamy Alfredo sauce tossed with perfectly cooked fettuccine pasta, topped with fresh parsley.',
        price: 25,
        quantity: 15,
        image: pasta,
      }
    ]
  }
];
