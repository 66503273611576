import BaseApiService from './BaseApiService';

export interface OrderDetailsResponse {
  code: string;
  message: string;
  result: boolean;
  data: OrderDetailsInterface;
};

export interface NewOrderInterface {
  orderDetails: OrderDetailsInterface;
  orderItems: OrderItems[];
};

export interface OrderDetailsInterface {
  orderID?: number;
  propertyID: number;
  confirmationID: number;
  orderTotal?: number;
  paymentMethodId: number;
  guestSignature?: string;
  orderStatus: number;
  timestamp: string;
  guestNotes: string;
};

export interface OrderItems {
  orderID?: number;
  menuItemID: number;
  quantity: number;
  price?: number;
}

export const PlaceOrder = (orderDetails: NewOrderInterface) => {
  BaseApiService.post('/Orders/AddNewOrder', orderDetails)
    .then((response) => {
      console.log(response);
    })
    .catch((error : unknown) => {
      console.log(error);
    }).finally(() => {
      console.log('finally');
    });
};
