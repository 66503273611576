import { Heading, HStack, IconButton, VStack } from '@chakra-ui/react';
import { MenuItemForm } from './components/MenuItemForm';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useBackofficeRootStore } from '../../hooks/useBackofficeRootStore';


export const AddMenuItem = () => {

  const rootStore = useBackofficeRootStore();

  return (
    <VStack alignItems={'flex-start'} alignSelf={'flex-start'} mt={10} ml={10}>
      <HStack>
        <IconButton
          aria-label='Go to Menu Master'
          mb={1}
          color={'#343A40'}
          size={'md'}
          isRound
          variant={'outline'}
          icon={<ArrowBackIcon />}
          borderWidth={'thin'}
          borderColor={'#343A40'}
          mr={2}
          onClick={() => {
            rootStore.setRoute('menumaster');
          }}
        />
        <Heading color={'#343A40'}>Add New Menu Item</Heading>
      </HStack>
      <MenuItemForm addItem={true}/>
    </VStack>
  );
};
