import React from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Image, Text, Flex } from '@chakra-ui/react';
import { MenuItemType } from '../TopSellerDetails';

export interface TopSellerInput {
  items: MenuItemType[];
}

export const TopSellerTable: React.FC<TopSellerInput> = ({ items }) => {
  return (
    <Box overflowX="auto" >
      <Table variant="simple">
        <Thead bg="#f0f4f8" >
          <Tr>
            <Th fontFamily="inherit" borderRadius="10px 0 0 10px">Product Name</Th>
            <Th fontFamily="inherit">Price</Th>
            <Th fontFamily="inherit">Sold</Th>
            <Th fontFamily="inherit" borderRadius="0 10px 10px 0">Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map((item: MenuItemType) => (
            <Tr key={item.id}>
              <Td>
                <Flex align="center">
                  <Image src={item.image} alt={item.name} boxSize="50px" mr={4} />
                  <Text>{item.name}</Text>
                </Flex>
              </Td>
              <Td>
                <Text>${item.price}</Text>
              </Td>
              <Td>
                <Text>{item.quantity || 'N/A'}</Text>
              </Td>
              <Td>
                <Text>${(item.price * item.quantity).toFixed(2)}</Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
