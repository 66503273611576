import BaseApiService from '../BaseApiService';

export interface OutletsInterface {
result: boolean;
code: string;
data: OutletApiResponse;
message: string;
}

export interface OutletApiResponse {
outlets: Outlet[];
totalItems: number;
}

export interface Outlet {
  outletId: number;
  categoryId: number;
  outletName: string;
  propertyId: number;
  description: string;
  outletImage: string;
  isActive: boolean;
  isDeleted: boolean;
  createdOn: string;
  createdBy: number;
  modifiedOn: string;
  modifiedBy: number;
  about: string;
  phoneNumber: string;
  isBookingEnabled: boolean;
  location: string;
}

export interface OutletForm {
  outletId?: number;
  outletName: string;
  outletCode: string;
  propertyId: number;
  categoryId: number;
  isActive: boolean;
  createdOn: string;
  createdBy: number;
  modifiedOn: string;
  modifiedBy: number;
  isBookingEnabled: boolean;
  outletImage: string;
}

export interface OutletResponseInterface {
  result: boolean;
  code: string;
  data: Outlet;
  message: string;
}

export const GetAllOutletsByPropertyId = async (propertyId : number, pageNumber: number, pageSize: number) => {
  try {
    const response = await BaseApiService.get<OutletsInterface>(`/Outlets/GetOutletsByPropertyIdAdmin?PropertyId=${String(propertyId)}&PageNumber=${String(pageNumber)}&PageSize=${String(pageSize)}`);
    return response.data;
  } catch (error) {
    console.error('Error while fetching outlets by property id', error);
    return null;
  }
};

export const AddOutletService = async (outlet: OutletForm) => {
  try {
    const response = await BaseApiService.post<OutletResponseInterface>('/Outlets/AddOutlet/', outlet);
    return response.data;
  } catch (error) {
    console.error('Error adding new outlet', error);
    return null;
  }
};

export const DeleteOutletService = async (outletId: number) => {
  try {
    const response = await BaseApiService.delete<OutletResponseInterface>(`/Outlets/DeleteOutlet?OutletId=${String(outletId)}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting outlet', error);
    return null;
  }
};
