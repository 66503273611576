import { Box, Stack, Text } from '@chakra-ui/react';
import SignatureCanvas from 'react-signature-canvas';
import { useWindow } from '../../../hooks/useWindow';
import { observer } from 'mobx-react';
import { useBackofficeRootStore } from '../../../hooks/useBackofficeRootStore';
import { useEffect, useRef } from 'react';

const PaymentsCloseSignature = observer(({ signatureData, setSignatureData }: {signatureData: string | null, setSignatureData: React.Dispatch<React.SetStateAction<string | null>>}) => {
  const { isTablet, isDesktop } = useWindow();
  const { backofficeOrderStore  } = useBackofficeRootStore();
  const sigCanvasRef = useRef<SignatureCanvas>(null);

  useEffect(() => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.fromDataURL(String(backofficeOrderStore.currentOrderDetails.guestSignature));
    }
  }, [backofficeOrderStore.currentOrderDetails.guestSignature]);

  const handleEnd = () => {
    if (sigCanvasRef.current) {
      setSignatureData(sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png'));
    }
    console.log('signatureData', signatureData);
  };

  return (
    <>
      <Stack
        spacing={4}
        align="center"
        width="100%"
        maxW="900px"
        padding="16px"
        borderRadius="14px"
      >
        <Text fontSize="14px" color="#8a8d91">
          Signature of the guest
        </Text>
        <Box
          border="1px solid #d3d3d3"
          borderRadius="10px"
          bg="#ffffff"
          overflow="hidden"
          width={isTablet ? 300 : isDesktop ? 500 : 180}
          height={isTablet ? 200 : isDesktop ? 250 : 150}
        >
          <SignatureCanvas
            penColor="black"
            canvasProps={{
              className: 'sigCanvas',
              width: isTablet ? 300 : isDesktop ? 500 : 180,
              height: isTablet ? 200 : isDesktop ? 250 : 150,
            }}
            ref={sigCanvasRef}
            onEnd={handleEnd}
          />
        </Box>
      </Stack>
    </>
  );
});
export default PaymentsCloseSignature;
