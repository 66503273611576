import { PropertyType } from '../../stores/BackofficeStores/BackofficePropertyTypeStore';
import BaseApiService from '../BaseApiService';

export interface PropertyTypeDetailInterface {
  result: boolean;
  code: string;
  data: PropertyType[];
  message: string;
}

export interface PropertyTypeDeleteInterface {
  result: boolean;
  code: string;
  message: string;
  data: boolean;
}

export const GetAllPropertyTypeService = async () => {
  try {
    const response = await BaseApiService.get('/PropertyType/GetAllPropertyTypes');
    if (response.data && typeof response.data === 'object') {
      return response.data as PropertyTypeDetailInterface;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error fetching property types', error);
  }
};

export const DeletePropertyTypeService = async (propertyTypeId: number) => {
  try {
    const response = await BaseApiService.delete(`/PropertyType/DeletePropertyType?PropertyTypeId=${propertyTypeId.toString()}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as PropertyTypeDeleteInterface;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error fetching property types', error);
  }
};
