import React from 'react';
import { Button, Heading, HStack, IconButton, Image, Text, VStack } from '@chakra-ui/react';
import { MenuItemType } from './MenuItemsData';

import styles from './MenuItemsStyle.module.css';
import { useRootStore } from '../../../hooks/useRootStore';
import { observer } from 'mobx-react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { EmptyMenuItems } from './EmptyMenuItems';


export const MenuItemContent = (props: Omit<MenuItemType, 'category' | 'outletId'>) => {
  return (
    <HStack spacing={4} justify={'space-between'} align={'start'} height={'100%'}>
      <Image src={props.image} alt={props.name} width={'125px'} height={'125px'} borderRadius={'12px'} fallbackSrc='https://via.placeholder.com/150' loading="lazy" />
      <VStack spacing={2} justify={'space-between'} align={'start'} className={styles.MenuItemContentText}>
        <VStack align={'start'} gap={0} height={'100%'} justify={'start'}>
          <Heading size={'medium'} as={'h3'} fontWeight={'800'}>
            {props.name}
          </Heading>
          <Text fontSize={'small'} color={'gray.500'} noOfLines={3} opacity={0.75} lineHeight={'1'}>
            {props.description}
          </Text>
        </VStack>
        <Text fontSize={'md'} fontWeight={'bold'}>
          AED {props.price}
        </Text>
      </VStack>
    </HStack>
  );
};

export const AddToCartButton = observer(({ id, quantity }: { id: number, quantity: number }) => {

  const { menuStore } = useRootStore();
  const { t } = useTranslation();

  return (
    <VStack p={0}>
      {quantity > 0 ? (
        <VStack className={styles.PlusMinusButton} height={'100%'} p={0}>
          <IconButton
            className={styles.cartInnerTopButton}
            aria-label="Increase quantity"
            icon={<AddIcon width={'20px'} height={'20px'} />}
            colorScheme="orange"
            onClick={() => {
              menuStore.addToCart(id, menuStore.selectedOutlet);
            }}
            p={1}
          />
          <Text>{quantity}</Text>
          <IconButton
            className={styles.cartInnerBottomButton}
            aria-label="Decrease quantity"
            icon={<MinusIcon width={'20px'} height={'20px'} />}
            colorScheme="orange"
            onClick={() => {
              menuStore.removeFromCart(id);
            }}
            p={1}
          />
        </VStack>
      ) : (
        <Button className={styles.AddToCartButton}
          onClick={() => {
            menuStore.addToCart(id, menuStore.selectedOutlet);
          }}
        >
          <Image src={'./images/shopping-basket.svg'} alt={'shopping basket'} width={'34px'} height={'34px'} className={styles.AddToCartButtonIcon} />
          <Text fontSize={'xx-small'} whiteSpace={'normal'} fontWeight={'100'}>
            {t('add_to_basket')}
          </Text>
        </Button>
      )}
    </VStack>
  );
});
export const MenuItem = (props: Omit<MenuItemType, 'outletId' | 'category'>) => {
  return (
    <VStack align={'center'} spacing={4} width={'100%'} className={styles.MenuItemContainer}>
      <HStack spacing={4} width={'100%'} className={styles.MenuItem} justify={'space-between'}>
        <MenuItemContent {...props} />
        <AddToCartButton id={props.id} quantity={props.stock} />
      </HStack>
      <div className={styles.divider} />
    </VStack>
  );
};

export const MenuItemsList = observer(({ MenuItems }: { MenuItems: MenuItemType[] }) => {

  const { cartStore } = useRootStore();

  return (
    <VStack align={'start'} spacing={4} width={'100%'} maxWidth={'900px'} mb={16}>
      {MenuItems.length > 0 ?
        MenuItems.map((item, index) => (
          <MenuItem key={index} name={item.name} description={item.description} price={item.price} image={item.image}
            stock={cartStore.cartCountForItem(item.id)}
            id={item.id} />
        ))
        :
        <EmptyMenuItems />
      }
    </VStack>
  );
});
