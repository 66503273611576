import { CartStore } from './CartStore';
import { MenuStore } from './MenuStore';
import { PropertyStore } from './PropertyStore';
import { RoomStore } from './RoomStore';

export class RootStore {
  menuStore: MenuStore;
  cartStore: CartStore;
  roomStore: RoomStore;
  propertyStore: PropertyStore;

  constructor(propertyId: number) {
    this.propertyStore = new PropertyStore(this, propertyId);
    this.menuStore = new MenuStore(this);
    this.cartStore = new CartStore(this);
    this.roomStore = new RoomStore(this);
  }
}
