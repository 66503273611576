import { Heading, HStack, IconButton, VStack } from '@chakra-ui/react';
import { AddPropertyForm } from './components/AddPropertyForm';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useBackofficeRootStore } from '../../hooks/useBackofficeRootStore';


export const AddProperty = () => {

  const rootStore = useBackofficeRootStore();

  return (
    <VStack alignItems={'flex-start'} alignSelf={'flex-start'} mt={10} ml={10}>
      <HStack>
        <IconButton
          aria-label='Go to Property Master'
          mb={1}
          color={'#343A40'}
          size={'md'}
          isRound
          variant={'outline'}
          icon={<ArrowBackIcon />}
          borderWidth={'thin'}
          borderColor={'#343A40'}
          mr={2}
          onClick={() => {
            rootStore.setRoute('propertymaster');
          }}
        />
        <Heading color={'#343A40'}>Add New Property</Heading>
      </HStack>
      <AddPropertyForm />
    </VStack>
  );
};
