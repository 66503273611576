import { Box, Heading, Button } from '@chakra-ui/react';
import SearchBar from '../../../../BackOfficeComponents/SearchBar';
import OutletTable from './Components/OutletTable';
import { observer } from 'mobx-react';
import { useBackofficeRootStore } from '../../../../hooks/useBackofficeRootStore';
const OutletDetails = observer(() => {

  const rootStore = useBackofficeRootStore();

  return (
    <Box bg="#eeeeee" minH="100vh" p={4} width={'100%'}>
      <Box mb={6} p={6}>
        <Box mb={6} p={6} bg="white" borderRadius="lg" boxShadow="lg">
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
            <Heading as="h5" size="md" fontFamily="inherit">Outlet Master</Heading>
            <Box>
              <Button m="3px" bg="white" color='#404040' borderColor="#404040" borderWidth="2px"
                borderStyle="solid"_hover={{ bg: 'white' }}
                _active={{ bg: 'white' }} size="sm" >Bulk Upload</Button>
              <Button m="5px" bg="#404040" color="white" _hover={{ bg: '#404040' }}
                _active={{ bg: '#404040' }} size="sm"
                onClick={() => {
                  rootStore.setRoute('addoutlet');
                }}>Add Outlet</Button>
            </Box>
          </Box>
          <SearchBar onChangeHandler={rootStore.backofficeOutletStore.setSearchQuery}/>
          <OutletTable/>
        </Box>
      </Box>
    </Box>
  );
});

export default OutletDetails;
