import { Box, Heading, Button } from '@chakra-ui/react';
import MenuTable from './Components/MenuTable';
import SearchBar from '../../../../BackOfficeComponents/SearchBar';
import React from 'react';
import { observer } from 'mobx-react';
import { useBackofficeRootStore } from '../../../../hooks/useBackofficeRootStore';

const MenuDetailsByOutletId = observer(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const outletId = Number(urlParams.get('outletId'));
  const { backofficeMenuItemStore } = useBackofficeRootStore();

  const outletIdNumber = outletId ? Number(outletId) : undefined;

  if (!outletIdNumber || isNaN(outletIdNumber)) {
    console.error('Invalid outletId:', outletId);
    return <Box>Error: Outlet ID is missing or invalid</Box>;
  }

  return (
    <Box bg="#eeeeee" w="100%" p={4}>
      <Box mb={6} p={6} bg="white" borderRadius="lg" boxShadow="lg">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Heading as="h5" size="md" fontFamily="inherit">Menu Master for OutletId {outletId}</Heading>
          <Button m="5px" bg="#404040" color="white" _hover={{ bg: '#404040' }}
            _active={{ bg: '#404040' }}>Add new</Button>
        </Box>
        <SearchBar onChangeHandler={backofficeMenuItemStore.setSearchQuery}/>
        <MenuTable outletId = {outletIdNumber} />
      </Box>
    </Box>
  );
});

export default MenuDetailsByOutletId;
