import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { MenuItemType } from '../ClientPages/MenuPage/components/MenuItemsData';
import { PlaceOrder } from '../services/OrderService';

export class CartStore {
  rootStore: RootStore;
  cartItems: MenuItemType[];
  total: number;
  orderNotes: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const savedCartItems = localStorage.getItem('userCart');
    this.cartItems = savedCartItems ? JSON.parse(savedCartItems) as MenuItemType[] : [];
    this.total = 0;
    this.orderNotes = localStorage.getItem('orderNotes') ?? '';

    makeAutoObservable(this, { cartItems: true, total: true });
  }

  addToCart = (id: number, outletId: number) => {
    // menu Items is [{outletId, menuItems}]
    const item = this.rootStore.menuStore.MenuItems.find(outlet => outlet.outletId === outletId)?.menuItems.find(item => item.id === id);
    if (item) {
      const cartItem = this.cartItems.find(cartItem => cartItem.id === id);
      if (cartItem) {
        cartItem.stock += 1;
      } else {
        this.cartItems.push({ ...item, stock: 1, outletId });
      }
      this.total += item.price;
    }
    localStorage.setItem('userCart', JSON.stringify(this.cartItems));
  };

  incrementCartItem = (id: number) => {
    const cartItem = this.cartItems.find(cartItem => cartItem.id === id);
    if (cartItem) {
      cartItem.stock += 1;
      this.total += cartItem.price;
    }
    localStorage.setItem('userCart', JSON.stringify(this.cartItems));
  };

  removeFromCart = (id: number) => {
    const cartItem = this.cartItems.find(cartItem => cartItem.id === id);
    if (cartItem) {
      cartItem.stock -= 1;
      this.total -= cartItem.price;
      if (cartItem.stock === 0) {
        this.cartItems = this.cartItems.filter(cartItem => cartItem.id !== id);
      }
    }
    localStorage.setItem('userCart', JSON.stringify(this.cartItems));
  };

  deleteFromCart = (id: number) => {
    const cartItem = this.cartItems.find(cartItem => cartItem.id === id);
    if (cartItem) {
      this.total -= cartItem.stock * cartItem.price;
      this.cartItems = this.cartItems.filter(cartItem => cartItem.id !== id);
    }
    localStorage.setItem('userCart', JSON.stringify(this.cartItems));
  };

  clearCart = () => {
    this.cartItems = [];
    this.total = 0;
    localStorage.removeItem('userCart');
  };

  cartCountForItem = (id: number) => {
    const item = this.cartItems.find(item => item.id === id);
    return item ? item.stock : 0;
  };

  setOrderNotes = (notes: string) => {
    this.orderNotes = notes;
    localStorage.setItem('orderNotes', notes);
  };

  get cartCount() {
    return this.cartItems.reduce((acc, item) => acc + item.stock, 0);
  }

  get cartTotal() {
    return this.cartItems.reduce((acc, item) => acc + item.stock * (item.price), 0);
  }

  get cartTotalString() {
    return `AED ${this.cartTotal.toFixed(2)}`;
  }

  placeOrder = (guestSignatureData?: string) => {
    const newOrder = {
      orderDetails: {
        propertyID: this.rootStore.propertyStore.propertyId,
        confirmationID: Number(this.rootStore.roomStore.roomDetails.confirmationNo),
        orderTotal: this.cartTotal,
        paymentMethodId: this.rootStore.roomStore.selectedPayment?.paymentMethodId ?? 0,
        orderStatus: 0,
        timestamp: new Date().toISOString(),
        guestNotes: this.orderNotes,
        ...(guestSignatureData ? { guestSignature: guestSignatureData } : {}),
      },
      orderItems: this.cartItems.map((item) => ({
        menuItemID: item.id,
        quantity: item.stock,
      })),
    };

    // console.log('Placing order', newOrder);

    PlaceOrder(newOrder);
  };
}
