// api call to base api service + Menuitems/Getmenuitemsbyoutlet?outletId=10
import { MenuItemType } from '../ClientPages/MenuPage/components/MenuItemsData';
import BaseApiService from './BaseApiService';

// Result = true,
// Code = "1001",
// Data = result,
// Message = "Success"
export interface MenuItemsInterface {
  result: boolean;
  code: string;
  data: MenuItemType[];
  message: string;
}

export interface MenuItem {
  id: number;
  name: string;
  description: string;
  imageId: number;
  OutletId: number;
  Price: number;
  isAvailable: boolean;
  IsExhaustable : boolean;
  Stock : number;
}


export const GetMenuItemsService = async (outletId: number) => {
  try {
    const response = await BaseApiService.get(`/Menuitems/Getmenuitemsbyoutlet?outletId=${outletId.toString()}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as MenuItemsInterface;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error fetching menu items', error);
  }
};
