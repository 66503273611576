import React from 'react';
import styles from './login.module.css';
import { useState } from 'react';


interface LoginProps {
    buttonText: string;
}

export const Login: React.FC<LoginProps> = ({ buttonText }) => {

  const[roomNumber, setRoomNumber] = useState('');
  const[lastName, setLastName] = useState('');
  return (
    <div className={styles.loginBox}>
      <form>
        <div className = {styles.roomNumber}>
          <h3>Room number</h3>
          <input
            type = "text"
            value = {roomNumber}
            name = "roomNumber"
            onChange = { ev => {
              setRoomNumber(ev.target.value);
            }}
          />
        </div>
        <div className = {styles.lastName}>
          <h3>Last Name</h3>
          <input
            type = "text"
            value = {lastName}
            onChange = { ev => {
              setLastName(ev.target.value);
            }}
          />
        </div>
      </form>
      <button
        className = {styles.button}
        type="submit">
        {buttonText}
      </button>

    </div>

  );
};
