import BaseApiService from './BaseApiService';

export interface RoomVerifyInterface {
  result: boolean;
  code: string;
  data: RoomDetails;
  message: string;
}

export interface RoomDetails {
  confirmationID: number;
  confirmationNo: string;
  paxCount: number;
  checkinDate: string;
  checkoutDate: string;
  isBookingEligible: number;
}
export interface DetailedRoomDetails {
  id: number;
  confirmationNo: string;
  adultCount: number;
  childCount: number;
  noShowFlag: boolean;
  processFlag: boolean;
  marketCode: string;
  marketDescription: string;
  checkoutDate: string;
  updatedDateTime: string;
  createdBy: string;
  reservationNameId: string;
  familyName: string;
  givenName: string;
  roomNumber: string;
  propertyId: number;
  profileId: string;
  sharerId: string;
  accompanyFlag: boolean;
  sharerFlag: boolean;
  checkinDate: string;
  paymentType: string;
  reservationCreatedDate: string;
  address: string;
  phoneNo: string;
  emailId: string;
  reservationStatus: string;
  roomType: string;
  roomId: number;
  postalCode: string;
  isGuestComments: boolean;
  ispreferences: boolean;
  isAlerts: boolean;
}

export interface RoomDetailsResponse {
  result: boolean;
  code: string;
  data: DetailedRoomDetails;
  message: string;
}

export const RoomVerifyService = async (propertyId: number, roomNo: string, lastName: string) => {
  try {
    const response = await BaseApiService.get(`/BookingConfirmation/GetBookingConfirmationWithRoomAndLastName?PropertyId=${String(propertyId)}&RoomNo=${roomNo}&LastName=${lastName}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as RoomVerifyInterface;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error verifying room', error);
  }
};

export const GetRoomDetails = async (confirmationID: number) => {
  try{
    const response = await BaseApiService.get(`/BookingConfirmation/GetBookingConfirmationById?Id=${String(confirmationID)}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as RoomDetailsResponse;
    } else {
      throw new Error('Invalid response data');
    }
  } catch(error){
    console.error('Error fetching room details', error);
  }
};
