import React from 'react';
import { Skeleton } from '@chakra-ui/react';

export const PaymentOptionsLoading = () => {
  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => (
        <Skeleton key={index} height={10} width={'100%'} mb={0.3} borderRadius={2} />
      ))}
    </>
  );
};
