import React from 'react';
import { Button, HStack, Text, VStack } from '@chakra-ui/react';

import styles from './bottomBar.module.css';

interface BottomBarProps {
  enabled?: boolean;
  totalTitle: string;
  total: string;
  buttonText: string;
  buttonAction: () => void;
}

export const BottomBar = ({ enabled=true, totalTitle, total, buttonText, buttonAction }: BottomBarProps) => {
  // const [isAtBottom, setIsAtBottom] = React.useState(false);
  // const [bottomBarClassNames, setBottomBarClassNames] = React.useState(styles.bottomBar);

  // React.useEffect(() => {
  //   const onscroll = () => {
  //     const scrolledTo = window.scrollY + window.innerHeight;
  //     const threshold = 10;
  //     const isReachBottom = document.body.scrollHeight - threshold <= scrolledTo;
  //     if (isReachBottom && document.body.scrollHeight > window.innerHeight) {
  //       setIsAtBottom(true);
  //     } else {
  //       setIsAtBottom(false);
  //     }
  //   };
  //   onscroll();
  //   window.addEventListener('scroll', onscroll);
  //   return () => {
  //     window.removeEventListener('scroll', onscroll);
  //   };
  // }, []);


  // React.useEffect(() => {
  //   if(isAtBottom) {
  //     setBottomBarClassNames(styles.persistentBottomBar + ' ' + styles.persistentBottomBarHidden + ' ' + styles.bottomBar);
  //   } else {
  //     setBottomBarClassNames(styles.persistentBottomBar + ' ' + styles.bottomBar);
  //   }
  // }, [isAtBottom]);


  return (
    <VStack
      aria-label='Bottom bar'
      className={styles.persistentBottomBar + ' ' + styles.bottomBar}
    >
      {/* Up arrow when isAtTheBottom */}
      {/* {isAtBottom && (
        <VStack
          width={'100%'}
          justify={'center'}
          gap={0}
          marginBottom={2}
          onClick={() => {
            setBottomBarClassNames(
              bottomBarClassNames.includes(styles.persistentBottomBarHidden)
                ? styles.persistentBottomBar + ' ' + styles.bottomBar
                : styles.persistentBottomBar + ' ' + styles.persistentBottomBarHidden + ' ' + styles.bottomBar
            );
          }
          }
        >
          <ChevronUpIcon
            aria-label='Up arrow'
            fontSize={'xx-large'}
            transform={bottomBarClassNames.includes(styles.persistentBottomBarHidden) ? 'rotate(0deg)' : 'rotate(180deg)'}
            className={styles.arrowUp}
          />
          <hr className={styles.line} />
        </VStack>
      )} */}
      <HStack
        justify={'space-between'}
        width={'100%'}
      >
        <VStack className = {styles.totalAmount} align={'start'} spacing={0}>
          <Text className={styles.totalTitle} fontSize={'medium'} fontWeight={'600'}>
            {totalTitle}
          </Text>
          <Text fontSize={'x-large'} fontWeight={'600'}>
            {total}
          </Text>
        </VStack>
        <Button
          colorScheme='primary'
          bg={'primary.600'}
          color={'white'}
          borderRadius={46}
          className = {styles.button}
          onClick={buttonAction}
          whiteSpace={'wrap'}
          isDisabled={!enabled}
        >
          {buttonText}
        </Button>
      </HStack>
    </VStack>
  );
};

