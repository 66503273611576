import BaseApiService from '../BaseApiService';
import { MenuItemType } from '../../stores/BackofficeStores/BackofficeMenuItemStore';


export interface MenuItemDeletedResponse {
  result: boolean;
  code: string;
  data: boolean;
  message: string;
}

export interface MenuItemsApiResponse {
  menuItems: MenuItemType[];
  totalItems: number;
}

export interface MenuItemsInterface {
  result: boolean;
  code: string;
  data: MenuItemsApiResponse;
  message: string;
}

export interface MenuItemsPerOutletInterface {
  result: boolean;
  code: string;
  data: MenuItemType[];
  message: string;
}

export interface MenuItemInterface {
  result: boolean;
  code: string;
  data: MenuItemType;
  message: string;
}


export const GetMenuItemById = async (menuId: number) => {
  try {
    const response = await BaseApiService.get(`/MenuItems/GetMenuItemById?MenuItemId=${String(menuId)}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as MenuItemInterface;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error fetching menu items', error);
  }
};

export const GetAllMenuItemsService = async (pageNumber: number, pageSize: number) => {
  try {
    const response = await BaseApiService.get('/Menuitems/GetAllMenuItems?pageNumber=' + String(pageNumber) + '&pageSize=' + String(pageSize));
    if (response.data && typeof response.data === 'object') {
      return response.data as MenuItemsInterface;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error fetching menu items', error);
  }
};

export const GetMenuItemsByOutletId = async (outletId: number) => {
  try {
    const response = await BaseApiService.get(`/Menuitems/GetMenuItemsByOutletAdmin?OutletId=${String(outletId)}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as MenuItemsPerOutletInterface;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error fetching menu items', error);
  }
};

export interface MenuItemProps {
  id?: number;
  itemDisplayNumber?: number;
  name: string;
  description: string;
  image: string;
  price: number;
  outletId: number[];
  isAvailable: boolean;
  isExhaustable: boolean;
  stock: number;
  maxCartQty: number;
}

export const AddMenuItemService = async (menu: MenuItemProps) => {
  try {
    // for every outlet id, add a menu item
    //     curl -X 'POST' \
    //   'https://localhost:5001/api/MenuItems/AddNewMenuItem' \
    //   -H 'accept: text/plain' \
    //   -H 'Content-Type: application/json' \
    //   -d '{
    //   "name": "Apple",
    //   "description": "an apple a day keeps the doctor away",
    //   "image": "",
    //   "outletId": 1,
    //   "price": 20,
    //   "isAvailable": true,
    //   "isExhaustable": true,
    //   "stock": 25,
    //   "maxCartQty": 5
    // }
    const response = await Promise.all(menu.outletId.map(async (outletId) => {
      return await BaseApiService.post('/MenuItems/AddNewMenuItem', {
        name: menu.name,
        description: menu.description,
        image: menu.image,
        price: menu.price,
        outletId: outletId,
        isAvailable: menu.isAvailable,
        isExhaustable: menu.isExhaustable,
        stock: menu.stock,
        maxCartQty: menu.maxCartQty,
      });
    }));
    if (response.every((res) => res.data && typeof res.data === 'object')) {
      return response.map((res) => res.data as MenuItemDeletedResponse);
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error deleting property', error);
  }
};

export const UpdateMenuItemService = async (menu: MenuItemProps) => {
  try {
    const response = await BaseApiService.put('/MenuItems/UpdateMenuItem', {
      id: menu.id,
      itemDisplayNumber: menu.itemDisplayNumber,
      name: menu.name,
      description: menu.description,
      image: menu.image,
      price: menu.price,
      outletId: menu.outletId[0], // For now, adding only to first outlet in update
      isAvailable: menu.isAvailable,
      isExhaustable: menu.isExhaustable,
      stock: menu.stock,
      maxCartQty: menu.maxCartQty,
    });
    if (response.data && typeof response.data === 'object') {
      return response.data as MenuItemDeletedResponse;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error updating property', error);
  }
};

export const DeleteMenuItemService = async (menuId: number) => {
  try {
    const response = await BaseApiService.delete<MenuItemDeletedResponse>(`/Menuitems/DeleteMenuItem/${String(menuId)}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting menu item', error);
    return null;
  }
};
