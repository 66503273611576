import { VStack, Text, Modal, ModalBody, ModalOverlay, ModalFooter, ModalContent, useDisclosure, Button, Select } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import PaymentsCloseSignature from './Components/PaymentsCloseSignature';
import PaymentsCloseCard from './Components/PaymentsCloseCard';
import { observer } from 'mobx-react';
import { useBackofficeRootStore } from '../../hooks/useBackofficeRootStore';
import { PaymentMethodsInterface } from '../../services/GetPaymentMethodsService';

export const PaymentsClose = observer(({ buttonText, orderCategoryID, disabled, paymentMethodId }: { buttonText: string, orderCategoryID: number, disabled?: boolean, paymentMethodId: number }) => {
  // const { isTablet, isDesktop } = useWindow();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { backofficeOrderStore } = useBackofficeRootStore();

  useEffect(() => {
    backofficeOrderStore.fetchPaymentMethods();
    setPaymentOption(backofficeOrderStore.paymentMethods.find((paymentMethod) => paymentMethod.paymentMethodId === paymentMethodId));
  }, [backofficeOrderStore, paymentMethodId]);

  const [paymentOption, setPaymentOption] = useState<PaymentMethodsInterface>();

  const [signatureData, setSignatureData] = useState<string | null>(null);

  // Handle payment option change
  const handlePaymentOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPaymentOption(backofficeOrderStore.paymentMethods.find((paymentMethod) => paymentMethod.paymentMethodId === Number(event.target.value)));
  };

  return (
    <>
      <Button onClick={onOpen}
        colorScheme='neutral'
        isDisabled={disabled}
      >{buttonText}</Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent bg={'#F2F5F8'} margin={2}>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Text fontSize="lg" fontWeight="bold">
                                Confirm payment
              </Text>
              <Select onChange={handlePaymentOptionChange} defaultValue={paymentMethodId}>
                {
                  backofficeOrderStore.paymentMethods.map((paymentMethod) => (
                    <option key={paymentMethod.paymentMethodId} value={paymentMethod.paymentMethodId}>{paymentMethod.paymentMethodName}</option>
                  ))
                }
              </Select>

              {paymentOption?.isSignatureRequired && <PaymentsCloseSignature signatureData={signatureData} setSignatureData={setSignatureData} />}
              {paymentOption?.paymentMethodName.toLowerCase() === 'pay with card' && <PaymentsCloseCard />}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose} color='primary'>
              Close
            </Button>
            <Button colorScheme="blue" disabled={!paymentOption}
              onClick={() => {
                backofficeOrderStore.closePaymentForOrderCategory(orderCategoryID, paymentOption?.paymentMethodId ?? 0);
                onClose();
              }}>
                Confirm payment
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});
