// axios api instance to make requests to the backend
import axios, { AxiosInstance } from 'axios';

// add Access-Control-Allow-Origin header to the request
const BaseApiService: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL ?? 'https://localhost:5002/Api',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export default BaseApiService;
