import BaseApiService from './BaseApiService';

export interface PaymentMethodsInterface {
  paymentMethodId: number;
  paymentMethodName: string;
  propertyId: number;
  description: string;
  isSignatureRequired: boolean;
};

export interface PaymentMethodsResponse {
  result: boolean;
  code: string;
  data: PaymentMethodsInterface[];
  message: string;
};

export interface PaymentCardInterface {
  id: number;
  name: string;
  image: string;
};

export interface PaymentCardResponse {
  result: boolean;
  code: string;
  data: PaymentCardInterface[];
  message: string;
};

export const GetPaymentMethodsService = async (propertyId: number) => {
  try {
    const response = await BaseApiService.get(`/PaymentMethods/GetPaymentMethodsByProperty?PropertyId=${String(propertyId)}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as PaymentMethodsResponse;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error getting payment methods', error);
  }
};

export const GetPaymentCardsService = async () => {
  try {
    const response = await BaseApiService.get('/PaymentCard/GetAllPaymentCards');
    if (response.data && typeof response.data === 'object') {
      return response.data as PaymentCardResponse;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error getting payment cards', error);
  }
};
