import { AddPropertyInterface, EditPropertyInterface, PropertyDetail } from '../../stores/BackofficeStores/BackofficePropertyStore';
import BaseApiService from '../BaseApiService';

export interface PropertyDetailInterface {
result: boolean;
code: string;
data: PropertyApiData;
message: string;
}

export interface PropertyApiData {
  propertyDetails: PropertyDetail[];
  totalCount: number;
}

export interface PropertyDeletedResponse {
  result: boolean;
  code: string;
  data: boolean;
  message: string;
}

export interface PropertyAddedResponse {
  code: string,
  result: boolean,
  message: string,
  data: string
}

export interface PropertyUpdatedResponse {
  code: string,
  result: boolean,
  message: string,
  data: string
}

export const GetAllPropertyDeatilsService = async (pageNumber: number, pageSize: number) => {
  try {
    const response = await BaseApiService.get('/PropertyDetails/GetAllPropertyDetails?pageNumber=' + String(pageNumber) + '&pageSize=' + String(pageSize));
    if (response.data && typeof response.data === 'object') {
      return response.data as PropertyDetailInterface;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error fetching menu items', error);
  }
};

export const DeletePropertyService = async (propertyId: number) => {
  try {
    const response = await BaseApiService.delete(`/PropertyDetails/DeleteProperty/${String(propertyId)}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as PropertyDeletedResponse;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error deleting property', error);
  }
};

export const AddPropertyService = async (newProperty: AddPropertyInterface) => {
  try {
    const response = await BaseApiService.post('/PropertyDetails/AddProperty', newProperty);
    if(response.data && typeof response.data === 'object') {
      return response.data as PropertyAddedResponse;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.log('Error adding property', error);
  }
};

export const UpdatePropertyService = async (updatedProperty: EditPropertyInterface) => {
  try {
    const response = await BaseApiService.put('/PropertyDetails/UpdateProperty', updatedProperty);
    if(response.data && typeof response.data === 'object') {
      return response.data as PropertyUpdatedResponse;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.log('Error updating property', error);
  }
};

// ADD OTHER SERVICES HERE
