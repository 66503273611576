import React, { useState } from 'react';
import { Box, Heading, Select, Text } from '@chakra-ui/react';
import { TopSellerTable } from './Components/TopSellerTable';

export interface MenuItemType {
  id: number;
  name: string;
  description: string;
  price: number;
  quantity: number;
  image: string;
}

export interface TopSellerInput {
  items: MenuItemType[];
}

const TopSeller: React.FC<TopSellerInput> = ({ items }) => {
  const [sortedItems, setSortedItems] = useState<MenuItemType[]>(
    [...items].sort((a, b) => b.price - a.price)
  );

  const handleSort = (sortBy: string) => {
    const sorted = [...items].sort((a, b) => {
      if (sortBy === 'Price') {
        return b.price - a.price;
      } else if (sortBy === 'Quantity') {
        return b.quantity - a.quantity;
      } else if (sortBy === 'Amount') {
        return b.price * b.quantity - a.price * a.quantity;
      }
      return 0; // Default case if needed
    });

    setSortedItems(sorted);
  };

  return (
    <Box mb={6} py={6} maxWidth={'1144px'}>
      <Box mb={6} p={6} bg="white" borderRadius="lg" boxShadow="lg">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Heading as="h5" size="sm" fontFamily="inherit">Top Seller</Heading>
          <Box display="flex" alignItems="center">
            <Text fontSize="sm" mr={2}>Sort by</Text>
            <Select
              size="sm"
              name="sort-by"
              width="150px"
              fontFamily="inherit"
              onChange={(e) => {
                handleSort(e.target.value);
              }}
            >
              <option value="Price">Price</option>
              <option value="Quantity">Quantity sold</option>
              <option value="Amount">Amount</option>
            </Select>
          </Box>
        </Box>
        <TopSellerTable items={sortedItems} />
      </Box>
    </Box>
  );
};

export default TopSeller;
