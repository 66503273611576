import React  from 'react';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Image, Button, Icon, Text, Flex, Skeleton, HStack, Select, Input } from '@chakra-ui/react';
// import { menuData, MenuItemType } from '../MenuData';
// import { GetAllMenuItemsService, MenuItemType } from '../../../../../services/GetAllMenuItemsService';
import { observer } from 'mobx-react';
import { useBackofficeRootStore } from '../../../../../hooks/useBackofficeRootStore';

const MenuTable = observer(() => {

  const { backofficeMenuItemStore } = useBackofficeRootStore();
  const rootStore = useBackofficeRootStore();

  const handleFieldFilter = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    backofficeMenuItemStore.filterMenuItemsPerField(field, e.target.value);
  };

  const handlePageChange = (pageNumber: number) => {
    backofficeMenuItemStore.setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize: number) => {
    backofficeMenuItemStore.setPageSize(pageSize);
  };

  return (
    <Box overflowX="auto" my={5} borderRadius="lg">
      <Table variant="simple">
        <Thead bg='#f0f4f8'>
          <Tr>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Menu name</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Description</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">ItemID</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Price</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Quantity</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Action</Th>
          </Tr>
          <Tr bg='#DFDFDF'>
            {/* Search filter for each column */}
            <Th>
              <Input
                bg="white"
                type="text"
                placeholder="Menu Name"
                onChange={(e) => {
                  handleFieldFilter(e, 'name');
                }}
              />
            </Th>
            <Th>
              <Input
                bg="white"
                placeholder="Description"
                onChange={(e) => {
                  handleFieldFilter(e, 'description');
                }}
              />
            </Th>
            <Th>
              <Input
                bg="white"
                placeholder="MenuID"
                onChange={(e) => {
                  handleFieldFilter(e, 'id');
                }}
              />
            </Th>
            <Th>
              <Input
                bg="white"
                placeholder="Price"
                onChange={(e) => {
                  handleFieldFilter(e, 'price');
                }}
              />
            </Th>
            <Th>
              <Input
                bg="white"
                placeholder="Quantity"
                onChange={(e) => {
                  handleFieldFilter(e, 'stock');
                }}
              />
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {backofficeMenuItemStore.isLoading ? (
            Array.from({ length: 5 }).map((_, index) => (
              <Tr key={index}>
                <Td colSpan={8} textAlign="center">
                  <Skeleton height={10} />
                </Td>
              </Tr>
            ))
          ) : backofficeMenuItemStore.filteredMenuItems.length ? (
            backofficeMenuItemStore.filteredMenuItems.map((item) => (
              <Tr key={item.id}>
                <Td>
                  <Flex align="center">
                    <Image src={item.image} alt={item.name} boxSize="50px" mr={4} />
                    <Text>{item.name}</Text>
                  </Flex>
                </Td>
                <Td>
                  <Text>{item.description}</Text>
                </Td>
                <Td>{item.itemDisplayNumber.toString().padStart(8, '0')}</Td>
                <Td>
                  <Text>AED {item.price}</Text>
                </Td>
                <Td>
                  <Text>X {item.stock}</Text>
                </Td>
                <Td>
                  <Flex direction="row" gap={2}>
                    <Button size="sm" variant="ghost"
                      onClick={() => {
                        rootStore.setRoute('editmenuitem', { menuItemId: String(item.id) });
                      }}>
                      <Icon as={HiOutlinePencilSquare} />
                    </Button>
                    <Button size="sm" variant="ghost"
                      onClick={() => {
                        backofficeMenuItemStore.deleteMenuItem(item.id);
                      }}>
                      <Icon as={RiDeleteBinLine} />
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={6} textAlign="center">
                No data available.
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {/* Page Number */}
      <HStack justify="end" mt={5} mx={10}>
        <Button
          size="sm"
          border="1px"
          rounded={5}
          borderColor={'#D9D9D9'}
          p={2}
          cursor="pointer"
          onClick={() => {
            handlePageChange(backofficeMenuItemStore.pageNumber - 1);
          }}
          isDisabled={backofficeMenuItemStore.pageNumber === 1}
        >
          &lt;
        </Button>
        <HStack>
          {Array.from({ length: Math.ceil(backofficeMenuItemStore.totalItems / backofficeMenuItemStore.pageSize) }, (_, index) => (
            <Box
              key={index}
              border="1px"
              rounded={5}
              borderColor={backofficeMenuItemStore.pageNumber === index + 1 ? 'black' : '#D9D9D9'}
              px={2}
              py={1}
              cursor="pointer"
              onClick={() => {
                handlePageChange(index + 1);
              }}
              bg={backofficeMenuItemStore.pageNumber === index + 1 ? '#6E8189' : 'white'}
              color={backofficeMenuItemStore.pageNumber === index + 1 ? 'white' : 'black'}
            >
              {index + 1}
            </Box>
          ))}
        </HStack>
        <Button
          size="sm"
          border="1px"
          rounded={5}
          borderColor={'#D9D9D9'}
          p={2}
          cursor="pointer"
          onClick={() => {
            handlePageChange(backofficeMenuItemStore.pageNumber + 1);
          }}
          isDisabled={backofficeMenuItemStore.pageNumber * backofficeMenuItemStore.pageSize >= backofficeMenuItemStore.totalItems}
        >
          &gt;
        </Button>
        {/* Items per page dropdown */}
        <Select
          size="sm"
          maxWidth={'fit-content'}
          value={backofficeMenuItemStore.pageSize}
          onChange={(e) => {
            handlePageSizeChange(Number(e.target.value));
          }}
        >
          {/* options from 5 to 20 in equal intervals of 5 */}
          {[5, 10, 15, 20].map((value) => (
            <option key={value} value={value}>
              {value.toString() + ' / page'}
            </option>
          ))}
        </Select>
      </HStack>
    </Box>
  );
});

export default MenuTable;
