import { InputGroup, InputRightElement, Input, Icon } from '@chakra-ui/react';
import { BiSearch } from 'react-icons/bi';

// eslint-disable-next-line no-unused-vars
function SearchBar({ onChangeHandler }: { onChangeHandler: (value: string) => void }) {
  return (
    <InputGroup maxW="300px" >
      <Input
        type="text"
        placeholder="Search"
        focusBorderColor="teal.500"
        variant="outline"
        onChange={(e) => {
          onChangeHandler(e.target.value);
        }}
      />
      <InputRightElement pointerEvents="none">
        <Icon as={BiSearch} color="gray.400" />
      </InputRightElement>
    </InputGroup>
  );
}

export default SearchBar;
