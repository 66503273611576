import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import styles from '../Dashboard.module.css';

export interface SalesGraphType {
  salesArray: number[];
  graphId: number;
}

export const SalesGraph: React.FC<SalesGraphType> = ({ salesArray, graphId }) => {
  const salesData = salesArray.map((value, index) => ({
    index, // x-axis value
    value, // y-axis value
  }));

  return (
    <Stack direction='column' className={styles.graph}>
      <Text>Sales Details</Text>
      <Stack className={styles.graphPlot}>
        <ResponsiveContainer width={1050} height={300}>
          <AreaChart data={salesData}>
            <defs>
              <linearGradient id={`fillGradient-${String(graphId)}`} x1="0" y1="-1" x2="0" y2="1">
                <stop offset="0.2" stopColor="#4379EE" stopOpacity={0.5} />
                <stop offset="1" stopColor="#FFFFFF" stopOpacity={0.3} />
              </linearGradient>
            </defs>
            <XAxis dataKey="index" axisLine={false} tickLine={false} tick={{ fontSize: 12 }}/>
            <YAxis axisLine={false} tickLine={false} tickFormatter={(value: number) => `${String(value / 1000)}K`} tick={{ fontSize: 12 }} tickMargin={30}/>
            <CartesianGrid strokeDasharray="0" vertical={false} strokeWidth={0.75}/>
            <Tooltip />
            <Area
              type="linear"
              dataKey="value"
              stroke="#4379EE"
              strokeWidth={1.7}
              fillOpacity={1}
              fill={`url(#fillGradient-${String(graphId)})`}
              activeDot={{ scale: 1.5 }}  // Increase active dot size on hover
              dot={{ r: 3.5, fill: '#4379EE', stroke: '#4379EE', strokeWidth: 1 }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Stack>
    </Stack>
  );
};
