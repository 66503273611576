import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
      /* Heading/Heading 1 - 36px */
      @font-face {
        color: var(--Text-Primary, #212121);
        font-family: "Heading";
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 52px;
        src: url('./fonts/AdobeCaslonProSemibold.woff') format('woff');
      }
      /* Body/Body 1 - 16px */
      @font-face {
        color: var(--Text-Primary, #212121);
        font-family: "Body";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        line-height: 24px;
        src: url('./fonts/Montserrat-Regular.woff') format('woff');
      }

      /* Label/Label 1 - 12px */
      @font-face {
        color: var(--Text-Primary, #212121);
        font-family: "Label";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        line-height: 16px;
        src: url('./fonts/Montserrat-Regular.woff') format('woff');
      }
      
      `}
  />
);

export default Fonts;
