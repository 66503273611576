import { VStack, Image, HStack, Box } from '@chakra-ui/react';
import { useState } from 'react';
import { useBackofficeRootStore } from '../../../hooks/useBackofficeRootStore';

const PaymentsCloseCards = () => {
  const [cardType, setCardType] = useState('');
    type CardTypeSelectHandler = (type: string) => void;

    const handleCardTypeSelect: CardTypeSelectHandler = (type) => {
      setCardType(type);
    };

    const { backofficeOrderStore } = useBackofficeRootStore();

    return (
      <VStack spacing={4} mt={4} align="stretch">
        <HStack spacing={4}>
          {
            backofficeOrderStore.paymentCards.map((card) => {
              return (
                <Box
                  as="button"
                  border={cardType === card.name ? '2px solid blue' : '2px solid transparent'}
                  borderRadius="md"
                  onClick={() => {
                    handleCardTypeSelect(card.name);
                  }}
                >
                  <Image src={card.image} alt={card.name} boxSize="50px" filter={cardType === card.name ? 'brightness(1)' : 'grayscale(1) contrast(0.4)'} />
                </Box>
              );
            }
            )
          }
          {/* <Box
            as="button"
            border={cardType === 'visa' ? '2px solid blue' : '2px solid transparent'}
            borderRadius="md"
            onClick={() => {
              handleCardTypeSelect('visa');
            }}
          >
            <Image src="/path/to/visa.png" alt="Visa" boxSize="50px" />
          </Box>
          <Box
            as="button"
            border={cardType === 'mastercard' ? '2px solid blue' : '2px solid transparent'}
            borderRadius="md"
            onClick={() => {
              handleCardTypeSelect('mastercard');
            }}
          >
            <Image src="/path/to/mastercard.png" alt="MasterCard" boxSize="50px" />
          </Box>
          <Box
            as="button"
            border={cardType === 'amex' ? '2px solid blue' : '2px solid transparent'}
            borderRadius="md"
            onClick={() => {
              handleCardTypeSelect('amex');
            }}
          >
            <Image src="/path/to/amex.png" alt="American Express" boxSize="50px" />
          </Box>
          <Box
            as="button"
            border={cardType === 'maestro' ? '2px solid blue' : '2px solid transparent'}
            borderRadius="md"
            onClick={() => {
              handleCardTypeSelect('maestro');
            }}
          >
            <Image src="/path/to/maestro.png" alt="Maestro" boxSize="50px" />
          </Box>
          <Box
            as="button"
            border={cardType === 'jcb' ? '2px solid blue' : '2px solid transparent'}
            borderRadius="md"
            onClick={() => {
              handleCardTypeSelect('jcb');
            }}
          >
            <Image src="/path/to/jcb.png" alt="JCB" boxSize="50px" />
          </Box> */}
        </HStack>
      </VStack>


    );
};
export default PaymentsCloseCards;
