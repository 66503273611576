import { VStack } from '@chakra-ui/react';
import { PaymentOptions } from './components/paymentOptions';
import { PaymentSummary } from './components/paymentSummary';
import { TopBar } from '../../ClientComponents/TopBar/TopBar';
import styles from './Payments.module.css';
import { Signature } from '../../ClientComponents/Signature/Signature';
import { BottomBar } from '../../ClientComponents/BottomBar/BottomBar';
import { useState } from 'react';
import { ModalButton } from '../../ClientComponents/ModalButton/ModalButton';
import { useRootStore } from '../../hooks/useRootStore';
import { observer } from 'mobx-react';

export const Payments = observer(() => {
  const [isSignatureOpen, setIsSignatureOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signatureData, setSignatureData] = useState<string | null>(null);

  const { cartStore, roomStore } = useRootStore();

  const handlePlaceOrder = () => {
    if (roomStore.selectedPayment?.isSignatureRequired) {
      setIsSignatureOpen(true);
    } else {
      cartStore.placeOrder();
      setIsModalOpen(true);
      cartStore.clearCart();
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    }
  };

  return (
    <VStack className={styles.paymentsContainer}>
      <VStack px={4} width="100%" gap={6}>
        <TopBar url="/basket" imageSrc="./images/backbutton.svg" title="Payment" />
        <PaymentSummary />
      </VStack>
      <PaymentOptions />
      <BottomBar
        total={cartStore.cartTotalString}
        totalTitle="Total"
        buttonText="Place Order"
        buttonAction={handlePlaceOrder}
        enabled={roomStore.selectedPayment !== null}
      />
      {isSignatureOpen && (
        <Signature isOpen={isSignatureOpen} onClose={() => {
          setIsSignatureOpen(false);
        }}
        onProceed={() => {
          cartStore.placeOrder(signatureData?.split(',')[1] ?? '');
          setIsModalOpen(true);
          cartStore.clearCart();
          setTimeout(() => {
            window.location.href = '/';
          }, 3000);
        }}
        signatureData={signatureData}
        setSignatureData={setSignatureData}
        />
      )}

      <ModalButton
        modalTitle='Order Placed!'
        modalIconUrl='./images/order-placed-icon.svg'
        modalText='Your order has been placed successfully'
        isOpen={isModalOpen}
        onClose={() => {
          window.location.href = '/';
        }}
      />
    </VStack>
  );
});

export default Payments;
