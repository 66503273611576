import { Divider, VStack } from '@chakra-ui/react';
import { TopBar } from '../../ClientComponents/TopBar/TopBar';
import { BasketSummary } from './components/basketSummary';
import { RoomDetails } from './components/roomDetails';
import { CartItems } from '../../ClientComponents/CartItems/CartItems';
// import styles from './basket.module.css';
import { BottomBar } from '../../ClientComponents/BottomBar/BottomBar';
import { useRootStore } from '../../hooks/useRootStore';
import { OrderNotes } from './components/orderNotes';
import { observer } from 'mobx-react';

export const Basket = observer(() => {

  const basketsContainerStyles = {
    padding: '48px 24px',
    height: '100vh',
    width: '100vw'
  };

  const { cartStore, roomStore } = useRootStore();

  const prooceedToPayment = () => {
    roomStore.verifyRoomDetails().then((res) => {
      if (res) {
        window.location.href = '/payments';
      }
    }).catch((err : unknown) => {
      console.error(err);
    });
  };

  return (
    <VStack style={basketsContainerStyles}>
      <VStack width="100%" gap={6}>
        <TopBar url="/menu" imageSrc="./images/backbutton.svg" title="Cart" />
        <BasketSummary  />
        <Divider width={'100vw'} borderWidth={6} bg={'gray.300'} />
        <CartItems />
      </VStack>
      <Divider width={'100vw'} borderWidth={6} bg={'gray.300'} />
      <RoomDetails />
      <Divider width={'100vw'} borderWidth={6} bg={'gray.300'} />
      <OrderNotes />
      <BottomBar
        total={cartStore.cartTotalString}
        totalTitle="Total"
        buttonText="Proceed to Payment"
        buttonAction={prooceedToPayment}
      />
    </VStack>
  );
});
