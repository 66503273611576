import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { Outlet } from '../ClientPages/Home/components/Menu/OutletData';
import { MenuItems, MenuItemType } from '../ClientPages/MenuPage/components/MenuItemsData';
import { GetOutletsService } from '../services/GetOutletsService';
import { GetMenuItemsService } from '../services/GetMenuItemsService';

export class MenuStore {
  rootStore: RootStore;
  Outlets: Outlet[];
  MenuItems: MenuItems[];
  outletsLoading: boolean;
  menuItemsLoading: boolean;
  selectedOutlet: number;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.Outlets = [];
    this.MenuItems = [];
    this.outletsLoading = false;
    this.menuItemsLoading = false;
    this.selectedOutlet = 1;

    makeAutoObservable(this, {
      rootStore: false,
      Outlets: true,
      MenuItems: true,
      selectedOutlet: true,
      outletsLoading: true,
      menuItemsLoading: true,
      setOutletsLoading: action,
      setMenuItemsLoading: action,
      setOutlets: action,
      setMenuItems: action,
      setSelectedOutlet: action,
      addToCart: action,
      removeFromCart: action,
      fetchOutlets: action,
      fetchMenuItems: action,
    });

    this.fetchOutlets(this.rootStore.propertyStore.propertyId);
  }

  setOutletsLoading = (loading: boolean) => {
    this.outletsLoading = loading;
  };

  setMenuItemsLoading = (loading: boolean) => {
    this.menuItemsLoading = loading;
  };

  setOutlets = (items: Outlet[]) => {
    const signatures: Record<string, string> = {
      'iVBORw0KGgo': 'image/png',
      '/9j/': 'image/jpg',
      'R0lGODlh': 'image/gif',
      'PHN2ZyB': 'image/svg+xml',
      'PD94bW': 'image/svg+xml',
    };

    const detectSignature = (base64String: string) => {
      return Object.keys(signatures).find(signature => base64String.startsWith(signature));
    };

    items.forEach((item) => {
      const base64String = item.outletImage;
      const signature = detectSignature(base64String);
      if (signature && signatures[signature]) {
        item.outletImage = `data:${signatures[signature]};base64,${base64String}`;
      } else {
        console.error('Invalid image signature detected:', base64String);
      }
    });
    this.Outlets = items;
  };

  setMenuItems = (items: MenuItemType[], outletId: number) => {
    const signatures: Record<string, string> = {
      'iVBORw0KGgo': 'image/png',
      '/9j/': 'image/jpg',
      'R0lGODlh': 'image/gif',
      'PHN2ZyB': 'image/svg+xml',
    };

    const detectSignature = (base64String: string) => {
      return Object.keys(signatures).find(signature => base64String.startsWith(signature));
    };

    items.forEach((item) => {
      const base64String = item.image;
      if (base64String) {
        const signature = detectSignature(base64String);
        if (signature && signatures[signature]) {
          item.image = `data:${signatures[signature]};base64,${base64String}`;
        } else {
          console.error('Invalid image signature detected:', base64String);
        }
      }
    });

    this.MenuItems.push({ outletId, menuItems: items });
  };

  setSelectedOutlet = (id: number) => {
    this.selectedOutlet = id;
    this.fetchMenuItems(id);
  };

  get selectedMenu() {
    return this.Outlets.find(outlet => outlet.outletId === this.selectedOutlet);
  }

  getOutletName = (id: number) => {
    const outlet = this.Outlets.find(outlet => outlet.outletId === id);
    return outlet ? outlet.outletName : '';
  };

  addToCart = (id: number, outletId: number) => {
    this.rootStore.cartStore.addToCart(id, outletId);
  };

  removeFromCart = (id: number) => {
    this.rootStore.cartStore.removeFromCart(id);
  };

  fetchOutlets = (propertyId: number) => {
    this.setOutletsLoading(true);
    GetOutletsService(propertyId)
      .then((res) => {
        if (res?.data) {
          this.setOutlets(res.data);
        } else {
          this.setOutlets([]);
        }
      })
      .catch((error: unknown) => {
        console.error('Error fetching outlets', error);
      })
      .finally(() => {
        this.setOutletsLoading(false);
      });
  };

  fetchMenuItems = (outletId: number) => {
    // if already loaded, don't fetch again
    if (this.MenuItems.find(outlet => outlet.outletId === outletId)) {
      return;
    }
    this.setMenuItemsLoading(true);
    GetMenuItemsService(outletId)
      .then((res) => {
        if (res?.data) {
          this.setMenuItems(res.data, outletId);
        } else {
          this.setMenuItems([], outletId);
        }
      })
      .catch((error: unknown) => {
        console.error('Error fetching menu items', error);
      })
      .finally(() => {
        this.setMenuItemsLoading(false);
      });
  };
}
