import React from 'react';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import { RiDeleteBinLine } from 'react-icons/ri';
import { LuScreenShare } from 'react-icons/lu';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button, Icon, Text, Flex, Image, Skeleton, Input, Select, HStack } from '@chakra-ui/react';
import { useBackofficeRootStore } from '../../../../../hooks/useBackofficeRootStore';
import { observer } from 'mobx-react';


const PropertyTable = observer(() => {
  const { backofficePropertyStore } = useBackofficeRootStore();
  const rootStore = useBackofficeRootStore();

  const handlePageChange = (pageNumber: number) => {
    backofficePropertyStore.setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize: number) => {
    backofficePropertyStore.setPageSize(pageSize);
  };

  return (
    <Box overflowX="auto" my={5} borderRadius="lg" width={'100%'}>
      <Table variant="simple">
        <Thead bg='#f0f4f8'>
          <Tr>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Property ID</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Property type</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Name</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Contact Number </Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Logo</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Manager</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Mobile Number</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Action</Th>
          </Tr>
          <Tr bg='#DFDFDF'>
            {/* Search filter for each column */}
            <Th>
              <Input
                bg="white"
                type="text"
                placeholder="Property ID"
                onChange={(e) => {
                  backofficePropertyStore.filterPropertiesPerField('propertyId', e.target.value);
                }}
              />
            </Th>
            <Th>
              <Input
                bg="white"
                placeholder="Property type"
                onChange={(e) => {
                  backofficePropertyStore.filterPropertiesPerField('propertyTypeId', e.target.value);
                }}
              />
            </Th>
            <Th>
              <Input
                bg="white"
                placeholder="Name"
                onChange={(e) => {
                  backofficePropertyStore.filterPropertiesPerField('name', e.target.value);
                }}
              />
            </Th>
            <Th>
              <Input
                bg="white"
                placeholder="Contact Number"
                onChange={(e) => {
                  backofficePropertyStore.filterPropertiesPerField('contactNo', e.target.value);
                }}
              />
            </Th>
            <Th></Th>
            <Th>
              <Input
                bg="white"
                placeholder="Manager"
                onChange={(e) => {
                  backofficePropertyStore.filterPropertiesPerField('itManagerName', e.target.value);
                }}
              />
            </Th>
            <Th>
              <Input
                bg="white"
                placeholder="Mobile Number"
                onChange={(e) => {
                  backofficePropertyStore.filterPropertiesPerField('itManagerContactNo', e.target.value);
                }}
              />
            </Th>
            <Th></Th>
          </Tr>
        </Thead>

        <Tbody>
          {backofficePropertyStore.isLoading ? (
            Array.from({ length: 5 }).map((_, index) => (
              <Tr key={index}>
                <Td colSpan={8} textAlign="center">
                  <Skeleton height={10} />
                </Td>
              </Tr>
            ))
          ) : backofficePropertyStore.filteredProperties.length ? (
            backofficePropertyStore.filteredProperties.map((item) => (
              <Tr key={item.propertyId}>
                <Td>
                  <Text>{item.propertyId}</Text>
                </Td>
                <Td>
                  <Text>{item.propertyTypeId}</Text>
                </Td>
                <Td>
                  <Text>{item.name}</Text>
                </Td>
                <Td>
                  <Text>{item.contactNo}</Text>
                </Td>
                <Td textAlign="center">
                  <Image src={item.propertyLogo} alt="logo" maxW="100px" mx="auto" />
                </Td>
                <Td>
                  <Text>{item.itManagerName}</Text>
                </Td>
                <Td>
                  <Text>{item.itManagerContactNo}</Text>
                </Td>
                <Td p={0}>
                  <Flex direction="row" justify="flex-start" align="left">
                    <Button size="md" variant="ghost"
                      onClick={() => {
                        rootStore.setRoute('editproperty', { propertyId: String(item.propertyId) });
                      }}
                    >
                      <Icon as={HiOutlinePencilSquare} color="#6699FF"/>
                    </Button>
                    <Button size="md" variant="ghost">
                      <Icon as={LuScreenShare} color="#6699FF" />
                    </Button>
                    <Button size="md" variant="ghost"
                      onClick={() => {
                        backofficePropertyStore.deleteProperty(item.propertyId);
                      }}
                    >
                      <Icon as={RiDeleteBinLine} color="#6699FF"/>
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={8} textAlign="center">
                No data available.
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <HStack justify="end" mt={5} mx={10}>
        <Button
          size="sm"
          border="1px"
          rounded={5}
          borderColor={'#D9D9D9'}
          p={2}
          cursor="pointer"
          onClick={() => {
            handlePageChange(backofficePropertyStore.pageNumber - 1);
          }}
          isDisabled={backofficePropertyStore.pageNumber === 1}
        >
          &lt;
        </Button>
        <HStack>
          {Array.from({ length: Math.ceil(backofficePropertyStore.totalItems / backofficePropertyStore.pageSize) }, (_, index) => (
            <Box
              key={index}
              border="1px"
              rounded={5}
              borderColor={backofficePropertyStore.pageNumber === index + 1 ? 'black' : '#D9D9D9'}
              px={2}
              py={1}
              cursor="pointer"
              onClick={() => {
                handlePageChange(index + 1);
              }}
              bg={backofficePropertyStore.pageNumber === index + 1 ? '#6E8189' : 'white'}
              color={backofficePropertyStore.pageNumber === index + 1 ? 'white' : 'black'}
            >
              {index + 1}
            </Box>
          ))}
        </HStack>
        <Button
          size="sm"
          border="1px"
          rounded={5}
          borderColor={'#D9D9D9'}
          p={2}
          cursor="pointer"
          onClick={() => {
            handlePageChange(backofficePropertyStore.pageNumber + 1);
          }}
          isDisabled={backofficePropertyStore.pageNumber * backofficePropertyStore.pageSize >= backofficePropertyStore.totalItems}
        >
          &gt;
        </Button>
        {/* Items per page dropdown */}
        <Select
          size="sm"
          maxWidth={'fit-content'}
          value={backofficePropertyStore.pageSize}
          onChange={(e) => {
            handlePageSizeChange(Number(e.target.value));
          }}
        >
          {/* options from 5 to 20 in equal intervals of 5 */}
          {[5, 10, 15, 20].map((value) => (
            <option key={value} value={value}>
              {value.toString() + ' / page'}
            </option>
          ))}
        </Select>
      </HStack>
    </Box>
  );
});

export default PropertyTable;
