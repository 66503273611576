import { Stack, VStack, Box, Text, Modal, ModalBody, ModalOverlay, ModalFooter, ModalContent } from '@chakra-ui/react';
import SignatureCanvas from 'react-signature-canvas';
import { useWindow } from '../../hooks/useWindow';
import { useRef } from 'react';

interface SignatureProps {
  isOpen: boolean;
  onClose: () => void;
  onProceed: () => void;
  signatureData: string | null;
  setSignatureData: React.Dispatch<React.SetStateAction<string | null>>;
}

export const Signature: React.FC<SignatureProps> = ({ isOpen, onClose, onProceed, signatureData, setSignatureData }) => {
  const { isTablet, isDesktop } = useWindow();

  const sigCanvasRef = useRef<SignatureCanvas>(null);

  const handleEnd = () => {
    if (sigCanvasRef.current) {
      setSignatureData(sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png'));
    }
    console.log(new TextEncoder().encode(signatureData ?? ''));
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent bg={'#F2F5F8'} margin={2}>
        <ModalBody>
          <Stack
            spacing={4}
            align="center"
            width="100%"
            maxW="900px"
            padding="16px"
            borderRadius="14px"
            zIndex={100}
          >
            <Text fontSize="24px" fontWeight="bold" color="#2f3338">
              Please sign below
            </Text>
            <Text fontSize="14px" color="#8a8d91">
              We require your signature for the bill to be charged to your room.
            </Text>
            <Box
              border="1px solid #d3d3d3"
              borderRadius="10px"
              bg="#ffffff"
              overflow="hidden"
              width={isTablet ? 400 : isDesktop ? 600 : 280}
              height={isTablet ? 200 : isDesktop ? 250 : 150}
            >
              <SignatureCanvas
                ref={sigCanvasRef}
                penColor="black"
                canvasProps={{
                  className: 'sigCanvas',
                  width: isTablet ? 400 : isDesktop ? 600 : 280,
                  height: isTablet ? 200 : isDesktop ? 250 : 150,
                }}
                onEnd={handleEnd}
              />
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <VStack gap={4} width="100%" maxW="500px">
            <Box
              as='button'
              height='57px'
              width='80%'
              lineHeight='19.2px'
              border='1px'
              px='8px'
              borderRadius='26.84px'
              paddingRight='45.2px'
              paddingLeft='45.2px'
              fontSize='16px'
              fontWeight='500'
              bg='#022741'
              color='#FFFFFF'
              _focus={{
                boxShadow: '0px 9.42px 37.67px 0px #7A81BE29',
              }}
              onClick={onProceed}
            >
              Proceed
            </Box>
            <Box
              as='button'
              height='57px'
              width='80%'
              lineHeight='19.2px'
              px='8px'
              borderRadius='26.84px'
              paddingRight='45.2px'
              paddingLeft='45.2px'
              fontSize='16px'
              fontWeight='500'
              bg='#FFF6F6'
              color='#D35252'
              onClick={onClose}
              border='1px solid #D35252'
              _focus={{
                boxShadow: '0px 9.42px 37.67px 0px #7A81BE29'
              }}
            >
              Close
            </Box>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
