import { Heading, HStack, IconButton, VStack } from '@chakra-ui/react';
import { MenuItemForm } from './components/MenuItemForm';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useBackofficeRootStore } from '../../hooks/useBackofficeRootStore';
import { MenuItemProps } from '../../services/BackofficeServices/GetAllMenuItemsService';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

export const EditMenuItem = observer(() => {
  // get menuItemId from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const menuItemId = Number(urlParams.get('menuItemId'));

  const { backofficeMenuItemStore } = useBackofficeRootStore();
  const rootStore = useBackofficeRootStore();

  if (!menuItemId) {
    rootStore.setRoute('menumaster');
    return null;
  }
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (menuItemId) {
      backofficeMenuItemStore.fetchCurrentMenuItem(menuItemId).then(() => {
        setIsDataLoaded(true);
      }).catch(() => {
        setIsDataLoaded(true);
      });
    }
  }, [menuItemId, backofficeMenuItemStore]);

  if (!isDataLoaded) {
    return <div>Loading...</div>; // loading state
  }

  const formData: MenuItemProps = backofficeMenuItemStore.currentMenuItem
    ? {
      id: backofficeMenuItemStore.currentMenuItem.id,
      itemDisplayNumber: backofficeMenuItemStore.currentMenuItem.itemDisplayNumber,
      name: backofficeMenuItemStore.currentMenuItem.name,
      description: backofficeMenuItemStore.currentMenuItem.description,
      image: backofficeMenuItemStore.currentMenuItem.image,
      price: backofficeMenuItemStore.currentMenuItem.price,
      outletId: [backofficeMenuItemStore.currentMenuItem.outletId],
      isAvailable: backofficeMenuItemStore.currentMenuItem.isAvailable,
      isExhaustable: backofficeMenuItemStore.currentMenuItem.isExhaustable,
      stock: backofficeMenuItemStore.currentMenuItem.stock,
      maxCartQty: backofficeMenuItemStore.currentMenuItem.maxCartQty,
    }
    : {
      id: 0,
      itemDisplayNumber: 0,
      name: '',
      description: '',
      image: '',
      price: 0,
      outletId: [],
      isAvailable: false,
      isExhaustable: false,
      stock: 0,
      maxCartQty: 0,
    };

  return (
    <VStack alignItems={'flex-start'} alignSelf={'flex-start'} mt={10} ml={10}>
      <HStack>
        <IconButton
          aria-label='Go to Menu Master'
          mb={1}
          color={'#343A40'}
          size={'md'}
          isRound
          variant={'outline'}
          icon={<ArrowBackIcon />}
          borderWidth={'thin'}
          borderColor={'#343A40'}
          mr={2}
          onClick={() => {
            rootStore.setRoute('menumaster');
          }}
        />
        <Heading color={'#343A40'}>Update Menu Item #{backofficeMenuItemStore.currentMenuItem?.itemDisplayNumber.toString().padStart(8, '0')}</Heading>
      </HStack>
      <MenuItemForm formdata={formData} addItem={false}/>
    </VStack>
  );
});
