import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { GetPropertyDetailsService } from '../services/GetPropertyDetailsService';

export class PropertyStore {
  propertyId = 1;
  propertyLogo = '';
  propertyName = '';
  propertyContact = '';
  isLoading = false;

  rootStore: RootStore;

  constructor(rootStore: RootStore, propertyId: number) {
    this.rootStore = rootStore;
    this.propertyId = propertyId;
    makeAutoObservable(this, {
      propertyId: false,
      rootStore: false,
    });

    this.fetchPropertyDetails();
  };

  fetchPropertyDetails = () => {
    this.isLoading = true;
    const signatures: Record<string, string> = {
      'iVBORw0KGgo': 'image/png',
      '/9j/': 'image/jpg',
      'R0lGODlh': 'image/gif',
      'PHN2ZyB': 'image/svg+xml',
    };
    const detectSignature = (base64String: string) => {
      return Object.keys(signatures).find(signature => base64String.startsWith(signature));
    };
    GetPropertyDetailsService(this.propertyId).then((res) => {
      if (res?.data.propertyLogo) {
        const signature = detectSignature(res.data.propertyLogo);
        if (signature) {
          this.propertyLogo = `data:${signatures[signature]};base64,${res.data.propertyLogo}`;
        } else {
          this.propertyLogo = res.data.propertyLogo;
        }
      }
    }).catch((err : unknown) => {
      console.error(err);
    }).finally(() => {
      this.isLoading = false;
    });
  };
}
