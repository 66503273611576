import { Box, Heading, Button } from '@chakra-ui/react';
// import SearchBar from '../../../../BackOfficeComponents/SearchBar';
import PropertyTable from './Components/PropertyTypeTable';
const PropertyType = () => {
  return (
    <Box bg="#eeeeee" minH="100vh" p={4} width={'100%'}>
      <Box mb={6} p={6}>
        <Box mb={6} p={6} bg="white" borderRadius="lg" boxShadow="lg">
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
            <Heading as="h5" size="md" fontFamily="inherit">Property Type</Heading>
            <Button m="5px" bg="#404040" color="white" _hover={{ bg: '#404040' }}
              _active={{ bg: '#404040' }}>Add New Type</Button>
          </Box>
          {/* <SearchBar /> */}
          <PropertyTable/>
        </Box>
      </Box>
    </Box>
  );
};

export default PropertyType;
