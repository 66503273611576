import { Box, Checkbox, CheckboxGroup, Button, FormControl, FormLabel, FormErrorMessage, Input, VStack, HStack, Text, Image, Flex, Switch } from '@chakra-ui/react';
import { useState } from 'react';
import { useBackofficeRootStore } from '../../../hooks/useBackofficeRootStore';
import { OutletForm } from '../../../services/BackofficeServices/BackofficeOutletService';
// import { AddOutletService } from "../../../services/AddOutletService";

/*
    TO-DO:
        needs refactoring
        error message display
        handle submit for empty images
*/


export interface Outlet {
  OutletName: string,
  OutletImage?: string,
  PropertyId: number,
  OutletCode: string,
  IsBookingEnabled: boolean,
  IsActive: boolean
}

export const AddOutletForm = () => {

  const { backofficePropertyStore, backofficeOutletStore } = useBackofficeRootStore();
  const currentPropertyId = backofficePropertyStore.propertyId;

  //form related states
  const [formData, setFormData] = useState<OutletForm>({
    outletName: '',
    outletCode: '',
    propertyId: currentPropertyId,
    categoryId: 0,
    isBookingEnabled: false,
    isActive: false,
    outletImage: '',
    createdBy: 0,
    createdOn: '',
    modifiedBy: 0,
    modifiedOn: ''
  });
  const [image, setImage] = useState<File | null>(null);

  //form fields and related
  // const formFields = ['Outlet name', 'Outlet code', 'Inclusive type'];
  const formFields = ['Outlet name', 'Outlet code'];
  const fallbackOutletPicUrl = '/images/backoffice/fallbackOutlet.png';
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const formFieldMapper = (value: string) => {
    if (value === 'Outlet name') {
      return formData.outletName;
    }
    if (value === 'Outlet code') {
      return formData.outletCode;
    }
    return '';
  };

  //field change handlers
  const handleActivationChange = () => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        isActive: !prevFormData.isActive
      };
    });
  };

  const handleBookingEnablementChange = () => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        isBookingEnabled: !prevFormData.isBookingEnabled
      };
    });
  };

  const handleFormFieldChange = (value: string, e: React.ChangeEvent<HTMLInputElement>) => {
    if (value === 'Outlet name') {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          outletName: e.target.value
        };
      });
    }
    if (value === 'Outlet code') {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          outletCode: e.target.value
        };
      });
    }
  };

  const handleDiscard = () => {
    setFormData({
      outletName: '',
      outletCode: '',
      propertyId: currentPropertyId,
      categoryId: 0,
      isBookingEnabled: false,
      isActive: false,
      outletImage: '',
      createdBy: 0,
      createdOn: '',
      modifiedBy: 0,
      modifiedOn: ''
    });
    setImage(null);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImage(file);
    }
  };

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result.split(',')[1]);
        } else {
          reject(new Error('Failed to convert to base64'));
        }
      };
    });
  };

  //form submission handlers

  const validateForm = () => {
    if ((formData.outletName === '' || formData.outletCode === '')) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (image) {
      convertToBase64(image)
        .then((imageByteArray) => {
          setFormData((prevFormData) => {
            return {
              ...prevFormData,
              outletImage: imageByteArray,
              createdBy: 1,
              modifiedBy: 1,
              createdOn: new Date().toISOString(),
              modifiedOn: new Date().toISOString(),
              categoryId: 1
            };
          });
        })
        .catch(() => {
          console.log('Failed to add image');
        }).finally(() => {
          if (formData.outletImage) {
            backofficeOutletStore.addOutlet(formData);
            handleDiscard();
          }
        });
    };
  };



  return (
    <Box m={2} my={10} w="fit-content" bg={''} color={'#6C757D'}>
      <form onSubmit={(e) => {
        e.preventDefault();
        if (validateForm()) {
          handleSubmit();
        };
      }}>
        <VStack>
          <Flex wrap={{ base: 'wrap', lg: 'nowrap' }} >
            <VStack bg="white" px={3} py={2} borderRadius="md" borderWidth="2px" borderColor="#BDBDBD" w="200px" h="fit-content" mr="40px" mb={10}>
              <Text alignSelf={'flex-start'}>Logo: </Text>

              {image ? (
                <Box mb={1} >
                  <Image
                    src={URL.createObjectURL(image)}
                    alt="Uploaded Preview"
                    w="105px"
                    h="105px"
                    objectFit="cover"
                  />
                </Box>
              ) : (
                <Box mb={1} >
                  <Image
                    src={fallbackOutletPicUrl}
                    alt="Uploaded Preview"
                    w="105px"
                    h="105px"
                    objectFit="cover"
                  />
                </Box>
              )}
              <Input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                display="none"
                id="file-upload"
              />
              <Button width="160px" variant="unstyled" color="white" bgColor={'#3F3F3F'} mb={2} onClick={() => document.getElementById('file-upload')?.click()}>
                Upload Image
              </Button>

              {image && (
                <Button width="160px" variant="unstyled" borderColor="#D1D1D1" borderWidth="1px" color="black" bgColor={'white'} mb={2} onClick={() => {
                  setImage(null);
                }}>
                  Remove
                </Button>
              )}


            </VStack>
            <VStack maxW="920px" mb={10}>
              <Flex alignSelf={'flex-start'} maxW="920px" direction="row" wrap={'wrap'} mb={4} >
                <HStack>
                  <Text>Activate</Text>
                  <Switch
                    isChecked={formData.isActive}
                    onChange={handleActivationChange}
                    size='lg'
                    mr={10}
                    sx={{

                      'span.chakra-switch__track': {
                        backgroundColor: formData.isActive ? '#195D0E' : 'inherit',
                        borderColor: formData.isActive ? '#195D0E' : '#999999',
                        borderWidth: '2px',

                      },

                      'span.chakra-switch__thumb': {
                        backgroundColor: formData.isActive ? '#FFFFFF' : '#79747E',

                      },
                    }}
                  />
                </HStack>
                <HStack>
                  <Text>Enable Booking</Text>
                  <Switch
                    isChecked={formData.isBookingEnabled}
                    onChange={handleBookingEnablementChange}
                    size='lg'
                    sx={{

                      'span.chakra-switch__track': {
                        backgroundColor: formData.isBookingEnabled ? '#195D0E' : 'inherit',
                        borderColor: formData.isBookingEnabled ? '#195D0E' : '#999999',
                        borderWidth: '2px',
                      },

                      'span.chakra-switch__thumb': {
                        backgroundColor: formData.isBookingEnabled ? '#FFFFFF' : '#79747E',
                      },
                    }}
                  />
                </HStack>
              </Flex>
              <Flex alignSelf={'flex-start'} maxW="920px" direction="row" wrap={'wrap'}>
                {
                  formFields.map((formField) => (
                    <FormControl width="200px" mr="30px" mb="30px" isInvalid={false}>
                      <FormLabel >{formField}</FormLabel>
                      <Input
                        bg={'white'}
                        type="text"
                        borderColor={'#BDBDBD'}
                        borderWidth={'thin'}
                        value={formFieldMapper(formField)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleFormFieldChange(formField, e);
                        }}


                      />
                      <FormErrorMessage>{'Invalid Input'}</FormErrorMessage>
                    </FormControl>
                  ))
                }
              </Flex>


              <CheckboxGroup>
                <Text alignSelf={'flex-start'}>Close Days</Text>
                <Flex alignSelf={'flex-start'} maxW="920px" direction="row" wrap={'wrap'}>
                  {
                    days.map((day) => (
                      <Checkbox value={day}
                        _hover={{}}
                        mr={4}
                        mt={2}
                        sx={{
                          '.chakra-checkbox__control[data-checked]': {
                            bg: 'black',
                            borderColor: 'black',
                          },
                          '.chakra-checkbox__control': {
                            boxSize: '2em',
                            borderRadius: 'md',
                            borderColor: '#00000094',
                            bg: '#B4B4B499',
                            borderWidth: '1.5px'
                          },
                        }}
                      >
                        {day}
                      </Checkbox>
                    ))
                  }
                </Flex>
              </CheckboxGroup>

            </VStack>
          </Flex>
          <HStack alignSelf={{ lg: 'flex-end' }} mr={{ lg: '30px' }}>
            <Button borderColor={'#BDBDBD'} borderWidth={'thin'} variant="unstyled" pt={1} w="100px" bg="#DDDDDD" color={'black'} onClick={handleDiscard}>Discard</Button>
            <Button borderColor={'#BDBDBD'} borderWidth={'thin'} variant="unstyled" pt={1} w="100px" bg="#3F3F3F" color="white"
              onClick={() => {
                if (validateForm()) {
                  handleSubmit();
                }
              }}
              isLoading={backofficeOutletStore.isLoading}
            >
              Add
            </Button>
          </HStack>
        </VStack>
      </form>
    </Box>
  );
};
