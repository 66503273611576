import { makeAutoObservable } from 'mobx';
import { BackofficeRootStore } from './BackofficeRootStore';
import { AddPropertyService, UpdatePropertyService, DeletePropertyService, GetAllPropertyDeatilsService } from '../../services/BackofficeServices/BackofficePropertyService';

/*
  TO-DO:
    Needs refactoring
*/


export interface PropertyDetail {
  propertyId: number;
  propertyTypeId: number;
  name?: string;
  contactNo?: string;
  website?: string;
  propertyLogo: string;
  address?: string;
  itManagerName?: string;
  itManagerContactNo?: string;
  pin?: string;
  poBox?: string;
  emailId?: string;
  registrationId?: string;
  isDefault: boolean;
  lastUpdatedDatetime: Date;
  city?: string;
  district?: string;
  state?: string;
  hotelCode?: string;
  mocibranchId?: string;
  userId?: string;
  tenantId: number;
  authType: number;
  propertyCode: string;
  minStayRequired?: number;
  maxBookingDays?: number;
}

export interface AddPropertyInterface {
  propertyTypeId: number;
  name?: string;
  contactNo?: string;
  website?: string;
  propertyLogo: string;
  address?: string;
  itManagerName?: string;
  itManagerContactNo?: string;
  pin?: string;
  poBox?: string;
  emailId?: string;
  registrationId?: string;
  isDefault: boolean;
  city?: string;
  district?: string;
  state?: string;
  hotelCode?: string;
  mocibranchId?: string;
  userId?: string;
  tenantId?: number;
  authType?: number;
  propertyCode?: string;
  minStayRequired?: number;
  maxBookingDays?: number;
}


export interface EditPropertyInterface {
  propertyId: number;
  propertyTypeId: number;
  name?: string;
  contactNo?: string;
  website?: string;
  propertyLogo: string;
  address?: string;
  itManagerName?: string;
  itManagerContactNo?: string;
  pin?: string;
  poBox?: string;
  emailId?: string;
  registrationId?: string;
  isDefault: boolean;
  city?: string;
  district?: string;
  state?: string;
  hotelCode?: string;
  mocibranchId?: string;
  userId?: string;
  tenantId?: number;
  authType?: number;
  propertyCode?: string;
  minStayRequired?: number;
  maxBookingDays?: number;
}

export class BackofficePropertyStore {
  propertyId: number;
  properties : PropertyDetail[] = [];
  filteredProperties : PropertyDetail[] = [];
  isLoading = false;
  rootStore : BackofficeRootStore;
  searchQuery = '';
  pageNumber = 1;
  pageSize = 10;
  totalItems = 0;

  constructor(rootStore: BackofficeRootStore, propertyId: number) {
    this.properties = [];
    this.filteredProperties = [];
    this.rootStore = rootStore;
    this.propertyId = propertyId;
    makeAutoObservable(this);

    this.fetchPropertyDetails();

  };

  setPageSize = (pageSize: number) => {
    this.pageSize = pageSize;
    this.fetchPropertyDetails();
  };

  setPageNumber = (pageNumber: number) => {
    this.pageNumber = pageNumber;
    this.fetchPropertyDetails();
  };

  fetchPropertyDetails = () => {
    this.isLoading = true;
    const signatures: Record<string, string> = {
      'iVBORw0KGgo': 'image/png',
      '/9j/': 'image/jpg',
      'R0lGODlh': 'image/gif',
      'PHN2ZyB': 'image/svg+xml',
    };

    const detectSignature = (base64String: string) => {
      return Object.keys(signatures).find(signature => base64String.startsWith(signature));
    };

    GetAllPropertyDeatilsService(this.pageNumber, this.pageSize).then((response) => {
      if (response?.data && typeof response.data === 'object') {
        response.data.propertyDetails.forEach((property: PropertyDetail) => {
          if (property.propertyLogo) {
            const signature = detectSignature(property.propertyLogo);
            if (signature) {
              property.propertyLogo = `data:${signatures[signature]};base64,${property.propertyLogo}`;
            }
          }
        });
        this.properties = response.data.propertyDetails;
        this.totalItems = response.data.totalCount;
        this.filterProperties();
      } else {
        throw new Error('Invalid response data');
      }
    }).catch((error : unknown) => {
      console.error('Error fetching menu items', error);
    }).finally(() => {
      this.isLoading = false;
    });


  };

  addProperty = (newProperty: AddPropertyInterface) => {
    this.isLoading = true;
    AddPropertyService(newProperty).then((response) => {
      if(response?.data) {
        this.fetchPropertyDetails();
      }
    }).catch((error: unknown) => {
      console.log('Error adding property', error);
    }).finally(() => {
      this.isLoading = false;
    });
  };

  updateProperty = (updatedProperty: EditPropertyInterface) => {
    this.isLoading = true;
    UpdatePropertyService(updatedProperty).then((response) => {
      if(response?.data) {
        this.fetchPropertyDetails();
      }
    }).catch((error: unknown) => {
      console.log('Error updating property', error);
    }).finally(() => {
      this.isLoading = false;
    });
  };


  deleteProperty = (propertyId: number) => {
    this.isLoading = true;
    DeletePropertyService(propertyId).then((response) => {
      if (response?.data) {
        this.fetchPropertyDetails();
      }
    }).catch((error : unknown) => {
      console.error('Error deleting property', error);
    }).finally(() => {
      this.isLoading = false;
    });
  };

  setSearchQuery = (query: string) => {
    this.searchQuery = query;
    this.filterProperties();
  };

  filterPropertiesPerField = (field: string, value: string) => {
    if (!value) {
      this.filteredProperties = this.properties;
      return;
    }
    this.filteredProperties = this.properties.filter((property) => {
      return (property[field as keyof PropertyDetail]?.toString() ?? '').toLowerCase().includes(value.toLowerCase());
    });
  };

  filterProperties = () => {
    if(!this.searchQuery) {
      this.filteredProperties = this.properties;
      return;
    }
    this.filteredProperties = this.properties.filter((property) => {
      return JSON.stringify(property).toLowerCase().includes(this.searchQuery.toLowerCase());
    });
  };
};

