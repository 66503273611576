import React, { useState } from 'react';
import {
  Box,
  Flex,
  Icon,
  Text,
  BoxProps,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { TbDeviceDesktopAnalytics } from 'react-icons/tb';
import { GiShoppingCart } from 'react-icons/gi';
import { LuBookMarked }from 'react-icons/lu';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { TbPointFilled } from 'react-icons/tb';
import { IoLockClosedOutline , IoSettingsOutline } from 'react-icons/io5';
import { IconType } from 'react-icons';
import { ReactNode } from 'react';
import styles from './sideNavigation.module.css';
import { useBackofficeRootStore } from '../../hooks/useBackofficeRootStore';
import { observer } from 'mobx-react';

interface LinkItemProps {
  name: string
  icon: IconType
  route: string
}

interface PageItemProps {
  name: string,
  icon: IconType,
  route: string,
  children: LinkItemProps[]

}

interface SidebarContentProps extends BoxProps {
  activeRoute: string;
  setActiveRoute: (route: string) => void;
}


export const LinkItems: LinkItemProps[] = [
  { name: 'Dashboard', icon: TbDeviceDesktopAnalytics, route: '' },
  { name: 'Order' , icon: GiShoppingCart, route: 'orderlist' },
];

export const PageItems : PageItemProps[] = [
  { name: 'Reports' , icon: LuBookMarked , route: '' , children : [] },
  { name: 'Master' , icon: HiOutlineClipboardList, route: '', children : [
    { name:'Room', icon: TbPointFilled , route: '' },
    { name:'Property type', icon: TbPointFilled , route: 'propertytype' },
    { name:'Property', icon: TbPointFilled , route: 'propertymaster' },
    { name:'Outlet', icon: TbPointFilled , route: 'outletmaster' },
    { name:'Menu', icon: TbPointFilled , route: 'menumaster' },
  ] },
  { name: 'Security', icon:IoLockClosedOutline, route: '', children:[
    { name:'Role', icon: TbPointFilled , route: '' },
    { name:'User', icon: TbPointFilled , route: '' },
  ] },
  { name: 'Settings' , icon: IoSettingsOutline , route: '' , children : [] },
];

export const SimpleSidebar = () => {
  const [activeRoute, setActiveRoute] = useState('');
  console.log(`SimpleSidebar rendered, activeRoute: ${activeRoute}`);
  return (
    <Box className={styles.sidebarWrapper}>
      <SidebarContent activeRoute={activeRoute} setActiveRoute={setActiveRoute}/>
    </Box>
  );
};


const SidebarContent = ({ activeRoute, setActiveRoute,  ...rest }: SidebarContentProps) => {
  console.log('SidebarContent render, activeRoute:', activeRoute);
  return (
    <Box className={styles.sidebarContent} {...rest}>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          childExists={false}
          icon={link.icon}
          route={link.route}
          isActive={activeRoute === link.route}
          setActiveRoute={setActiveRoute}
          onClick={() => {
            setActiveRoute(link.route);
          }}
        >
          {link.name}
        </NavItem>
      ))}

      <div className={styles.pages}>
        <Flex className={styles.sidebarLogo}>
          <Text className={styles.sidebarTitle}>PAGES</Text>
        </Flex>
        {PageItems.map((link) =>
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          (link.children && link.children.length > 0) ? (
            <div className= {styles.accordianItems}>
              <Accordion allowToggle key={link.name}>
                <AccordionItem>
                  <h2>
                    <AccordionButton
                      as={Flex}
                      align="center"
                      p="4"
                      mx="2"
                      borderRadius="lg"
                    >
                      <Icon as={link.icon}  className={styles.navIconWithChildren}/>
                      <Box as="span" flex="1" textAlign="left">
                        {link.name}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>

                    {link.children.map((child) => (
                      <NavItem
                        key={child.name}
                        childExists={true}
                        icon={child.icon}
                        route={child.route}
                        isActive={activeRoute === child.route && child.route !== ''}
                        setActiveRoute={setActiveRoute}
                        onClick={() => {
                          setActiveRoute(child.route);
                        }}
                      >
                        {child.name}
                      </NavItem>
                    ))}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </div>
          ) : (
            <NavItem
              key={link.name}
              childExists={false}
              icon={link.icon}
              route={link.route}
              isActive={activeRoute === link.route && link.route !== ''}
              setActiveRoute={setActiveRoute}
              onClick={() => {
                setActiveRoute(link.route);
              }}>
              {link.name}
            </NavItem>
          )
        )}
      </div>
    </Box>
  );
};

interface NavItemProps {
  childExists: boolean
  icon: IconType
  route: string
  children: ReactNode
  onClick?: () => void;
}

const NavItem = observer((({
  childExists,
  icon,
  route,
  isActive,
  setActiveRoute,
  children,
  ...rest }: NavItemProps& { isActive: boolean; setActiveRoute: (route: string) => void }) => {

  const rootStore = useBackofficeRootStore();
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    console.log(`NavItem clicked: ${route}`);
    rootStore.setRoute(route);
    setActiveRoute(route);
  };
  return (
    <Box as="div" className={`${styles.navItem} ${isActive ? styles.activeNavItem : ''}`} >
      <Box as="a" className={styles.navItem}
        onClick={handleClick}
      >
        <Flex className={styles.navItemContent} {...rest}>
          {(!childExists ? (
            <Icon className={styles.navItemIcon} as={icon} />
          ) : (
            <Icon className={styles.navPoints} as={icon} />
          ))}
          {children}
        </Flex>
      </Box>
    </Box>
  );
}));
