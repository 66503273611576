import { Box, Button, FormControl, FormLabel, FormErrorMessage, Input, VStack, HStack, Text, Image, Flex, Switch } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Select, MultiValue } from 'chakra-react-select';
import { useBackofficeRootStore } from '../../../hooks/useBackofficeRootStore';
import { MenuItemProps } from '../../../services/BackofficeServices/GetAllMenuItemsService';
import { observer } from 'mobx-react';

export interface Outlet {
  OutletName: string,
  OutletImage?: string,
  PropertyId: number,
  OutletCode: string,
  IsBookingEnabled: boolean,
  IsActive: boolean
}

export const MenuItemForm = observer(({ formdata, addItem }: { formdata?: MenuItemProps; addItem: boolean }) => {
  const { backofficeMenuItemStore } = useBackofficeRootStore();
  const [formData, setFormData] = useState<MenuItemProps>(formdata ? formdata : {
    name: '',
    description: '',
    image: '',
    price: 0,
    outletId: [0],
    isAvailable: true,
    isExhaustable: true,
    stock: 0,
    maxCartQty: 0
  });
  const [image, setImage] = useState<File | null>(null);

  interface OptionType {
    label: string;
    value: string;
  }
  const options: OptionType[] = backofficeMenuItemStore.Outlets.map((outlet) => ({
    label: outlet.outletName,
    value: outlet.outletId.toString(),
  }));

  const [selectedOutlets, setSelectedOutlets] = useState<OptionType[]>([]);

  const handleChange = (newValue: MultiValue<OptionType>) => {
    setSelectedOutlets(newValue as OptionType[]);
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        outletId: newValue.map((outlet) => parseInt(outlet.value))
      };
    });
  };

  const fallbackOutletPicUrl = '/images/backoffice/fallbackOutlet.png';

  //field change handlers
  const handleAvailabilityChange = () => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        isAvailable: !prevFormData.isAvailable
      };
    });
  };

  const handleExhaustabilityChange = () => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        isExhaustable: !prevFormData.isExhaustable
      };
    });
  };

  const handleFormFieldChange = (value: string, e: React.ChangeEvent<HTMLInputElement>) => {
    if (value === 'Menu Item ID') {
      // setFormData((prevFormData) => {
      //   return {
      //     ...prevFormData,
      //     name: e.target.value
      //   };
      // });
    }
    if (value === 'Menu Name') {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          name: e.target.value
        };
      });
    }
    if (value === 'Description') {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          description: e.target.value
        };
      });
    }
    if (value === 'Price') {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          price: parseFloat(e.target.value)
        };
      });
    }
    if (value === 'Quantity') {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          quantity: parseInt(e.target.value)
        };
      });
    }
    if (value === 'Stock') {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          stock: parseInt(e.target.value)
        };
      });
    }
    if (value === 'Max Cart Quantity') {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          maxCartQty: parseInt(e.target.value)
        };
      });
    }
  };

  const handleDiscard = () => {
    setFormData({
      name: '',
      description: '',
      image: '',
      price: 0,
      outletId: [0],
      isAvailable: true,
      isExhaustable: true,
      stock: 0,
      maxCartQty: 0,
    });
    setImage(null);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImage(file);
      // set formData image to base64 string
      convertToBase64(file)
        .then((imageByteArray) => {
          setFormData((prevFormData) => {
            return {
              ...prevFormData,
              image: imageByteArray,
            };
          });
        })
        .catch(() => {
          console.log('Failed to add image');
        });
    }
  };

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result.split(',')[1]);
        } else {
          reject(new Error('Failed to convert to base64'));
        }
      };
    });
  };

  //form submission handlers

  const validateForm = () => {
    if ((formData.name === '') || (formData.price === 0) || (formData.stock === 0) || (formData.maxCartQty === 0)) {
      console.log('Invalid form data');
      return false;
    }
    return true;
  };

  const handleSubmit = (addItem: boolean) => {
    if (image) {
      convertToBase64(image)
        .then((imageByteArray) => {
          setFormData((prevFormData) => {
            return {
              ...prevFormData,
              image: imageByteArray,
              createdBy: 1,
              modifiedBy: 1,
              createdOn: new Date(),
              modifiedOn: new Date(),
            };
          });
        })
        .catch(() => {
          console.log('Failed to add image');
        }).finally(() => {
          if (formData.image) {
            if (addItem) {
              // console.log('Adding menu item:', formData);
              backofficeMenuItemStore.addMenuItem(formData);
            } else {
              backofficeMenuItemStore.updateMenuItem(formData);
            }
          }
        });
    };
  };

  const processImage = (base64Image: string) => {
    const signatures: Record<string, string> = {
      'iVBORw0KGgo': 'image/png',
      '/9j/': 'image/jpg',
      'R0lGODlh': 'image/gif',
      'PHN2ZyB': 'image/svg+xml',
      'PD94bW': 'image/svg+xml',
    };

    const detectSignature = (base64String: string) => {
      return Object.keys(signatures).find(signature => base64String.startsWith(signature));
    };

    const signature = detectSignature(base64Image);
    if (signature && signatures[signature]) {
      return `data:${signatures[signature]};base64,${base64Image}`;
    } else {
      console.error('Invalid image signature detected:', base64Image);
    }
  };

  useEffect(() => {
    if (!addItem) {
      setSelectedOutlets(options.filter((option) => formData.outletId.includes(parseInt(option.value))));
    }
  }, [addItem, formData.outletId, options]);

  return (
    <Box m={2} my={10} w="fit-content" bg={''} color={'#6C757D'}>
      <form onSubmit={(e) => {
        e.preventDefault();
        if (validateForm()) {
          handleSubmit(addItem);
        };
      }}>
        <VStack>
          <Flex wrap={{ base: 'wrap', lg: 'nowrap' }} >
            <VStack bg="white" px={3} py={2} borderRadius="md" borderWidth="2px" borderColor="#BDBDBD" w="200px" h="fit-content" mr="40px" mb={10}>
              <Text alignSelf={'flex-start'}>Logo: </Text>

              {formData.image ? (
                <Box mb={1} >
                  <Image
                    src={processImage(formData.image)}
                    alt="Uploaded Preview"
                    w="105px"
                    h="105px"
                    objectFit="cover"
                  />
                </Box>
              ) : (
                <Box mb={1} >
                  <Image
                    src={fallbackOutletPicUrl}
                    alt="Uploaded Preview"
                    w="105px"
                    h="105px"
                    objectFit="cover"
                  />
                </Box>
              )}
              <Input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                display="none"
                id="file-upload"
              />
              <Button width="160px" variant="unstyled" color="white" bgColor={'#3F3F3F'} mb={2} onClick={() => document.getElementById('file-upload')?.click()}>
                Upload Image
              </Button>

              {formData.image && (
                <Button width="160px" variant="unstyled" borderColor="#D1D1D1" borderWidth="1px" color="black" bgColor={'white'} mb={2} onClick={() => {
                  setImage(null);
                  setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      image: '',
                    };
                  });
                }}>
                  Remove
                </Button>
              )}


            </VStack>
            <VStack maxW="920px" mb={10}>
              <Flex alignSelf={'flex-start'} maxW="920px" direction="row" wrap={'wrap'} mb={4} >
                <HStack>
                  <Text>Is Available</Text>
                  <Switch
                    isChecked={formData.isAvailable}
                    onChange={handleAvailabilityChange}
                    size='lg'
                    mr={10}
                    sx={{

                      'span.chakra-switch__track': {
                        backgroundColor: formData.isAvailable ? '#195D0E' : 'inherit',
                        borderColor: formData.isAvailable ? '#195D0E' : '#999999',
                        borderWidth: '2px',

                      },

                      'span.chakra-switch__thumb': {
                        backgroundColor: formData.isAvailable ? '#FFFFFF' : '#79747E',

                      },
                    }}
                  />
                </HStack>
                <HStack>
                  <Text>Is Exhaustable</Text>
                  <Switch
                    isChecked={formData.isExhaustable}
                    onChange={handleExhaustabilityChange}
                    size='lg'
                    sx={{

                      'span.chakra-switch__track': {
                        backgroundColor: formData.isExhaustable ? '#195D0E' : 'inherit',
                        borderColor: formData.isExhaustable ? '#195D0E' : '#999999',
                        borderWidth: '2px',
                      },

                      'span.chakra-switch__thumb': {
                        backgroundColor: formData.isExhaustable ? '#FFFFFF' : '#79747E',
                      },
                    }}
                  />
                </HStack>
              </Flex>
              <VStack px={3} py={2} borderRadius="md" w="fit-content" h="fit-content" mb={10}>
                {/* <FormControl width="200px" mr="30px" mb="30px" isInvalid={false}>
                  <FormLabel> Menu Item ID </FormLabel>
                  <Input
                    bg={'white'}
                    type="text"
                    borderColor={'#BDBDBD'}
                    borderWidth={'thin'}
                    value={formData.id}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleFormFieldChange('Menu Item ID', e);
                    }}
                  />
                  <FormErrorMessage>{'Invalid Input'}</FormErrorMessage>
                </FormControl> */}
                <HStack w={'100%'}>
                  <FormControl width="200px" mr="30px" mb="30px" isInvalid={false}>
                    <FormLabel> Menu Name </FormLabel>
                    <Input
                      bg={'white'}
                      type="text"
                      borderColor={'#BDBDBD'}
                      borderWidth={'thin'}
                      value={formData.name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleFormFieldChange('Menu Name', e);
                      }}
                    />
                    <FormErrorMessage>{'Invalid Input'}</FormErrorMessage>
                  </FormControl>
                  {/* multi select among outlets for using chakra-react-select */}
                  <FormControl mr="30px" mb="30px" isInvalid={false} maxW="400px">
                    <FormLabel> Outlet </FormLabel>
                    <Select<OptionType, true>
                      isMulti
                      options={options}
                      closeMenuOnSelect={false}
                      placeholder="Select Outlets"
                      size="lg"
                      value={selectedOutlets}
                      onChange={handleChange}
                      isDisabled={!addItem}
                    />
                    <FormErrorMessage>{'Invalid Input'}</FormErrorMessage>
                  </FormControl>
                </HStack>
                <HStack w={'100%'}>
                  <FormControl width="200px" mr="30px" mb="30px" isInvalid={false}>
                    <FormLabel> Price </FormLabel>
                    <Input
                      bg={'white'}
                      type="number"
                      borderColor={'#BDBDBD'}
                      borderWidth={'thin'}
                      value={formData.price}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleFormFieldChange('Price', e);
                      }}
                    />
                    <FormErrorMessage>{'Invalid Input'}</FormErrorMessage>
                  </FormControl>
                  <FormControl width="200px" mr="30px" mb="30px" isInvalid={false}>
                    <FormLabel> Stock </FormLabel>
                    <Input
                      bg={'white'}
                      type="number"
                      borderColor={'#BDBDBD'}
                      borderWidth={'thin'}
                      value={formData.stock}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleFormFieldChange('Stock', e);
                      }}
                    />
                    <FormErrorMessage>{'Invalid Input'}</FormErrorMessage>
                  </FormControl>
                  <FormControl width="200px" mr="30px" mb="30px" isInvalid={false}>
                    <FormLabel> Max Cart Quantity </FormLabel>
                    <Input
                      bg={'white'}
                      type="number"
                      borderColor={'#BDBDBD'}
                      borderWidth={'thin'}
                      value={formData.maxCartQty}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleFormFieldChange('Max Cart Quantity', e);
                      }}
                    />
                    <FormErrorMessage>{'Invalid Input'}</FormErrorMessage>
                  </FormControl>
                </HStack>
                <HStack w={'100%'}>
                  <FormControl width="400px" height='100px' mr="30px" mb="30px" isInvalid={false}>
                    <FormLabel> Description </FormLabel>
                    <Input
                      bg={'white'}
                      type="text"
                      borderColor={'#BDBDBD'}
                      borderWidth={'thin'}
                      value={formData.description}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleFormFieldChange('Description', e);
                      }}
                      as="textarea"
                      height={'200px'}
                      maxLength={300}
                    />
                    <FormErrorMessage>{'Invalid Input'}</FormErrorMessage>
                  </FormControl>
                </HStack>
              </VStack>
            </VStack>
          </Flex>
          <HStack alignSelf={{ lg: 'flex-end' }} mr={{ lg: '30px' }}>
            <Button borderColor={'#BDBDBD'} borderWidth={'thin'} variant="unstyled" pt={1} w="100px" bg="#DDDDDD" color={'black'} onClick={handleDiscard}>Discard</Button>
            <Button borderColor={'#BDBDBD'} borderWidth={'thin'} variant="unstyled" pt={1} w="100px" bg="#3F3F3F" color="white"
              onClick={() => {
                if (validateForm()) {
                  handleSubmit(addItem);
                } else {
                  console.log('Invalid form data');
                }
              }}
            >
              {addItem ? 'Add' : 'Update'}
            </Button>
          </HStack>
        </VStack>
      </form>
    </Box>
  );
});
