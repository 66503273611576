import { FormControl, Input, Stack, Text } from '@chakra-ui/react';
import { useWindow } from '../../../hooks/useWindow';
import { useRootStore } from '../../../hooks/useRootStore';
import { observer } from 'mobx-react';

export const OrderNotes = observer(() => {

  const { maxWindowWidth } = useWindow();
  const { cartStore } = useRootStore();

  const handleOrderNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    cartStore.setOrderNotes(e.target.value);
  };

  return (
    <Stack width="100%" mx="auto" spacing={3} maxWidth={maxWindowWidth} paddingBottom={200}>
      <Text fontFamily="Title" fontSize="24px" fontWeight={800}>Order Notes</Text>
      <FormControl isRequired>
        <Input
          as="textarea"
          placeholder='Order Notes'
          value={cartStore.orderNotes}
          onChange={handleOrderNotesChange}
          height={100}
          rows={4}
          maxLength={150}
        />
      </FormControl>
    </Stack>
  );
});


