import { action, makeObservable } from 'mobx';
import { BackofficeRootStore } from './BackofficeRootStore';
import { ClosePaymentForOrderCategory, GetAllOrderDetails, GetOrderDetailsById, GetPaymentStatesByID, GetStructuredOrderItemsById } from '../../services/BackofficeServices/BackofficeOrderService';
import { GetRoomDetails } from '../../services/RoomConfirmationService';
import { OrderSummaryCard } from '../../BackOfficePages/OrderList/OrderDetails';
import { GetPaymentCardsService, GetPaymentMethodsService, PaymentCardInterface, PaymentMethodsInterface } from '../../services/GetPaymentMethodsService';

export interface OrderDetailsInterface {
    orderID: number;
    orderDisplayNumber: number;
    propertyID: number;
    confirmationID: number;
    guestSignature: string;
    orderTotal: number;
    paymentMethodId: number;
    orderStatus: number;
    timestamp: string;
    guestNotes?: string; //optional field
    guestName?: string; //optional field
    confirmationNo?: string; //optional field
    reservationID?: string; //optional field
    roomNumber?: string; //optional field
    roomType?: string; //optional field
}

export interface OrderMenuItem {
    menuItemID: number;
    quantity: number;
    price?: number; //optional field
}

export interface OrderData {
    orderDetails: OrderDetailsInterface;
    orderItems: OrderMenuItem[];
}

export const filters = ['All', 'Pending', 'Completed', 'Order Placed'];

export const filterMapper = (value: number) => {
  if (value === 0) {
    return 'Pending';
  }
  if (value === 1) {
    return 'Completed';
  }
  if (value === 2) {
    return 'Order Placed';
  }

  //needs to be handled later
  return 'All';
};

export class BackofficeOrderStore {
  orders: OrderDetailsInterface[] = [];
  filteredOrders: OrderDetailsInterface[] = [];
  isLoading = false;
  rootStore: BackofficeRootStore;
  currentOrderDetails: OrderDetailsInterface = {
    orderID: 0,
    orderDisplayNumber: 0,
    propertyID: 0,
    confirmationID: 0,
    guestSignature: '',
    orderTotal: 0,
    paymentMethodId: 0,
    orderStatus: 0,
    timestamp: '',
    guestName: 'None',
    confirmationNo: '0',
    reservationID: '0',
    roomNumber: '0',
    roomType: 'Unknown'
  };
  currentOrderItems: OrderSummaryCard[] = [];
  paymentMethods: PaymentMethodsInterface[] = [];
  paymentCards: PaymentCardInterface[] = [];

  constructor(rootStore: BackofficeRootStore) {
    this.orders = [];
    this.rootStore = rootStore;

    this.fetchOrders();
    this.fetchPaymentMethods();
    this.fetchPaymentCards();

    makeObservable(this, {
      orders: true,
      filteredOrders: true,
      isLoading: true,
      rootStore: true,
      currentOrderDetails: true,
      currentOrderItems: true,
      paymentMethods: true,
      paymentCards: true,
      setLoading: action,
      fetchOrders: action,
      filterOrders: action,
      fetchCurrentOrderItemsDetails: action,
      fetchCurrentOrderDetails: action,
      closePaymentForOrderCategory: action,
      fetchPaymentMethods: action,
      fetchPaymentCards: action,
      updatePaymentConfirmation: action,
      fetchPaymentStates: action
    });
  };

  setLoading = (value: boolean) => {
    this.isLoading = value;
  };

  fetchOrders = () => {
    this.setLoading(true);
    GetAllOrderDetails(this.rootStore.backofficePropertyStore.propertyId).then((response) => {
      if (response?.result) {
        this.orders = response.data;
        this.setLoading(true);
        for (const order of this.orders) {
          this.getRoomDetails(order.confirmationID).then(roomDetails => {
            order.roomNumber = roomDetails.roomNumber.toString();
            order.confirmationNo = roomDetails.confirmationNo;
            order.guestName = roomDetails.givenName;
            order.roomType = roomDetails.roomType;
          }).catch((error : unknown) => {
            console.error('Error fetching room details', error);
          });
        }
        this.filterOrders('All');
      }
    }).catch((error : unknown) => {
      console.error('Error fetching order details', error);
    }
    ).finally(() => {
      this.setLoading(false);
    }
    );
  };

  paymentMapper = (value: number) => {
    return this.paymentMethods.find((paymentMethod) => paymentMethod.paymentMethodId === value)?.paymentMethodName;
  };

  filterOrders = (filter: string) => {
    if (filter === 'All') {
      this.filteredOrders = this.orders;
    } else {
      this.filteredOrders = this.orders.filter((order) => filterMapper(order.orderStatus) === filter);
    }
  };

  getRoomDetails = async (confirmationID: number) => {
    try {
      const response = await GetRoomDetails(confirmationID);
      if (response?.result) {
        return {
          roomNumber: response.data.roomNumber,
          givenName: response.data.givenName,
          confirmationNo: response.data.confirmationNo,
          reservationID: response.data.reservationNameId,
          roomType: response.data.roomType
        };
      } else {
        throw new Error('Error fetching room details');
      }
    } catch (error: unknown) {
      console.error('Error fetching room details', error);
      return {
        roomNumber: '0',
        givenName: 'None',
        confirmationNo: '0',
        reservationID: '0',
        roomType: 'Unknown'
      };
    }
  };

  fetchCurrentOrderItemsDetails = async (orderID: number) => {
    this.setLoading(true);
    return GetStructuredOrderItemsById(orderID).then((response) => {
      if (response?.result) {
        this.currentOrderItems = response.data;
      }
    }).catch((error : unknown) => {
      console.error('Error fetching order details', error);
    }).finally(() => {
      this.setLoading(false);
    });
  };

  fetchCurrentOrderDetails = async (orderID: number) => {
    this.setLoading(true);
    const signatures: Record<string, string> = {
      'iVBORw0KGgo': 'image/png',
      '/9j/': 'image/jpg',
      'R0lGODlh': 'image/gif',
      'PHN2ZyB': 'image/svg+xml',
      'PD94bW': 'image/svg+xml',
    };

    const detectSignature = (base64String: string) => {
      try {
        return Object.keys(signatures).find(signature => base64String.startsWith(signature));
      } catch (error) {
        console.log('No signature detected', error);
        return null;
      }
    };

    return GetOrderDetailsById(orderID).then((response) => {
      if (response?.result) {
        this.currentOrderDetails = response.data;
        const signature = detectSignature(this.currentOrderDetails.guestSignature);
        if (signature) {
          this.currentOrderDetails.guestSignature = `data:${signatures[signature]};base64,${this.currentOrderDetails.guestSignature}`;
        }

        this.getRoomDetails(this.currentOrderDetails.confirmationID).then(roomDetails => {
          this.currentOrderDetails.roomNumber = roomDetails.roomNumber.toString();
          this.currentOrderDetails.guestName = roomDetails.givenName;
          this.currentOrderDetails.confirmationNo = roomDetails.confirmationNo;
          this.currentOrderDetails.reservationID = roomDetails.reservationID;
          this.currentOrderDetails.roomType = roomDetails.roomType;
        }).catch((error : unknown) => {
          console.error('Error fetching room details', error);
        });
      }
    }).catch((error : unknown) => {
      console.error('Error fetching order details', error);
    }).finally(() => {
      this.setLoading(false);
    });
  };

  closePaymentForOrderCategory = (categoryID: number, paymentMethodId: number) => {
    this.setLoading(true);
    ClosePaymentForOrderCategory({
      orderId: this.currentOrderDetails.orderID,
      categoryID: categoryID,
      paymentMethodId: paymentMethodId,
      guestSignature: this.currentOrderDetails.guestSignature ? this.currentOrderDetails.guestSignature.split(',')[1] ?? '' : '',
      isPaymentConfirmed: true
    }).then((response) => {
      if (response?.result) {
        console.log('Payment closed successfully');
      }
    }).catch((error : unknown) => {
      console.error('Error closing payment', error);
    }).finally(() => {
      setTimeout(() => {
        this.fetchOrders();
        this.fetchPaymentStates();
      }, 1500);
    });
  };

  fetchPaymentMethods = () => {
    GetPaymentMethodsService(this.rootStore.backofficePropertyStore.propertyId).then(
      (data) => {
        if (data?.result) {
          this.paymentMethods = data.data;
        }
      }
    ).catch((error : unknown) => {
      console.error('Error fetching payment methods', error);
    });
  };

  fetchPaymentCards = () => {
    const signatures: Record<string, string> = {
      'iVBORw0KGgo': 'image/png',
      '/9j/': 'image/jpg',
      'R0lGODlh': 'image/gif',
      'PHN2ZyB': 'image/svg+xml',
      'PD94bW': 'image/svg+xml',
    };
    GetPaymentCardsService().then(
      (result) => {
        if (result) {
          this.paymentCards = result.data.map((paymentCard) => {
            const signature = Object.keys(signatures).find(signature => paymentCard.image.startsWith(signature));
            if (signature) {
              paymentCard.image = `data:${signatures[signature]};base64,${paymentCard.image}`;
            }
            return paymentCard;
          });
        }
      }
    ).catch((error : unknown) => {
      console.error('Error fetching payment cards', error);
    });
  };


  updatePaymentConfirmation = (categoryId: number, isPaymentConfirmed: boolean) => {
    this.currentOrderItems.forEach((orderItem) => {
      if (orderItem.categoryID === categoryId) {
        orderItem.isPaymentConfirmed = isPaymentConfirmed;
      }
    });
  };

  fetchPaymentStates = () => {
    this.isLoading = true;
    GetPaymentStatesByID(this.currentOrderDetails.orderID).then((response) => {
      if (response?.result) {
        const PaymentStates = response.data;
        PaymentStates.forEach((paymentState) => {
          this.updatePaymentConfirmation(paymentState.categoryID, paymentState.isPaymentConfirmed);
        });
      }
    }).catch((error : unknown) => {
      console.error('Error fetching payment states', error);
    }).finally(() => {
      this.isLoading = false;
    });
  };
}
