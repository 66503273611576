import { makeAutoObservable } from 'mobx';
import { AddOutletService, DeleteOutletService, GetAllOutletsByPropertyId, Outlet, OutletForm } from '../../services/BackofficeServices/BackofficeOutletService';
import { BackofficeRootStore } from './BackofficeRootStore';

export class BackofficeOutletStore {

  outlets: Outlet[] = [];
  filteredOutlets: Outlet[] = [];
  isLoading = false;
  searchQuery = '';
  pageNumber = 1;
  pageSize = 10;
  totalItems = 0;
  rootStore: BackofficeRootStore;

  constructor(rootStore: BackofficeRootStore) {
    this.outlets = [];
    this.filteredOutlets = [];
    this.rootStore = rootStore;

    makeAutoObservable(this);

    this.fetchOutletDetails();
  };

  setPageNumber = (pageNumber: number) => {
    this.pageNumber = pageNumber;
    this.fetchOutletDetails();
  };

  setPageSize = (pageSize: number) => {
    this.pageSize = pageSize;
    this.fetchOutletDetails();
  };

  fetchOutletDetails = () => {
    this.isLoading = true;

    const signatures: Record<string, string> = {
      'iVBORw0KGgo': 'image/png',
      '/9j/': 'image/jpg',
      'R0lGODlh': 'image/gif',
      'PHN2ZyB': 'image/svg+xml',
      'PD94bW': 'image/svg+xml',
    };

    const detectSignature = (base64String: string) => {
      return Object.keys(signatures).find(signature => base64String.startsWith(signature));
    };

    GetAllOutletsByPropertyId(this.rootStore.backofficePropertyStore.propertyId, this.pageNumber, this.pageSize).then((response) => {
      if (response?.data && typeof response.data === 'object') {
        response.data.outlets.forEach((outlet: Outlet) => {
          if (outlet.outletImage) {
            const signature = detectSignature(outlet.outletImage);
            if (signature) {
              outlet.outletImage = `data:${signatures[signature]};base64,${outlet.outletImage}`;
            }
          }
        });
        this.outlets = response.data.outlets;
        this.totalItems = response.data.totalItems;
        this.filterOutlets();
      } else {
        throw new Error('Invalid response data');
      }
    }).catch((error : unknown) => {
      console.error('Error fetching menu items', error);
    }
    ).finally(() => {
      this.isLoading = false;
    });
  };

  filterOutletsPerField = (field: string, value: string) => {

    if (!value) {
      this.filteredOutlets = this.outlets;
      return;
    }

    this.filteredOutlets = this.outlets.filter((outlet) => {
      return outlet[field as keyof Outlet].toString().toLowerCase().includes(value.toLowerCase());
    });
  };

  filterOutlets = () => {
    this.filteredOutlets = this.outlets.filter((outlet) => {
      return outlet.outletName.toLowerCase().includes(this.searchQuery.toLowerCase());
    });
  };

  setSearchQuery = (query: string) => {
    this.searchQuery = query;
    this.filterOutlets();
  };

  getOutlets = () => {
    return this.filteredOutlets;
  };

  addOutlet = (outlet: OutletForm) => {
    AddOutletService(outlet).then((response) => {
      if (response?.data && typeof response.data === 'object') {
        this.outlets.push(response.data);
        this.filterOutlets();
      } else {
        throw new Error('Invalid response data');
      }
    }
    ).catch((error : unknown) => {
      console.error('Error adding new outlet', error);
    }).finally(() => {
      this.isLoading = false;
    });
  };

  deleteOutlet = (outletId: number) => {
    DeleteOutletService(outletId).then((response) => {
      if (response?.result) {
        this.outlets = this.outlets.filter(outlet => outlet.outletId !== outletId);
        this.filterOutlets();
      } else {
        throw new Error('Invalid response data');
      }
    }
    ).catch((error : unknown) => {
      console.error('Error deleting outlet', error);
    }).finally(() => {
      this.isLoading = false;
    });
  };

};
