import { CalendarIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, HStack, StackDivider, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

/*
    TO-DO:
        - Cleaner Implementation
*/

export const OrderStatPanel = () => {

  const [totalOrders, setTotalOrders] = useState('NA');
  const [pendingOrders, setPendingOrders] = useState('NA');
  const [completedOrders, setCompletedOrders] = useState('NA');

  useEffect(() => {

    //handle get request here

    //dummy data for now
    setTotalOrders('08');
    setPendingOrders('02');
    setCompletedOrders('06');

  }, []);

  return (
    <HStack divider={<StackDivider borderColor='#00000038' borderWidth={'1px'} />} h='60px' spacing={0} borderRadius="md" borderColor={'#00000038'} mr={4} my={4} maxW="516px" borderWidth="2px">
      <Center w={{ sm: '117px' }} h={'60px'} bg={'#323232'} color={'white'} borderLeftRadius={'md'} p={2}>
        <CalendarIcon mr={{ base: 1, sm: 2 }} boxSize={{ base: 3, sm: 4 }} />
        <Text fontSize={{ base: 'sm', sm: 'md' }} fontWeight={'bold'}>Today</Text>
      </Center>
      <Box w={{ sm: '132px' }} bg={'#FFFFFF'} h={'100%'}>
        <Flex direction={'column'} px={'5px'} py={1} gap={{ sm: 2 }}>
          <Text fontSize={'xs'}>Total Orders</Text>
          <Text fontSize={{ base: 'xs', sm: 'md' }} fontWeight={'bold'}>{totalOrders}</Text>
        </Flex>
      </Box>
      <Box w={{ sm: '133px' }} bg={'#FFFFFF'} h={'100%'}>
        <Flex direction={'column'} px={'5px'} py={1} gap={{ sm: 2 }}>
          <Text fontSize={'xs'}>Pending Orders</Text>
          <Text fontSize={{ base: 'xs', sm: 'md' }} fontWeight={'bold'}>{pendingOrders}</Text>
        </Flex>
      </Box>
      <Box w={{ sm: '134px' }} bg={'#FFFFFF'} h={'100%'} borderRightRadius={'md'}>
        <Flex direction={'column'} px={'5px'} py={1} gap={{ sm: 2 }}>
          <Text fontSize={'xs'}>Completed Orders</Text>
          <Text fontSize={{ base: 'xs', sm: 'md' }} fontWeight={'bold'}>{completedOrders}</Text>
        </Flex>
      </Box>
    </HStack>
  );
};
