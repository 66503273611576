import { BackofficeOutletStore } from './BackofficeOutletStore';
import { BackofficePropertyStore } from './BackofficePropertyStore';
import { BackofficePropertyTypeStore } from './BackofficePropertyTypeStore';
import { BackofficeMenuItemStore } from './BackofficeMenuItemStore';
import { BackofficeOrderStore } from './BackofficeOrderStore';
import { BackofficeMenuItemByOutletStore } from './BackofficeMenuItemByOutletStore';
import { makeAutoObservable } from 'mobx';
export class BackofficeRootStore {

  backofficePropertyStore: BackofficePropertyStore;
  backofficeOrderStore: BackofficeOrderStore;
  backofficeOutletStore: BackofficeOutletStore;
  backofficeMenuItemStore: BackofficeMenuItemStore;
  backofficeMenuItemByOutletStore: BackofficeMenuItemByOutletStore;
  backofficePropertyTypeStore: BackofficePropertyTypeStore;

  currentRoute = '';

  constructor(propertyId : number) {
    this.backofficePropertyStore = new BackofficePropertyStore(this, propertyId);
    this.backofficeOrderStore = new BackofficeOrderStore(this);
    this.backofficeOutletStore = new BackofficeOutletStore(this);
    this.backofficeMenuItemStore = new BackofficeMenuItemStore(this);
    this.backofficeMenuItemByOutletStore = new BackofficeMenuItemByOutletStore(this);
    this.backofficePropertyTypeStore = new BackofficePropertyTypeStore(this);

    makeAutoObservable(this);
  };

  setRoute(route: string, parameters?: Record<string, string>) {
    this.currentRoute = route;
    window.history.pushState({}, '', `/admin/${route}?propertyId=${this.backofficePropertyStore.propertyId.toString()}${parameters ? `&${Object.keys(parameters).map(key => `${key}=${parameters[key]}`).join('&')}` : ''}`);
  }
};
