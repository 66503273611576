import { useContext } from 'react';
import { RootStoreContext } from '../RootStoreContext';

export const useRootStore = () => {
  const context = useContext(RootStoreContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within a RootStoreProvider');
  }
  return context;
};
