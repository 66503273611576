import React from 'react';
import { Text, SimpleGrid, Card, CardHeader, CardBody, Image, HStack } from '@chakra-ui/react';
import { Outlet } from './OutletData';
import styles from './OutletsStyles.module.css';
import { useRootStore } from '../../../../hooks/useRootStore';
import { observer } from 'mobx-react';
import { OutletsLoading } from './OutletsLoading';

export const enum OutletListStructure {
  Grid = 'Grid',
  Scroll = 'Scroll',
}
interface OutletsProps {
  structure?: OutletListStructure;
  MenuList?: Outlet[];
  selectedId?: number;
  home?: boolean;
}

export const Outlets = observer(({ structure = OutletListStructure.Grid, home = false }: OutletsProps) => {

  const { menuStore } = useRootStore();

  return (
    menuStore.outletsLoading ? (
      <OutletsLoading structure={structure} />
    ) :
      <>
        {structure === OutletListStructure.Grid ? (
          <SimpleGrid spacingX={'10px'} spacingY={'14px'} columns={[3, null, 5]} className={styles.menuCardContainer} role="grid">
            {menuStore.Outlets.map((outlet: Outlet) => (
              <Card
                key={outlet.outletId}
                className={`${styles.menuCard} ${styles.menuCardGrid}`}
                borderRadius={8}
                aria-label="menu-card"
                onClick={() => {
                  menuStore.setSelectedOutlet(outlet.outletId);
                  if (home) {
                    window.location.href = '/menu?id=' + String(outlet.outletId);
                  }
                }}
              >
                <CardHeader className={styles.menuCardHeader}>
                  <Image src={outlet.outletImage} alt={outlet.outletName} className={styles.menuCardImage} />
                </CardHeader>
                <CardBody className={styles.menuCardBody}>
                  <Text fontWeight={600} className={styles.menuCardTitle} fontSize={'medium'}>
                    {outlet.outletName}
                  </Text>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>
        ) : (
          <HStack overflowX="auto" spacing="10px" className={styles.scrollContainer} role="list">
            {menuStore.Outlets.map((outlet : Outlet) => (
              <Card
                key={outlet.outletId}
                borderRadius={8}
                className={`${styles.menuCard} ${styles.scrollingMenuCard} ${outlet.outletId === menuStore.selectedOutlet ? styles.selectedMenuCard : ''}`}
                aria-label="menu-card"
                ref={el => {
                  if (outlet.outletId === menuStore.selectedOutlet && el) {
                    el.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                  }
                }}
                onClick={() => {
                  menuStore.setSelectedOutlet(outlet.outletId);
                  window.history.pushState({}, '', '/menu?id=' + String(outlet.outletId));
                }}
              >
                <CardHeader className={styles.menuCardHeader}>
                  <Image src={outlet.outletImage} alt={outlet.outletName} className={styles.menuCardImage} />
                </CardHeader>
                <CardBody className={styles.menuCardBody}>
                  <Text fontWeight={600} className={styles.menuCardTitle} fontSize="medium" noOfLines={2}>
                    {outlet.outletName}
                  </Text>
                </CardBody>
              </Card>
            ))}
          </HStack>
        )}
      </>
  );
});
