import { makeAutoObservable } from 'mobx';
import { DeletePropertyTypeService, GetAllPropertyTypeService } from '../../services/BackofficeServices/BackofficePropertyTypeService';
import { BackofficeRootStore } from './BackofficeRootStore';

export interface PropertyType {
  propertyTypeId: number;
  propertyType1: string;
  isDeleted: boolean;
  isActive: boolean;
  lastUpdatedDatetime: string;
}

export class BackofficePropertyTypeStore {
  rootStore: BackofficeRootStore;
  propertyTypes: PropertyType[] = [];
  constructor(rootStore: BackofficeRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    this.getPropertyTypes();
  }

  getPropertyTypes = () => {
    try {
      GetAllPropertyTypeService().then((response) => {
        if (response?.result) {
          console.log(response.data);
          this.propertyTypes = response.data;
        }
      }).catch((error : unknown) => {
        console.error(error);
      });
    } catch (e) {
      console.error(e);
    }
  };

  deletePropertyType = (propertyTypeId: number) => {
    try {
      DeletePropertyTypeService(propertyTypeId).then((response) => {
        if (response?.data) {
          this.getPropertyTypes();
        }
      }).catch((error : unknown) => {
        console.error(error);
      });
    } catch (e) {
      console.error(e);
    }
  };
}
