import React from 'react';
import { AddOutlet } from '../OutletMaster/AddOutlet';
import { OrderList } from '../OrderList/OrderList';
import OutletDetails from './Menus/OutletMaster/OutletDetails';
import PropertyType from './Menus/PropertyType/PropertyTypeDetails';
import { ParticularOrderDetails } from '../OrderList/OrderDetails';
import { AddMenuItem } from '../MenuMaster/AddMenuItem';
import { EditMenuItem } from '../MenuMaster/EditMenuItem';
import MenuDetailsByOutletId from './Menus/MenuDetailsByOutletId/MenuDetailsByOutletId';
import MenuDetails from './Menus/menuDetails/MenuDetails';
import { AddProperty } from '../PropertyMaster/AddProperty';
import { EditProperty } from '../PropertyMaster/EditProperty';
import PropertyDetails from './Menus/PropertyMaster/PropertyDetails';
import { Dashboard } from './Menus/Dashboard/Dashboard';
import { observer } from 'mobx-react';
import { useBackofficeRootStore } from '../../hooks/useBackofficeRootStore';

const ComponentsForRoute: Record<string, React.JSX.Element> = {
  'propertymaster': <PropertyDetails />,
  'menumaster': <MenuDetails />,
  'addmenuitem': <AddMenuItem />,
  'editmenuitem': <EditMenuItem />,
  'propertytype': <PropertyType />,
  '': <Dashboard />,
  'outletmaster': <OutletDetails />,
  'addoutlet': <AddOutlet />,
  'orderlist': <OrderList />,
  'orderdetails': <ParticularOrderDetails />,
  'editproperty': <EditProperty />,
  'addproperty': <AddProperty />,
  'menubyoutlet': <MenuDetailsByOutletId />
};

export const ComponentToRender = observer(() => {
  const rootStore = useBackofficeRootStore();

  return ComponentsForRoute[rootStore.currentRoute.split('?')[0]];
});
