import React from 'react';
import { observer } from 'mobx-react';
import { VStack } from '@chakra-ui/react';
import { OutletListStructure, Outlets } from '../Home/components/Menu/Outlets';
import { MenuItemsList } from './components/MenuItemsList';
import { useRootStore } from '../../hooks/useRootStore';

import styles from './menu.module.css';
import { TopBar } from '../../ClientComponents/TopBar/TopBar';
import { BottomBar } from '../../ClientComponents/BottomBar/BottomBar';
import { MenuItemsLoading } from './components/MenuItemsLoading';

export const MenuPage = observer(() => {
  const { menuStore, cartStore } = useRootStore();

  const urlParams = new URLSearchParams(window.location.search);
  const OutletId = urlParams.get('id') ?? 1;
  menuStore.setSelectedOutlet(Number(OutletId));
  window.history.pushState({}, '', `?id=${String(OutletId)}`);

  return (
    <VStack align={'center'} className={styles.menuContainer}>

      <TopBar url='/' imageSrc='./images/home.svg' title={menuStore.Outlets.find(outlet => outlet.outletId === menuStore.selectedOutlet)?.outletName ?? ''} />

      <VStack align={'center'} className={styles.menuCarousel}>
        <Outlets
          structure={OutletListStructure.Scroll}
          MenuList={menuStore.Outlets}
          selectedId={menuStore.selectedOutlet}
        />
      </VStack>
      {menuStore.menuItemsLoading ? (
        <MenuItemsLoading />
      ) : (
        <MenuItemsList MenuItems={menuStore.MenuItems.find(outlet => outlet.outletId === menuStore.selectedOutlet)?.menuItems ?? []} />
      )}
      <BottomBar
        totalTitle="Total"
        total={cartStore.cartTotalString}
        buttonText={cartStore.cartItems.length === 0 ? 'Add items to cart' : 'View Cart'}
        buttonAction={() => {
          if (cartStore.cartItems.length > 0) {
            window.location.href = '/basket';
          }
        }}
        enabled={cartStore.cartItems.length > 0}
      />
    </VStack>
  );
});
