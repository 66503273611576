import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import styles from '../Dashboard.module.css';


export interface StatBoxType {
  title: string;
  itemValue: number;
  imgSrc: string;
}

export const StatBox: React.FC<StatBoxType> = ({ title, itemValue, imgSrc }) => {
  return (
    <>
      <Stack direction='column' className={styles.statBox}>
        <Stack direction='row' className={styles.statTop}>
          <Stack direction='column' className={styles.statTitleValue}>
            <Stack className={styles.statTitle}>
              <Text>{title}</Text>
            </Stack>
            <Stack className={styles.statValue}>
              <Text>{itemValue}</Text>
            </Stack>
          </Stack>

          <Stack className={styles.statIcon}>
            <img src={imgSrc} alt='StatBox Icon'/>
          </Stack>
        </Stack>

      </Stack>
    </>
  );
};


