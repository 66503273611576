import { Box, Heading, Button, HStack } from '@chakra-ui/react';
import MenuTable from './Components/MenuTable';
import SearchBar from '../../../../BackOfficeComponents/SearchBar';
import { observer } from 'mobx-react';
import { useBackofficeRootStore } from '../../../../hooks/useBackofficeRootStore';
import { OutletFilter } from './Components/OutletFilter';

const MenuDetails = observer(() => {

  const rootStore = useBackofficeRootStore();

  return (
    <Box bg="#eeeeee" w="100%" p={4}>
      <Box mb={6} p={6} bg="white" borderRadius="lg" boxShadow="lg">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Heading as="h5" size="md" fontFamily="inherit">Menu Master</Heading>
          <Button m="5px" bg="#404040" color="white" _hover={{ bg: '#404040' }}
            _active={{ bg: '#404040' }}
            onClick={() => {
              rootStore.setRoute('addmenuitem');
            }}> Add new </Button>
        </Box>
        <HStack justify={'space-between'} w="100%">
          <SearchBar onChangeHandler={rootStore.backofficeMenuItemStore.setSearchQuery}/>
          <OutletFilter/>
        </HStack>
        <MenuTable/>
      </Box>
    </Box>
  );
});

export default MenuDetails;
