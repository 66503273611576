import { Image, Spinner, Stack } from '@chakra-ui/react';
import React from 'react';
import styles from '../home.module.css';
import { observer } from 'mobx-react';
import { useRootStore } from '../../../hooks/useRootStore';

export const HomeHeader = observer(() => {
  const { propertyStore } = useRootStore();
  return (
    <Stack spacing={'24px'} direction={'column'} align={'center'} justify={'center'} bgColor={'primary.600'} className={styles.homeHeader}>
      {
        propertyStore.isLoading ? <Spinner size="xl" color="white" />
          :
          <Image src={propertyStore.propertyLogo} alt={'home-header'} />
      }
    </Stack>
  );
});
