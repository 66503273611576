import { makeAutoObservable } from 'mobx';
import { BackofficeRootStore } from './BackofficeRootStore';
import { MenuItemProps, AddMenuItemService, UpdateMenuItemService, DeleteMenuItemService, GetAllMenuItemsService, GetMenuItemById, GetMenuItemsByOutletId } from '../../services/BackofficeServices/GetAllMenuItemsService';
import { GetAllOutletsByPropertyId, Outlet } from '../../services/BackofficeServices/BackofficeOutletService';

export interface MenuItemType {
    id: number;
    itemDisplayNumber: number;
    name: string;
    description: string;
    image: string;
    price: number;
    outletId: number;
    isAvailable: boolean;
    isExhaustable: boolean;
    stock: number;
    maxCartQty: number;
    createdOn: Date;
    createdBy: number;
    modifiedOn: Date;
    modifiedBy: number;
  }

export class BackofficeMenuItemStore {
  menuItems: MenuItemType[] = [];
  filteredMenuItems: MenuItemType[] = [];
  currentMenuItem: MenuItemType | null = null;
  isLoading = false;
  rootStore: BackofficeRootStore;
  searchQuery = '';
  selectedOutletId: number | null;
  Outlets: Outlet[] = [];
  pageNumber = 1;
  pageSize = 10;
  totalItems = 0;

  constructor(rootStore: BackofficeRootStore) {
    this.menuItems = [];
    this.filteredMenuItems = [];
    this.rootStore = rootStore;
    this.selectedOutletId = null;
    this.currentMenuItem = null;
    makeAutoObservable(this);

    this.fetchMenuItems();
    this.fetchOutlets();
  }

  setSelectedOutletId = (outletId: number | null) => {
    this.selectedOutletId = outletId;
    this.fetchMenuItems();
  };

  fetchCurrentMenuItem = async (menuId: number): Promise<void> => {
    this.isLoading = true;
    try {
      const response = await GetMenuItemById(menuId);
      if (response?.data) {
        this.currentMenuItem = response.data;
      }
    } catch (error: unknown) {
      console.error('Error fetching menu item', error);
    } finally {
      this.isLoading = false;
    }
  };

  setPageSize = (pageSize: number) => {
    this.pageSize = pageSize;
    this.fetchMenuItems();
  };

  setPageNumber = (pageNumber: number) => {
    this.pageNumber = pageNumber;
    this.fetchMenuItems();
  };

  incrementPageNumber = () => {
    if (this.pageNumber * this.pageSize >= this.totalItems) {
      return;
    }
    this.pageNumber++;
    this.fetchMenuItems();
  };

  decrementPageNumber = () => {
    if (this.pageNumber === 1) {
      return;
    }
    this.pageNumber--;
    this.fetchMenuItems();
  };

  fetchMenuItems = () => {
    this.isLoading = true;

    const signatures: Record<string, string> = {
      'iVBORw0KGgo': 'image/png',
      '/9j/': 'image/jpg',
      'R0lGODlh': 'image/gif',
      'PHN2ZyB': 'image/svg+xml',
      'PD94bW': 'image/svg+xml',
    };

    const detectSignature = (base64String: string) => {
      return Object.keys(signatures).find(signature => base64String.startsWith(signature));
    };

    if (this.selectedOutletId) {
      GetMenuItemsByOutletId(this.selectedOutletId).then((response) => {
        if (response?.data && typeof response.data === 'object') {
          response.data.forEach((menu: MenuItemType) => {
            if (menu.image) {
              const signature = detectSignature(menu.image);
              if (signature) {
                menu.image = `data:${signatures[signature]};base64,${menu.image}`;
              }
            }
          });
          this.menuItems = response.data;
          // this.totalItems = response.data.totalItems;
          this.filterMenuItems();
        } else {
          throw new Error('Invalid response data');
        }
      }).catch((error: unknown) => {
        console.error('Error fetching menu items', error);
      }).finally(() => {
        this.isLoading = false;
      });
    } else {
      GetAllMenuItemsService(this.pageNumber, this.pageSize).then((response) => {
        if (response?.data && typeof response.data === 'object') {
          response.data.menuItems.forEach((menu: MenuItemType) => {
            if (menu.image) {
              const signature = detectSignature(menu.image);
              if (signature) {
                menu.image = `data:${signatures[signature]};base64,${menu.image}`;
              }
            }
          });
          this.menuItems = response.data.menuItems;
          this.totalItems = response.data.totalItems;
          this.filterMenuItems();
        } else {
          throw new Error('Invalid response data');
        }
      }).catch((error: unknown) => {
        console.error('Error fetching menu items', error);
      }).finally(() => {
        this.isLoading = false;
      });
    };
  };

  deleteMenuItem = (menuId: number) => {
    this.isLoading = true;
    DeleteMenuItemService(menuId).then((response) => {
      if (response?.result) {
        this.menuItems = this.menuItems.filter(menuItem => menuItem.id !== menuId);
        this.fetchMenuItems();
      }
    }).catch((error: unknown) => {
      console.error('Error deleting menu item', error);
    }).finally(() => {
      this.isLoading = false;
    });
  };

  setSearchQuery = (query: string) => {
    this.searchQuery = query;
    this.filterMenuItems();
  };

  filterMenuItemsPerField = (field: string, query: string) => {
    if (!query) {
      this.filteredMenuItems = this.menuItems;
      return;
    }

    this.filteredMenuItems = this.menuItems.filter((item) => {
      return JSON.stringify(item[field as keyof MenuItemType]).toLowerCase().includes(query.toLowerCase());
    });
  };

  filterMenuItems = () => {
    if (!this.searchQuery) {
      this.filteredMenuItems = this.menuItems;
      return;
    }
    this.filteredMenuItems = this.menuItems.filter((item) => {
      return JSON.stringify(item).toLowerCase().includes(this.searchQuery.toLowerCase());
    });
  };

  fetchOutlets = () => {
    this.isLoading = true;
    const signatures: Record<string, string> = {
      'iVBORw0KGgo': 'image/png',
      '/9j/': 'image/jpg',
      'R0lGODlh': 'image/gif',
      'PHN2ZyB': 'image/svg+xml',
      'PD94bW': 'image/svg+xml',
    };

    const detectSignature = (base64String: string) => {
      return Object.keys(signatures).find(signature => base64String.startsWith(signature));
    };

    GetAllOutletsByPropertyId(this.rootStore.backofficePropertyStore.propertyId, this.pageNumber, this.pageSize).then((response) => {
      if (response?.data && typeof response.data === 'object') {
        response.data.outlets.forEach((outlet: Outlet) => {
          if (outlet.outletImage) {
            const signature = detectSignature(outlet.outletImage);
            if (signature) {
              outlet.outletImage = `data:${signatures[signature]};base64,${outlet.outletImage}`;
            }
          }
        });
        this.Outlets = response.data.outlets;
      } else {
        throw new Error('Invalid response data');
      }
    }).catch((error : unknown) => {
      console.error('Error fetching menu items', error);
    }
    ).finally(() => {
      this.isLoading = false;
    });
  };

  addMenuItem = (menuItem: MenuItemProps) => {
    AddMenuItemService(menuItem).then((response) => {
      if (response) {
        this.fetchMenuItems();
      }
    }).catch((error: unknown) => {
      console.error('Error adding menu item', error);
    }).finally(() => {
      this.isLoading = false;
      this.rootStore.setRoute('menumaster');
    });
  };

  updateMenuItem = (menuItem: MenuItemProps) => {
    UpdateMenuItemService(menuItem).then((response) => {
      if (response) {
        this.fetchMenuItems();
      }
    }).catch((error: unknown) => {
      console.error('Error updating menu item', error);
    }).finally(() => {
      this.isLoading = false;
      this.rootStore.setRoute('menumaster');
    });
  };
}
