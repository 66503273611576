import { Box, Heading, Button } from '@chakra-ui/react';
import SearchBar from '../../../../BackOfficeComponents/SearchBar';
import PropertyTable from './Components/PropertyTable';
import { useBackofficeRootStore } from '../../../../hooks/useBackofficeRootStore';
import { observer } from 'mobx-react';

const PropertyDetails = observer(() => {

  const { backofficePropertyStore } = useBackofficeRootStore();
  const rootStore = useBackofficeRootStore();

  return (
    <Box bg="#eeeeee" minH="100vh" w="100%" p={4}>
      <Box  p={6} bg="white" borderRadius="lg" boxShadow="lg">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Heading as="h5" size="md" fontFamily="inherit">Property Master</Heading>
          <Button m="5px" bg="#404040" color="white" _hover={{ bg: '#404040' }}
            _active={{ bg: '#404040' }}
            onClick={() => {
              rootStore.setRoute('addproperty');
            }}
          >Add Property</Button>
        </Box>
        <SearchBar onChangeHandler={backofficePropertyStore.setSearchQuery}/>
        <PropertyTable/>
      </Box>
    </Box>
  );
});

export default PropertyDetails;




