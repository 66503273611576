import { Heading, VStack } from '@chakra-ui/react';
import { OrderStatPanel } from '../../BackOfficeComponents/OrderStatPanel/OrderStatPanel';
import { OrderDetails } from '../../BackOfficeComponents/OrderDetails/OrderDetails';
// import { ParticularOrderDetails } from './OrderDetails';


export const OrderList = () => {
  return (
    <VStack alignItems={'flex-start'} alignSelf={'flex-start'} ml={10} mt={8}>
      <Heading > Orders </Heading>
      <OrderStatPanel />
      <OrderDetails />
      {/* <ParticularOrderDetails /> */}
    </VStack>
  );
};
