import { Text, HStack, IconButton } from '@chakra-ui/react';
import { FiBell, FiLogOut } from 'react-icons/fi';

export const NavBar = () => {

  return (
    <HStack backgroundColor="#243B45" color="white" height="50px" paddingX={4} width="100%" justify="space-between">
      <Text fontSize="lg" fontWeight="bold">
        VICAS Retail
      </Text>
      <HStack spacing={3}>
        <IconButton
          aria-label="Notifications"
          icon={<FiBell />}
          variant="ghost"
          color="white"
          _hover={{ color: 'gray.400' }}
        />
        <IconButton
          aria-label="Logout"
          icon={<FiLogOut />}
          variant="ghost"
          color="white"
          _hover={{ color: 'gray.400' }}
        />
      </HStack>
    </HStack>
  );
};
