import { makeAutoObservable } from 'mobx';
import { BackofficeRootStore } from './BackofficeRootStore';
import { DeleteMenuItemByOutletService, GetAllMenuItemsByOutletService } from '../../services/BackofficeServices/BackofficeGetMenuByOutletService';

export interface MenuItemType {
  id: number;
  name: string;
  description: string;
  image: string;
  price: number;
  quantity: number;
  outletId: number;
  isAvailable: boolean;
  isExhaustable: boolean;
  stock: number;
  maxCartQty: number;
  createdOn: Date;
  createdBy: number;
  modifiedOn: Date;
  modifiedBy: number;
}

export class BackofficeMenuItemByOutletStore {
  menuItems: MenuItemType[] = [];
  filteredMenuItems: MenuItemType[] = [];
  isLoading = false;
  rootStore: BackofficeRootStore;
  searchQuery = '';
  outletId: number;


  constructor(rootStore: BackofficeRootStore) {
    this.menuItems = [];
    this.filteredMenuItems = [];
    this.rootStore = rootStore;
    makeAutoObservable(this);
    this.outletId = 1;
    this.fetchMenuItems(this.outletId);
  }

  fetchMenuItems = (outletId: number) => {
    this.isLoading = true;
    this.outletId = outletId;
    const signatures: Record<string, string> = {
      'iVBORw0KGgo': 'image/png',
      '/9j/': 'image/jpg',
      'R0lGODlh': 'image/gif',
      'PHN2ZyB': 'image/svg+xml',
    };

    const detectSignature = (base64String: string) => {
      return Object.keys(signatures).find(signature => base64String.startsWith(signature));
    };


    GetAllMenuItemsByOutletService(outletId).then((response) => {
      if (response?.data && typeof response.data === 'object') {
        response.data.forEach((menu: MenuItemType) => {
          if (menu.image) {
            const signature = detectSignature(menu.image);
            if (signature) {
              menu.image = `data:${signatures[signature]};base64,${menu.image}`;
            }
          }
        });
        this.menuItems = response.data;
        this.filterMenuItems();
      } else {
        throw new Error('Invalid response data');
      }
    }).catch((error: unknown) => {
      console.error('Error fetching menu items', error);
    }).finally(() => {
      this.isLoading = false;
    });
  };


  deleteMenuItem = (menuId: number) => {
    this.isLoading = true;
    DeleteMenuItemByOutletService(menuId).then((response) => {
      if (response?.data) {
        this.fetchMenuItems(this.outletId);
      }
    }).catch((error: unknown) => {
      console.error('Error deleting menu item', error);
    }).finally(() => {
      this.isLoading = false;
    });
  };

  setSearchQuery = (query: string) => {
    this.searchQuery = query;
    this.filterMenuItems();
  };

  filterMenuItems = () => {
    if (!this.searchQuery) {
      this.filteredMenuItems = this.menuItems;
      return;
    }
    this.filteredMenuItems = this.menuItems.filter((item) => {
      return JSON.stringify(item).toLowerCase().includes(this.searchQuery.toLowerCase());
    });
  };
}
