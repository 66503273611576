import { Outlet } from '../ClientPages/Home/components/Menu/OutletData';
import BaseApiService from './BaseApiService';

export interface OutletsInterface {
  result: boolean;
  code: string;
  data: Outlet[];
  message: string;
}

export const GetOutletsService = async (propertyId: number) => {

  // const [OutletsData, setOutletsData] = useState<Outlet[]>([]);

  try {
    const response = await BaseApiService.get(`/Outlets/GetOutletsByPropertyId?propertyId=${propertyId.toString()}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as OutletsInterface;
      // setOutletsData(response.data);
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error fetching outlets', error);
  }
};
