import { Stack, VStack, Text, Radio, RadioGroup } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useWindow } from '../../../hooks/useWindow';
import { useRootStore } from '../../../hooks/useRootStore';
import { observer } from 'mobx-react';
import { PaymentOptionsLoading } from './paymentOptionsLoading';

export const PaymentOptions = observer(() => {
  const handleValueChange = (value: string) => {
    roomStore.setSelectedPayment(roomStore.paymentMethods.find((method) => method.paymentMethodName === value) ?? null);
  };

  const { roomStore } = useRootStore();
  const { maxWindowWidth } = useWindow();

  useEffect(() => {
    roomStore.fetchPaymentMethods();
  }, [roomStore]);

  return (
    <Stack spacing={4} bg="#f2f5f8" paddingY={3} maxWidth={maxWindowWidth} width={'100%'} >
      <VStack bg="#ffffff" spacing={4} width="100%" align="start">
        <Text fontFamily="Title" fontSize="24px" fontWeight={800} paddingTop={4} px={4}>
          Payment Options
        </Text>
        {
          roomStore.paymentMethodsLoading ? (
            <PaymentOptionsLoading />
          ) : (
            <RadioGroup value={roomStore.selectedPayment?.paymentMethodName} onChange={handleValueChange} width={'100%'}>
              {roomStore.paymentMethods.map((option) => (
                <Stack
                  key={option.paymentMethodId}
                  direction="row"
                  alignItems="center"
                  spacing={4}
                  p={4}
                  borderRadius="md"
                  bg={'transparent'}
                  paddingX={'24px'}
                  width={'100%'}
                  _selected={{ bg: '#EDF8FF' }}
                >
                  <Radio value={option.paymentMethodName} width={'100%'}>
                    <Stack>
                      <Text ml={2}>{option.paymentMethodName}</Text>
                      {roomStore.selectedPayment?.paymentMethodName === option.paymentMethodName && (
                        <Text fontSize="sm" color="gray.500" ml={2}>
                          {option.description}
                        </Text>
                      )}
                    </Stack>
                  </Radio>
                </Stack>
              ))}
            </RadioGroup>
          )
        }
      </VStack>
    </Stack>
  );
});
