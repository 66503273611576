import { Heading, HStack, IconButton, VStack } from '@chakra-ui/react';
import { EditPropertyForm } from './components/EditPropertyForm';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useBackofficeRootStore } from '../../hooks/useBackofficeRootStore';


export const EditProperty = () => {

  const rootStore = useBackofficeRootStore();
  const urlParams = new URLSearchParams(window.location.search);
  const propertyId = Number(urlParams.get('propertyId'));

  if(!propertyId) {
    return null;
  }

  return (
    <VStack alignItems={'flex-start'} alignSelf={'flex-start'} mt={10} ml={10}>
      <HStack>
        <IconButton
          aria-label='Go to Property Master'
          mb={1}
          color={'#343A40'}
          size={'md'}
          isRound
          variant={'outline'}
          icon={<ArrowBackIcon />}
          borderWidth={'thin'}
          borderColor={'#343A40'}
          mr={2}
          onClick={() => {
            rootStore.setRoute('propertymaster');
          }}
        />
        <Heading color={'#343A40'}>Edit Property</Heading>
      </HStack>
      <EditPropertyForm propId={propertyId} />
    </VStack>
  );
};
