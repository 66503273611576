import React, { useState } from 'react';
import { Stack, Text, Button } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import styles from '../Dashboard.module.css';

const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const yearNames = [
  '2019', '2020', '2021', '2022', '2023', '2024'
];

export interface StatsFilterType {
  title: string;
}

export const StatsFilter: React.FC<StatsFilterType> = ({ title }) => {
  const [selectedYear, setSelectedYear] = useState('2024');
  const [selectedMonth, setSelectedMonth] = useState('January');

  return (
    <>
      <Stack direction='column' className={styles.statFilter}>
        <Stack>
          <Text>{title}</Text>
        </Stack>

        <Stack direction='row' className={styles.statFilterInner}>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon/>} className={styles.statFilterMenu}>
              <Text>{selectedYear}</Text>
            </MenuButton>
            <MenuList>
              {yearNames.map((year) => (
                <MenuItem key={year} onClick={() => {
                  setSelectedYear(year);
                }}>
                  {year}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>

          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon/>} className={styles.statFilterMenu}>
              <Text>{selectedMonth}</Text>
            </MenuButton>
            <MenuList>
              {monthNames.map((month) => (
                <MenuItem key={month} onClick={() => {
                  setSelectedMonth(month);
                }}>
                  {month}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Stack>
      </Stack>
    </>
  );
};


