import { Select } from '@chakra-ui/react';
import { useBackofficeRootStore } from '../../../../../hooks/useBackofficeRootStore';
import { Outlet } from '../../../../../services/BackofficeServices/BackofficeOutletService';
import { observer } from 'mobx-react';

interface OutletFilterOption {
  value: string;
  label: string;
}

export const OutletFilter = observer(() => {
  const { backofficeMenuItemStore } = useBackofficeRootStore();
  const outlets: OutletFilterOption[] = backofficeMenuItemStore.Outlets.map((outlet: Outlet) => {
    return { value: outlet.outletId.toString(), label: outlet.outletName };
  });

  const setSelectedOutlet = (e: { value: string }) => {
    backofficeMenuItemStore.setSelectedOutletId(parseInt(e.value));
  };

  return (
    <div className="outlet-filter">
      <label>Outlet: </label>
      <Select
        value={backofficeMenuItemStore.selectedOutletId ?? ''}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setSelectedOutlet({ value: e.target.value });
        }}
        children={outlets.map((outlet) => (
          <option key={outlet.value} value={outlet.value}>
            {outlet.label}
          </option>
        ))}
        placeholder="All Outlets"
      />
    </div>
  );
});
