import { useContext } from 'react';
import { BackofficeRootStoreContext } from '../RootStoreContext';

export const useBackofficeRootStore = () => {
  const context = useContext(BackofficeRootStoreContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within a RootStoreProvider');
  }
  return context;
};
