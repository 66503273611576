import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { RoomVerifyService } from '../services/RoomConfirmationService';
import { GetPaymentMethodsService, PaymentMethodsInterface } from '../services/GetPaymentMethodsService';

interface RoomDetails {
  roomNumber: string;
  lastName: string;
  confirmationNo?: string;
}

export class RoomStore {
  rootStore: RootStore;
  roomDetails: RoomDetails = {
    roomNumber: '',
    lastName: '',
    confirmationNo: '',
  };
  isInvalid = false;
  isNotFilled = false;
  selectedPayment : PaymentMethodsInterface | null = null;
  paymentMethods: PaymentMethodsInterface[] = [];
  paymentMethodsLoading = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    this.loadFromStorage();
  }

  setRoomDetails(roomDetails: RoomDetails) {
    this.roomDetails = roomDetails;
    this.saveToStorage();
  }

  saveToStorage() {
    localStorage.setItem('roomDetails', JSON.stringify(this.roomDetails));
  }

  loadFromStorage() {
    const data = localStorage.getItem('roomDetails');
    if (data) {
      try {
        const { roomNumber, lastName, confirmationNo } = JSON.parse(data) as RoomDetails;
        this.roomDetails = { roomNumber, lastName, confirmationNo };
      } catch (e) {
        console.error('Error loading room details from storage', e);
      }
    }
  }

  verifyRoomDetails = async () => {
    if (this.roomDetails.roomNumber === '' || this.roomDetails.lastName === '') {
      this.isNotFilled = true;
      return false;
    }
    const data = await RoomVerifyService(this.rootStore.propertyStore.propertyId, this.roomDetails.roomNumber, this.roomDetails.lastName);
    this.isInvalid = !data?.result;
    this.roomDetails.confirmationNo = data?.data.confirmationID.toString();
    this.saveToStorage();
    return data?.result;
  };

  setPaymentMethods(paymentMethods: PaymentMethodsInterface[]) {
    this.paymentMethods = paymentMethods;
  }

  setPaymentMethodLoading(paymentMethodsLoading: boolean) {
    this.paymentMethodsLoading = paymentMethodsLoading;
  }

  setSelectedPayment(paymentMethod: PaymentMethodsInterface | null) {
    this.selectedPayment = paymentMethod;
  }

  fetchPaymentMethods = () => {
    this.setPaymentMethodLoading(true);
    GetPaymentMethodsService(this.rootStore.propertyStore.propertyId).then(
      (data) => {
        if (data?.result) {
          this.setPaymentMethods(data.data);
        }
      }
    ).catch((error : unknown) => {
      console.error('Error fetching payment methods', error);
    }).finally(() => {
      this.setPaymentMethodLoading(false);
    }
    );
  };

};
