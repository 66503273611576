import BaseApiService from '../BaseApiService';
import { MenuItemType } from '../../stores/BackofficeStores/BackofficeMenuItemByOutletStore';

export interface MenuItemDeletedResponse {
    result: boolean;
    code: string;
    data: boolean;
    message: string;
  }

export interface MenuItemsInterface {
    result: boolean;
    code: string;
    data: MenuItemType[];
    message: string;
    }

export const GetAllMenuItemsByOutletService = async (outletId: number) => {
  try {
    const response = await BaseApiService.get(`/Menuitems/GetMenuItemsByOutletAdmin?OutletId=${String(outletId)}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as MenuItemsInterface;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error fetching menu items', error);
  }
};

export const DeleteMenuItemByOutletService = async (menuId: number) => {
  try {
    const response = await BaseApiService.delete(`/MenuItemDetails/DeleteMenuItem/${String(menuId)}`);
    if (response.data && typeof response.data === 'object') {
      return response.data as MenuItemDeletedResponse;
    } else {
      throw new Error('Invalid response data');
    }
  } catch (error) {
    console.error('Error deleting property', error);
  }
};
