// hook to get window size
import React from 'react';

export const useWindow = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const maxWindowWidth = '900px';

  const [isMobile, setIsMobile] = React.useState(windowSize.width < 768);
  const [isTablet, setIsTablet] = React.useState(windowSize.width >= 768 && windowSize.width < 1024);
  const [isMobileOrTablet, setIsMobileOrTablet] = React.useState(windowSize.width < 1024);
  const [isDesktop, setIsDesktop] = React.useState(windowSize.width >= 1024);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 1024);
      setIsMobileOrTablet(window.innerWidth < 1024);
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    isMobile,
    isTablet,
    isMobileOrTablet,
    isDesktop,
    maxWindowWidth,
  };
};
