import { FormControl, FormLabel, Input, Stack, Text } from '@chakra-ui/react';
import { useWindow } from '../../../hooks/useWindow';
import { useRootStore } from '../../../hooks/useRootStore';
import { observer } from 'mobx-react';

export const RoomDetails = observer(() => {

  const { maxWindowWidth } = useWindow();
  const { roomStore } = useRootStore();

  const handleRoomNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    roomStore.setRoomDetails({ ...roomStore.roomDetails, roomNumber: e.target.value });
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    roomStore.setRoomDetails({ ...roomStore.roomDetails, lastName: e.target.value });
  };

  return (
    <Stack width="100%" mx="auto" spacing={3} maxWidth={maxWindowWidth} py={2}>
      <Text fontFamily="Title" fontSize="24px" fontWeight={800}>Room Details</Text>
      {
        roomStore.isInvalid && (
          <Text color="red" fontSize="sm">
            Room Details are invalid
          </Text>
        )
      }
      {
        roomStore.isNotFilled && (
          <Text color="red" fontSize="sm">
            Please fill in all fields
          </Text>
        )
      }
      <FormControl isRequired>
        <FormLabel>Room Number</FormLabel>
        <Input
          placeholder='Room Number'
          value={roomStore.roomDetails.roomNumber}
          onChange={handleRoomNumberChange}
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Last Name</FormLabel>
        <Input
          placeholder='Last Name'
          value={roomStore.roomDetails.lastName}
          onChange={handleLastNameChange}
        />
      </FormControl>
    </Stack>
  );
});


