/* eslint-disable no-console */
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './ClientPages/Home/Home';
import { Login }from './ClientPages/Login/Login';

import { theme } from './theme';
import Fonts from './fonts';
import { ChakraProvider, Spinner, VStack } from '@chakra-ui/react';
import { MenuPage } from './ClientPages/MenuPage/MenuPage';
import { RootStore } from './stores/RootStore';
import { RootStoreContext } from './RootStoreContext';
import { Payments } from './ClientPages/Payments/Payments';
import { LanguageSelector } from './ClientComponents/LanguageSelector/LanguageSelector';
import { AdminHome } from './BackOfficePages/Home/Home';
import { Basket } from './ClientPages/Basket/Basket';

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  let propertyId = Number(urlParams.get('propertyId'));
  if (!propertyId) {
    propertyId = 1;
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('propertyId', propertyId.toString());
    window.history.replaceState(null, '', newUrl.toString());
  }
  const rootStore = new RootStore(propertyId);

  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Suspense fallback={
          <VStack height={'100vh'} width={'100%'} align={'center'} justify={'center'}>
            <Spinner size="xl" />
          </VStack>
        }>
          <Fonts />
          <RootStoreContext.Provider value={rootStore}>
            <Routes>
              <Route path="/" Component={Home} />
              <Route path="/menu" Component={MenuPage} />
              <Route path="/login" element={<Login buttonText="Submit" />} />
              <Route path="/payments" Component={Payments} />
              <Route path="/basket" Component={Basket} />
            </Routes>
            <LanguageSelector />
          </RootStoreContext.Provider>
          <Routes>
            <Route path="/admin/*" Component={AdminHome} />
          </Routes>
        </Suspense>
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
