import { VStack, Box, HStack, Text, Heading, Divider, Button, IconButton, Image, Grid, Skeleton } from '@chakra-ui/react';
import { GuestDetails } from '../../BackOfficeComponents/ParticularOrderDetails/Components/GuestDetails';
import { Notes } from '../../BackOfficeComponents/ParticularOrderDetails/Components/Notes';

import { useEffect } from 'react';
import { PaymentsClose } from '../../BackOfficeComponents/PaymentOverlay/PaymentClose';
import { useBackofficeRootStore } from '../../hooks/useBackofficeRootStore';
import { observer } from 'mobx-react';
import moment from 'moment';

export interface OrderSummaryCard {
  categoryID: number,
  categoryName: string,
  isPaymentConfirmed?: boolean,
  orderList: OutletOrderDetails[]
  paymentMethodId: number
}

interface OutletOrderDetails {
  outletID: number,
  outletName: string,
  items: MenuItemDetails[]
}

interface MenuItemDetails {
  menuItemID: number,
  menuItemName: string,
  quantity: number,
  price: number
}

const OrderCard = ({ prop }: { prop: OrderSummaryCard }) => {
  let basketTotal = 0;
  prop.orderList.forEach((orderListItem) => {
    orderListItem.items.forEach((item) => {
      basketTotal += item.quantity * item.price;
    });
  });

  return (
    <VStack align="stretch" bg="white" p={4} maxW='400px' borderRadius="md" boxShadow="lg" m={4}>
      <Heading size="md" mb={4}>{prop.categoryName}</Heading>
      {
        prop.orderList.map((orderListItem: OutletOrderDetails) => {
          return (
            <Box>
              <Text fontWeight="bold" mb={2}>{orderListItem.outletName}</Text>
              <VStack spacing={3} align="stretch">
                {
                  orderListItem.items.map((item: MenuItemDetails) => {
                    return (
                      <>
                        <HStack justify="space-between">
                          <Text>{item.menuItemName}</Text>
                          <Text fontWeight="bold">{'AED ' + String(item.price * item.quantity)}</Text>
                        </HStack>
                        <Text color="gray.500">{String(item.price) + ' X ' + String(item.quantity)}</Text>
                        <Divider />
                      </>
                    );
                  })
                }

              </VStack>
            </Box>
          );
        })
      }
      <Divider my={4} />
      <VStack align="stretch">
        <Text fontWeight="bold">Payment Summary</Text>
        <HStack justify="space-between">
          <Text>Basket total</Text>
          <Text>{'AED ' + String(basketTotal)}</Text>
        </HStack>
        <HStack justify="space-between">
          <Text>Service</Text>
          <Text>AED 0</Text>
        </HStack>
        <HStack justify="space-between">
          <Text>VAT</Text>
          <Text>AED 0</Text>
        </HStack>
        <HStack justify="space-between" fontWeight="bold">
          <Text>Grand total</Text>
          <Text>{'AED ' + String(basketTotal)}</Text>
        </HStack>
      </VStack>
      <HStack mt={4} spacing={3}>
        <Button colorScheme="gray">Print receipt</Button>
        <PaymentsClose buttonText={prop.isPaymentConfirmed ? 'Payment confirmed' : 'Confirm payment'} orderCategoryID={prop.categoryID} disabled={prop.isPaymentConfirmed} paymentMethodId={prop.paymentMethodId} />
      </HStack>
    </VStack>
  );
};

export const ParticularOrderDetails = observer(() => {

  const { backofficeOrderStore } = useBackofficeRootStore();
  const rootStore = useBackofficeRootStore();
  const urlParams = new URLSearchParams(window.location.search);
  const orderID = urlParams.get('orderId');

  if (orderID) {
    useEffect(() => {
      const fetchOrderDetails = async () => {
        await backofficeOrderStore.fetchCurrentOrderItemsDetails(Number(orderID));
        await backofficeOrderStore.fetchCurrentOrderDetails(Number(orderID));
        backofficeOrderStore.fetchPaymentStates();
      };
      fetchOrderDetails().then(() => {
        console.log('Order details fetched');
      }).catch((error : unknown) => {
        console.log('Error in fetching order details', error);
      });
    }, [backofficeOrderStore, orderID]);
  }

  return (

    <VStack alignSelf={'flex-start'} alignItems={'flex-start'} px={10} py={8} spacing={4}>

      <VStack>
        <HStack width={'100%'} justifyContent={'start'}>
          <IconButton
            aria-label='Go to Orders'
            mb={1}
            color={'#343A40'}
            size={'md'}
            isRound
            variant={'outline'}
            icon={<Image src='/images/back_without_circle.svg' />}
            mr={2}
            onClick={() => {
              rootStore.setRoute('orderlist');
            }}
          />
          {
            (backofficeOrderStore.isLoading && !backofficeOrderStore.currentOrderDetails.orderID) ?
              <Skeleton height={20} width={200} />
              :
              <Heading>#{backofficeOrderStore.currentOrderDetails.orderDisplayNumber.toString().padStart(5, '0')}
                <Text as="span" color="gray.600" ml="6">#{backofficeOrderStore.currentOrderDetails.reservationID}</Text></Heading>
          }
        </HStack>

        {
          (backofficeOrderStore.isLoading && !backofficeOrderStore.currentOrderDetails.timestamp) ?
            <Skeleton height={30} width={400} pl={12}/>
            :
            <Text pl={12}>
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-call */}
              {String(moment(backofficeOrderStore.currentOrderDetails.timestamp + 'Z').format('MMMM Do YYYY [at] h:mm A'))
              + ' from room #' +
              String(backofficeOrderStore.currentOrderDetails.roomNumber ?? '')
              }
            </Text>
        }
      </VStack>

      <HStack spacing={4} width={'100%'} justifyContent={'space-between'}>
        {
          (backofficeOrderStore.isLoading) ?
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <Skeleton height={600} width={300} m={4} rounded={'md'} />
              <Skeleton height={600} width={300} m={4} rounded={'md'} />
            </Grid>
            :
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              {
                backofficeOrderStore.currentOrderItems.map((dataItem) => <OrderCard prop={dataItem} />)
              }
            </Grid>
        }
        <VStack spacing={4} height={'100%'} py={4}>
          {
            (backofficeOrderStore.isLoading) ?
              <>
                <Skeleton height={100} width={300} m={4} rounded={'md'} />
                <Skeleton height={400} width={300} mb={4} rounded={'md'} />
              </>
              :
              <>
                <Notes note={backofficeOrderStore.currentOrderDetails.guestNotes ?? ''} />
                <GuestDetails guestName={backofficeOrderStore.currentOrderDetails.guestName ?? 'Hello'} guestConfirmationNumber={backofficeOrderStore.currentOrderDetails.confirmationNo ?? ''} guestRoomNumber={Number(backofficeOrderStore.currentOrderDetails.roomNumber)} guestRoomType={backofficeOrderStore.currentOrderDetails.roomType ?? ''} />
              </>
          }
        </VStack>
      </HStack>

    </VStack>
  );
});



