import { Box, HStack, Text, VStack, Spacer } from '@chakra-ui/react';
import { TiPencil } from 'react-icons/ti';

export const Notes = ({ note }: { note: string }) => {
  return (
    <Box
      backgroundColor="white"
      padding={6}
      borderRadius="md"
      boxShadow="md"
      width="100%" // Set width to 100% to take up full space
    >
      <VStack align="start" spacing={4} width="100%">
        <HStack width="100%">
          <Text fontSize="xl" fontWeight="bold" color="gray.800">
            Notes
          </Text>
          <Spacer /> {/* Pushes the icon to the far right */}
          <TiPencil />
        </HStack>
        <VStack align="start" spacing={0} width="100%">
          <Text color="gray.500">
            {note}
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};
