import { extendTheme } from '@chakra-ui/react';

// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
  colors: {
    primary: {
      100: '#d4dadf',
      200: '#aab7bf',
      300: '#8093a0',
      400: '#566f80',
      500: '#2c4b60',
      600: '#022741',
      700: '#012036',
      800: '#011a2b',
      900: '#011320',
      1000: '#000d15',
      1100: '#00060a',
    },
    secondary: {
      100: '#e7e7e5',
      200: '#d0d0cc',
      300: '#b9b8b3',
      400: '#a2a199',
      500: '#8b8980',
      600: '#747267',
      700: '#605f55',
      800: '#4d4c44',
      900: '#3a3933',
      1000: '#262622',
      1100: '#131311',
    },
    neutral: {
      100: '#f6f6f6',
      200: '#eeeeee',
      300: '#e6e6e6',
      400: '#999999',
      500: '#4c4c4c',
    },
  },
  fonts: {
    heading: '"Heading", sans-serif',
    body: '"Body", sans-serif',
    label: '"Label", sans-serif',
  },
});
