import { Text, HStack, Image } from '@chakra-ui/react';
import { useWindow } from '../../hooks/useWindow';
interface TopBarProps {
  url: string;
  imageSrc: string;
  title: string;

}

export const TopBar = ({ url, imageSrc, title }: TopBarProps) => {

  const { maxWindowWidth } = useWindow();

  return (
    <HStack spacing={10} align="justify" width={'100%'} maxW={maxWindowWidth}>
      <a href={url}>
        <Image src={imageSrc} width={6} height={6} marginBottom={1.5} />
      </a>
      <HStack spacing={2} justify="center" width={'100%'} marginLeft={-7}>
        <Text fontFamily="Heading" fontSize={24} color="secondary.900">{title}</Text>
      </HStack>
    </HStack>
  );
};
