import React from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import styles from './home.module.css';
import { SimpleSidebar } from '../../BackOfficeComponents/SideNavigation/SideNavigation';
import { BackofficeRootStoreContext } from '../../RootStoreContext';
import { BackofficeRootStore } from '../../stores/BackofficeStores/BackofficeRootStore';
import { NavBar } from '../../BackOfficeComponents/NavBar/NavBar';
import { ComponentToRender } from './HomeContent';

export const AdminHome = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let propertyId = Number(urlParams.get('propertyId'));
  const subRoute = window.location.pathname.split('/')[2];
  if (!propertyId) {
    propertyId = 1;
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('propertyId', propertyId.toString());
    window.history.replaceState(null, '', newUrl.toString());
  }
  const rootStore = new BackofficeRootStore(propertyId);
  const params = new URLSearchParams(window.location.search);
  const keys = params.keys();
  if (subRoute) {
    rootStore.setRoute(subRoute, Object.fromEntries(Array.from(keys).filter(key => key !== 'propertyId').map(key => [key, params.get(key) ?? ''])));
  }

  return (
    <BackofficeRootStoreContext.Provider value={rootStore}>
      <NavBar/>
      <HStack className={styles.homeBody} gap={0}>
        <SimpleSidebar />
        <VStack style={{
          height: '100vh',
          overflowY: 'scroll',
          scrollbarWidth: 'none',
          width: '100%',
        }}
        mx={'auto'}
        >
          <ComponentToRender />
        </VStack>
      </HStack>
    </BackofficeRootStoreContext.Provider>

  );
};
