import React from 'react';
import { Stack } from '@chakra-ui/react';
import { Outlets } from './components/Menu/Outlets';
import { HomeHeader } from './components/HomeHeader';
import styles from './home.module.css';
import { observer } from 'mobx-react';

export const Home = observer(() => {
  return (
    <Stack direction={'column'} className={styles.homeContainer} align={'center'} gap={0}>
      <HomeHeader />
      <Stack direction={'column'} align={'center'} className={styles.homeBody}>
        <Outlets home={true} />
      </Stack>
    </Stack>
  );
});
