import React, { useState } from 'react';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import { RiDeleteBinLine } from 'react-icons/ri';
import { LuScreenShare } from 'react-icons/lu';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button, Icon, Text, Flex, Switch, Image, Input, Select, HStack } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useBackofficeRootStore } from '../../../../../hooks/useBackofficeRootStore';
import { Outlet } from '../../../../../services/BackofficeServices/BackofficeOutletService';

const OutletTable = observer(() => {
  const { backofficeOutletStore } = useBackofficeRootStore();
  const rootStore = useBackofficeRootStore();
  const [activeFilterState, setActiveFilterState] = useState(true);

  const handleFieldFilter = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    if (field === 'isActive') {
      backofficeOutletStore.filterOutletsPerField(field, e.target.checked.toString());
      return;
    }
    backofficeOutletStore.filterOutletsPerField(field, e.target.value);
  };

  const handleNavigate = (outletId: number) => {
    rootStore.setRoute('menubyoutlet', { outletId: outletId.toString() });
  };

  // Function to handle delete
  const handleDelete = (outletId: number) => {
    backofficeOutletStore.deleteOutlet(outletId); // Call the delete function from the store
  };

  const handlePageChange = (pageNumber: number) => {
    backofficeOutletStore.setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize: number) => {
    backofficeOutletStore.setPageSize(pageSize);
  };

  return (
    <Box overflowX="auto" my={5} borderRadius="lg">
      <Table variant="simple">
        <Thead bg='#f0f4f8'>
          <Tr>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Image</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Outlet Name</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Outlet Code</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold">Active State</Th>
            <Th fontFamily="inherit" textTransform="none" fontWeight="extrabold" width="200px">Action</Th>
          </Tr>
          <Tr bg='#DFDFDF'>
            <Th></Th>
            <Th>
              <Input
                type="text"
                bg={'white'}
                placeholder="Outlet Name"
                onChange={(e) => {
                  handleFieldFilter(e, 'outletName');
                }}
              />
            </Th>
            <Th>
              <Input
                type="number"
                bg={'white'}
                placeholder="Outlet Code"
                onChange={(e) => {
                  handleFieldFilter(e, 'outletId');
                }}
              />
            </Th>
            <Th>
              <Flex justifyContent="space-between" alignItems="center" width="50%">
                <Switch
                  colorScheme="green"
                  isChecked={activeFilterState}
                  onChange={(e) => {
                    setActiveFilterState(e.target.checked);
                    handleFieldFilter(e, 'isActive');
                  }}
                />
              </Flex>
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {backofficeOutletStore.filteredOutlets.map((item: Outlet) => (
            <Tr key={item.outletId}>
              <Td>
                <Image src={item.outletImage} alt="outlet" boxSize="50px" borderRadius="full" />
              </Td>
              <Td>
                <Text>{item.outletName}</Text>
              </Td>
              <Td>
                <Text>{item.outletId}</Text>
              </Td>
              <Td>
                <Switch colorScheme="green" isChecked={item.isActive} />
              </Td>
              <Td p={0} width="200px">
                <Flex direction="row" justify="flex-start" align="left">
                  <Button size="md" variant="ghost">
                    <Icon as={HiOutlinePencilSquare} color="#6699FF" />
                  </Button>
                  <Button size="md" variant="ghost" onClick={() => {
                    handleNavigate(item.outletId);
                  }}>  {/* Use the function to navigate */}
                    <Icon as={LuScreenShare} color="#6699FF" />
                  </Button>
                  <Button size="md" variant="ghost" onClick={() => {
                    handleDelete(item.outletId);
                  }}>
                    <Icon as={RiDeleteBinLine} color="#6699FF" />
                  </Button>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <HStack justify="end" mt={5} mx={10}>
        <Button
          size="sm"
          border="1px"
          rounded={5}
          borderColor={'#D9D9D9'}
          p={2}
          cursor="pointer"
          onClick={() => {
            handlePageChange(backofficeOutletStore.pageNumber - 1);
          }}
          isDisabled={backofficeOutletStore.pageNumber === 1}
        >
          &lt;
        </Button>
        <HStack>
          {Array.from({ length: Math.ceil(backofficeOutletStore.totalItems / backofficeOutletStore.pageSize) }, (_, index) => (
            <Box
              key={index}
              border="1px"
              rounded={5}
              borderColor={backofficeOutletStore.pageNumber === index + 1 ? 'black' : '#D9D9D9'}
              px={2}
              py={1}
              cursor="pointer"
              onClick={() => {
                handlePageChange(index + 1);
              }}
              bg={backofficeOutletStore.pageNumber === index + 1 ? '#6E8189' : 'white'}
              color={backofficeOutletStore.pageNumber === index + 1 ? 'white' : 'black'}
            >
              {index + 1}
            </Box>
          ))}
        </HStack>
        <Button
          size="sm"
          border="1px"
          rounded={5}
          borderColor={'#D9D9D9'}
          p={2}
          cursor="pointer"
          onClick={() => {
            handlePageChange(backofficeOutletStore.pageNumber + 1);
          }}
          isDisabled={backofficeOutletStore.pageNumber * backofficeOutletStore.pageSize >= backofficeOutletStore.totalItems}
        >
          &gt;
        </Button>
        {/* Items per page dropdown */}
        <Select
          size="sm"
          maxWidth={'fit-content'}
          value={backofficeOutletStore.pageSize}
          onChange={(e) => {
            handlePageSizeChange(Number(e.target.value));
          }}
        >
          {/* options from 5 to 20 in equal intervals of 5 */}
          {[5, 10, 15, 20].map((value) => (
            <option key={value} value={value}>
              {value.toString() + ' / page'}
            </option>
          ))}
        </Select>
      </HStack>
    </Box>
  );
});

export default OutletTable;
