import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Text } from '@chakra-ui/react';
import styles from './languageSelector.module.css';

const languages = [
  { code: 'en', lang: 'English', flag: 'US' },
  // { code: 'fr', lang: 'French', flag: 'FR' },
  { code: 'ar', lang: 'Arabic', flag: 'AE' },
];

export const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng).then(() => {
      document.documentElement.lang = lng;
    }).catch((e: unknown) => {
      console.error(e);
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  return (
    <div ref={containerRef} className={`${styles.container} ${isOpen ? styles.open : ''}`}>
      <button className={styles.flag} onClick={() => {
        setIsOpen(!isOpen);
      }}
      hidden={isOpen}
      >
        <Text fontSize="sm" fontWeight={600}>
          {i18n.language.toUpperCase()}
        </Text>
      </button>
      {isOpen && (
        <Select
          onChange={(e) => {
            changeLanguage(e.target.value);
          }}
          defaultValue={i18n.language}
          className={styles.languageSelector}
        >
          {languages.map((language) => (
            <option key={language.code} value={language.code} className={styles.languageOption}>
              {language.lang}
            </option>
          ))}
        </Select>
      )}
    </div>
  );
};
