import { Modal, ModalOverlay, ModalContent, ModalBody, Text, Image, Center, VStack, ModalCloseButton } from '@chakra-ui/react';

interface ModalButtonProps {
  modalTitle: string;
  modalText: string;
  modalIconUrl: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalButton = ({ modalTitle, modalText, modalIconUrl, isOpen, onClose }: ModalButtonProps) => {
  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size={['full', 'md', 'xl']}>
        <ModalOverlay />
        <ModalContent h={['100vh', 'auto']}>
          <ModalCloseButton />
          <ModalBody mb={'20px'}>
            <Center py={'60px'} h={'100%'}>
              <VStack>
                <Image src={modalIconUrl} mb={'30px'} alt="Modal Icon" />
                <Text align={'center'} fontSize={['x-large', 'xx-large']} fontWeight="bold">
                  {modalTitle}
                </Text>
                <Text align={'center'} fontSize={['medium', 'larger']} fontWeight="medium">
                  {modalText}
                </Text>
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
