import React, { useState } from 'react';
import { Stack, Tabs, TabList, TabPanels, Tab, TabPanel, Button, Text, HStack, Image } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { ChevronDownIcon, CalendarIcon } from '@chakra-ui/icons';
import styles from './Dashboard.module.css';
import { OutletDetailsData } from './DashboardData';
import { StatBox } from './Components/StatBox';
import { SalesGraph } from './Components/SalesGraph';
import { StatsFilter } from './Components/StatsFilter';
import TopSeller from '../../../../BackOfficeComponents/TopSeller/TopSellerDetails';

// Tab style
const tabSelectStyle = {
  color: 'white', bg: '#6E8189', border: '2px solid #243B45'
};

// Icon Paths
const TotalCustomerIcon = '/images/totalCustomer.svg';
const totalOrder = '/images/totalOrder.svg';
const totalSales = '/images/totalSales.svg';
const totalPending = '/images/totalPending.svg';

export interface getValueOnMenuType {
  today: number;
  week: number;
  month: number;
};

// Dashboard Component
export const Dashboard = () => {
  const [selectedMenu, setSelectedMenu] = useState('Today');
  const [isMonthFilterVisible, setMonthFilterVisibility] = useState(false);
  const [isCustomFilterVisible, setCustomFilterVisibility] = useState(false);

  // Function to get item value based on selectedMenu
  const getValueOnMenu = (data: getValueOnMenuType) => {
    switch (selectedMenu) {
    case 'Today':
      return data.today;
    case 'Month':
      return data.week;
    case 'Custom':
      return data.month;
    default:
      return data.today;
    }
  };

  return (
    <>
      <Stack className={styles.dashboardOuter} p={5}>
        <Stack className={styles.dashboardInner}>
          <Tabs variant='unstyled'>
            <TabList className={styles.tablist}>
              <Stack direction='row' className={styles.outletTabsLeft}>
                {OutletDetailsData.map((outlet, index) => (
                  <Tab key={index} className={styles.outletTab} _selected={tabSelectStyle}>
                    {outlet.title}
                  </Tab>
                ))}
              </Stack>

              <Stack direction='row' className={styles.outletTabsRight}>
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon className={styles.menuIcon}/>} className={styles.outletMenu}>
                    <Stack direction='row' spacing={5} className={styles.outletMenuStack}>
                      <CalendarIcon/>
                      <Text>{selectedMenu}</Text>
                    </Stack>
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => {
                      setSelectedMenu('Today');
                      setMonthFilterVisibility(false); setCustomFilterVisibility(false);
                    }}>Today</MenuItem>
                    <MenuItem onClick={() => {
                      setSelectedMenu('Month');
                      setMonthFilterVisibility(true); setCustomFilterVisibility(false);
                    }}>Month</MenuItem>
                    <MenuItem onClick={() => {
                      setSelectedMenu('Custom');
                      setMonthFilterVisibility(false); setCustomFilterVisibility(true);
                    }}>Custom</MenuItem>
                  </MenuList>
                </Menu>
              </Stack>
            </TabList>

            {isMonthFilterVisible && (
              <HStack gap={4} className={styles.statFilterOuter} mt={5}>
                <StatsFilter title="Select Month" />
              </HStack>
            )}

            {isCustomFilterVisible && (
              <HStack gap={4} className={styles.statFilterOuter} mt={5}>
                <StatsFilter title="From" />
                <Image src='/images/right_arrow.svg' width={50}/>
                <StatsFilter title="To" />
              </HStack>
            )}

            <TabPanels>
              {OutletDetailsData.map((outlet, index) => (
                <TabPanel key={index}>
                  <Stack direction='row' className={styles.statBoxSet}>
                    <StatBox
                      title="Total Customers"
                      itemValue={getValueOnMenu(outlet.total_customers)}
                      imgSrc={TotalCustomerIcon}
                    />
                    <StatBox
                      title="Total Orders"
                      itemValue={getValueOnMenu(outlet.total_orders)}
                      imgSrc={totalOrder}
                    />
                    <StatBox
                      title="Total Sales"
                      itemValue={getValueOnMenu(outlet.total_sales)}
                      imgSrc={totalSales}
                    />
                    <StatBox
                      title="Total Pending"
                      itemValue={outlet.total_pending_orders}
                      imgSrc={totalPending}
                    />
                  </Stack>

                  <SalesGraph salesArray={outlet.sales_details.October} graphId={index}/>
                  <TopSeller items={outlet.top_sellers}/>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Stack>
      </Stack>
    </>
  );
};
