import { Text, Flex, Button, Table, Thead, Tr, Th, Tbody, Td, TableContainer, Center, Skeleton } from '@chakra-ui/react';
import { useState } from 'react';
import { CircleIcon } from './CircleIcon';
import { useBackofficeRootStore } from '../../hooks/useBackofficeRootStore';
import { observer } from 'mobx-react';
import { filterMapper, filters } from '../../stores/BackofficeStores/BackofficeOrderStore';
import moment from 'moment';

/*
    TO-DO:
    - Red Circle Icon
    - Cleaner Custom Styling
    - Fallback Row ?
*/


const OrderStatusDisplay = ({ status }: { status: string }) => {
  return (
    <Flex alignItems={'center'}>
      <Center bg={status === 'Completed' ? '#C3FFBA' : '#FFF4BA'} color={'black'} borderRadius={100} p={2}>
        <CircleIcon boxSize={2} /> <Text ml={0.5}>{status}</Text>
      </Center>
    </Flex>
  );
};

const StyledTh = ({ children }: { children: React.ReactNode }) => (
  <Th fontSize={'medium'} textTransform={'none'} fontFamily={'Body'} color={'#6C6C6C'} pr={20}>
    {children}
  </Th>
);

const StyledTd = ({ children }: { children: React.ReactNode }) => (
  <Td fontFamily={'Body'}>
    {children}
  </Td>
);

export const OrderDetails = observer(() => {
  // const { backofficeOrderStore } = useBackofficeRootStore();
  const rootStore = useBackofficeRootStore();
  const [filter, setFilter] = useState<string>('All');

  return (
    <>
      <TableContainer bg={'#FFFFFF'} mr={2} my={2} borderWidth={1} borderRadius={'lg'} borderColor={'#00000038'}>
        <Flex p={3} gap={3}>
          {
            filters.map((filterItem) => (
              <Button variant={'ghost'} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'clip'} bg={filter === filterItem ? '#EBEBEB' : 'inherit'} onClick={() => {
                setFilter(filterItem);
                rootStore.backofficeOrderStore.filterOrders(filterItem);
              }}>
                {filterItem}
              </Button>
            ))
          }
        </Flex>
        <Table>
          <Thead>
            <Tr bg={'#F7F7F7'}>
              <StyledTh> Order </StyledTh>
              <StyledTh> Date </StyledTh>
              <StyledTh> Room </StyledTh>
              <StyledTh> Guest </StyledTh>
              <StyledTh> Total </StyledTh>
              <StyledTh> Order Status </StyledTh>
              <StyledTh> Payment Type </StyledTh>
            </Tr>
          </Thead>
          <Tbody>
            {rootStore.backofficeOrderStore.isLoading ?
              Array.from({ length: 5 }).map((_, index) => (
                <Tr key={index}>
                  <Td colSpan={7}>
                    <Skeleton height={30} width={'100%'} />
                  </Td>
                </Tr>))
              :
              rootStore.backofficeOrderStore.filteredOrders.map((order) => (
                <Tr key={order.orderID}
                  onClick={() => {
                    rootStore.setRoute('orderdetails', { orderId: order.orderID.toString() });
                  }}
                  _hover={{ cursor: 'pointer', bg: '#F7F7F7' }}
                >
                  <Td fontWeight={'bold'} fontFamily={'Body'}> {order.orderDisplayNumber.toString().padStart(5, '0')} </Td>
                  <StyledTd>
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-call */}
                    {moment(order.timestamp + 'Z').format('MMM DD, YYYY h:mm A')}
                  </StyledTd>
                  <StyledTd>
                    {order.roomNumber}
                  </StyledTd>
                  <StyledTd>
                    {order.guestName}
                  </StyledTd>
                  <StyledTd> {order.orderTotal} </StyledTd>
                  <StyledTd>
                    <OrderStatusDisplay status={filterMapper(order.orderStatus)} />
                  </StyledTd>
                  <StyledTd> {rootStore.backofficeOrderStore.paymentMapper(order.paymentMethodId)} </StyledTd>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer >
    </>
  );
});
